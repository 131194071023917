@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;600;700&display=swap");
.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #47b2f7;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #00c292;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #d32f2f;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #47b2f7;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #00c292;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #d32f2f;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #47b2f7;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #00c292;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #d32f2f;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #47b2f7;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #00c292;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #d32f2f;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #47b2f7;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #00c292;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #d32f2f;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #47b2f7;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #00c292;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #d32f2f;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #47b2f7;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #00c292;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #d32f2f;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #47b2f7;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #00c292;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #d32f2f;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #47b2f7;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #d32f2f;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #00c292;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(71, 178, 247, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(71, 178, 247, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(71, 178, 247, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #47b2f7;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(71, 178, 247, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(71, 178, 247, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(71, 178, 247, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(0, 194, 146, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 194, 146, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 194, 146, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #00c292;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 194, 146, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 194, 146, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 194, 146, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(211, 47, 47, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(211, 47, 47, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(211, 47, 47, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #d32f2f;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(211, 47, 47, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(211, 47, 47, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(211, 47, 47, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #47b2f7;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #00c292;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #d32f2f;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #47b2f7;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #00c292;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #d32f2f;
}

.mat-focused .mat-form-field-required-marker {
  color: #00c292;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #47b2f7;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #00c292;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #d32f2f;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #47b2f7;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #00c292;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #d32f2f;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #d32f2f;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #d32f2f;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #d32f2f;
}

.mat-error {
  color: #d32f2f;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #47b2f7;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #00c292;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #d32f2f;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #d32f2f;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #47b2f7;
}
.mat-icon.mat-accent {
  color: #00c292;
}
.mat-icon.mat-warn {
  color: #d32f2f;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #47b2f7;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #00c292;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #d32f2f;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #d32f2f;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #cde8f9;
}

.mat-progress-bar-buffer {
  background-color: #cde8f9;
}

.mat-progress-bar-fill::after {
  background-color: #47b2f7;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #bcece0;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #bcece0;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #00c292;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f0c7c7;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f0c7c7;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #d32f2f;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #47b2f7;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #00c292;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #d32f2f;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #47b2f7;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #47b2f7;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #00c292;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #00c292;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #d32f2f;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #d32f2f;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #47b2f7;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #00c292;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #d32f2f;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #d32f2f;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #00c292;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 194, 146, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #00c292;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #47b2f7;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(71, 178, 247, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #47b2f7;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #d32f2f;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(211, 47, 47, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #d32f2f;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #47b2f7;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(71, 178, 247, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #00c292;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(0, 194, 146, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #d32f2f;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(211, 47, 47, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #47b2f7;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #00c292;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #d32f2f;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #d32f2f;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #d32f2f;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(243, 201, 189, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #47b2f7;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(3, 201, 215, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #00c292;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #d32f2f;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(243, 201, 189, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #47b2f7;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(3, 201, 215, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #00c292;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #d32f2f;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #47b2f7;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #00c292;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #d32f2f;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #00c292;
}

.darkTheme .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .mat-option {
  color: white;
}
.darkTheme .mat-option:hover:not(.mat-option-disabled), .darkTheme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.darkTheme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.darkTheme .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.darkTheme .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #47b2f7;
}
.darkTheme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #00c292;
}
.darkTheme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #d32f2f;
}
.darkTheme .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .mat-pseudo-checkbox::after {
  color: #303030;
}
.darkTheme .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.darkTheme .mat-primary .mat-pseudo-checkbox-checked,
.darkTheme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #47b2f7;
}
.darkTheme .mat-pseudo-checkbox-checked,
.darkTheme .mat-pseudo-checkbox-indeterminate,
.darkTheme .mat-accent .mat-pseudo-checkbox-checked,
.darkTheme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #00c292;
}
.darkTheme .mat-warn .mat-pseudo-checkbox-checked,
.darkTheme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #d32f2f;
}
.darkTheme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.darkTheme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.darkTheme .mat-app-background, .darkTheme.mat-app-background {
  background-color: #303030;
  color: white;
}
.darkTheme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.darkTheme .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.darkTheme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.darkTheme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.darkTheme .mat-badge-content {
  color: white;
  background: #47b2f7;
}
.cdk-high-contrast-active .darkTheme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.darkTheme .mat-badge-accent .mat-badge-content {
  background: #00c292;
  color: white;
}
.darkTheme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #d32f2f;
}
.darkTheme .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.darkTheme .mat-button, .darkTheme .mat-icon-button, .darkTheme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.darkTheme .mat-button.mat-primary, .darkTheme .mat-icon-button.mat-primary, .darkTheme .mat-stroked-button.mat-primary {
  color: #47b2f7;
}
.darkTheme .mat-button.mat-accent, .darkTheme .mat-icon-button.mat-accent, .darkTheme .mat-stroked-button.mat-accent {
  color: #00c292;
}
.darkTheme .mat-button.mat-warn, .darkTheme .mat-icon-button.mat-warn, .darkTheme .mat-stroked-button.mat-warn {
  color: #d32f2f;
}
.darkTheme .mat-button.mat-primary.mat-button-disabled, .darkTheme .mat-button.mat-accent.mat-button-disabled, .darkTheme .mat-button.mat-warn.mat-button-disabled, .darkTheme .mat-button.mat-button-disabled.mat-button-disabled, .darkTheme .mat-icon-button.mat-primary.mat-button-disabled, .darkTheme .mat-icon-button.mat-accent.mat-button-disabled, .darkTheme .mat-icon-button.mat-warn.mat-button-disabled, .darkTheme .mat-icon-button.mat-button-disabled.mat-button-disabled, .darkTheme .mat-stroked-button.mat-primary.mat-button-disabled, .darkTheme .mat-stroked-button.mat-accent.mat-button-disabled, .darkTheme .mat-stroked-button.mat-warn.mat-button-disabled, .darkTheme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.darkTheme .mat-button.mat-primary .mat-button-focus-overlay, .darkTheme .mat-icon-button.mat-primary .mat-button-focus-overlay, .darkTheme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #47b2f7;
}
.darkTheme .mat-button.mat-accent .mat-button-focus-overlay, .darkTheme .mat-icon-button.mat-accent .mat-button-focus-overlay, .darkTheme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #00c292;
}
.darkTheme .mat-button.mat-warn .mat-button-focus-overlay, .darkTheme .mat-icon-button.mat-warn .mat-button-focus-overlay, .darkTheme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #d32f2f;
}
.darkTheme .mat-button.mat-button-disabled .mat-button-focus-overlay, .darkTheme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .darkTheme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.darkTheme .mat-button .mat-ripple-element, .darkTheme .mat-icon-button .mat-ripple-element, .darkTheme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.darkTheme .mat-button-focus-overlay {
  background: white;
}
.darkTheme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .mat-flat-button, .darkTheme .mat-raised-button, .darkTheme .mat-fab, .darkTheme .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.darkTheme .mat-flat-button.mat-primary, .darkTheme .mat-raised-button.mat-primary, .darkTheme .mat-fab.mat-primary, .darkTheme .mat-mini-fab.mat-primary {
  color: white;
}
.darkTheme .mat-flat-button.mat-accent, .darkTheme .mat-raised-button.mat-accent, .darkTheme .mat-fab.mat-accent, .darkTheme .mat-mini-fab.mat-accent {
  color: white;
}
.darkTheme .mat-flat-button.mat-warn, .darkTheme .mat-raised-button.mat-warn, .darkTheme .mat-fab.mat-warn, .darkTheme .mat-mini-fab.mat-warn {
  color: white;
}
.darkTheme .mat-flat-button.mat-primary.mat-button-disabled, .darkTheme .mat-flat-button.mat-accent.mat-button-disabled, .darkTheme .mat-flat-button.mat-warn.mat-button-disabled, .darkTheme .mat-flat-button.mat-button-disabled.mat-button-disabled, .darkTheme .mat-raised-button.mat-primary.mat-button-disabled, .darkTheme .mat-raised-button.mat-accent.mat-button-disabled, .darkTheme .mat-raised-button.mat-warn.mat-button-disabled, .darkTheme .mat-raised-button.mat-button-disabled.mat-button-disabled, .darkTheme .mat-fab.mat-primary.mat-button-disabled, .darkTheme .mat-fab.mat-accent.mat-button-disabled, .darkTheme .mat-fab.mat-warn.mat-button-disabled, .darkTheme .mat-fab.mat-button-disabled.mat-button-disabled, .darkTheme .mat-mini-fab.mat-primary.mat-button-disabled, .darkTheme .mat-mini-fab.mat-accent.mat-button-disabled, .darkTheme .mat-mini-fab.mat-warn.mat-button-disabled, .darkTheme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.darkTheme .mat-flat-button.mat-primary, .darkTheme .mat-raised-button.mat-primary, .darkTheme .mat-fab.mat-primary, .darkTheme .mat-mini-fab.mat-primary {
  background-color: #47b2f7;
}
.darkTheme .mat-flat-button.mat-accent, .darkTheme .mat-raised-button.mat-accent, .darkTheme .mat-fab.mat-accent, .darkTheme .mat-mini-fab.mat-accent {
  background-color: #00c292;
}
.darkTheme .mat-flat-button.mat-warn, .darkTheme .mat-raised-button.mat-warn, .darkTheme .mat-fab.mat-warn, .darkTheme .mat-mini-fab.mat-warn {
  background-color: #d32f2f;
}
.darkTheme .mat-flat-button.mat-primary.mat-button-disabled, .darkTheme .mat-flat-button.mat-accent.mat-button-disabled, .darkTheme .mat-flat-button.mat-warn.mat-button-disabled, .darkTheme .mat-flat-button.mat-button-disabled.mat-button-disabled, .darkTheme .mat-raised-button.mat-primary.mat-button-disabled, .darkTheme .mat-raised-button.mat-accent.mat-button-disabled, .darkTheme .mat-raised-button.mat-warn.mat-button-disabled, .darkTheme .mat-raised-button.mat-button-disabled.mat-button-disabled, .darkTheme .mat-fab.mat-primary.mat-button-disabled, .darkTheme .mat-fab.mat-accent.mat-button-disabled, .darkTheme .mat-fab.mat-warn.mat-button-disabled, .darkTheme .mat-fab.mat-button-disabled.mat-button-disabled, .darkTheme .mat-mini-fab.mat-primary.mat-button-disabled, .darkTheme .mat-mini-fab.mat-accent.mat-button-disabled, .darkTheme .mat-mini-fab.mat-warn.mat-button-disabled, .darkTheme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .mat-flat-button.mat-primary .mat-ripple-element, .darkTheme .mat-raised-button.mat-primary .mat-ripple-element, .darkTheme .mat-fab.mat-primary .mat-ripple-element, .darkTheme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .mat-flat-button.mat-accent .mat-ripple-element, .darkTheme .mat-raised-button.mat-accent .mat-ripple-element, .darkTheme .mat-fab.mat-accent .mat-ripple-element, .darkTheme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .mat-flat-button.mat-warn .mat-ripple-element, .darkTheme .mat-raised-button.mat-warn .mat-ripple-element, .darkTheme .mat-fab.mat-warn .mat-ripple-element, .darkTheme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .mat-stroked-button:not([class*=mat-elevation-z]), .darkTheme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-fab:not([class*=mat-elevation-z]), .darkTheme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .darkTheme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .darkTheme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.darkTheme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.darkTheme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.darkTheme .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.darkTheme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.darkTheme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.darkTheme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.darkTheme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.darkTheme .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.darkTheme .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.darkTheme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.darkTheme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.darkTheme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.darkTheme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.darkTheme .mat-card {
  background: #424242;
  color: white;
}
.darkTheme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .mat-checkbox-checkmark {
  fill: #303030;
}
.darkTheme .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.darkTheme .mat-checkbox-mixedmark {
  background-color: #303030;
}
.darkTheme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .darkTheme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #47b2f7;
}
.darkTheme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .darkTheme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #00c292;
}
.darkTheme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .darkTheme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #d32f2f;
}
.darkTheme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .darkTheme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.darkTheme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.darkTheme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.darkTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.darkTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #47b2f7;
}
.darkTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.darkTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #00c292;
}
.darkTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.darkTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #d32f2f;
}
.darkTheme .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.darkTheme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.darkTheme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.darkTheme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.darkTheme .mat-chip.mat-standard-chip::after {
  background: white;
}
.darkTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #47b2f7;
  color: white;
}
.darkTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.darkTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #d32f2f;
  color: white;
}
.darkTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.darkTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #00c292;
  color: white;
}
.darkTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.darkTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .mat-table {
  background: #424242;
}
.darkTheme .mat-table thead, .darkTheme .mat-table tbody, .darkTheme .mat-table tfoot,
.darkTheme mat-header-row, .darkTheme mat-row, .darkTheme mat-footer-row,
.darkTheme [mat-header-row], .darkTheme [mat-row], .darkTheme [mat-footer-row],
.darkTheme .mat-table-sticky {
  background: inherit;
}
.darkTheme mat-row, .darkTheme mat-header-row, .darkTheme mat-footer-row,
.darkTheme th.mat-header-cell, .darkTheme td.mat-cell, .darkTheme td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .mat-cell, .darkTheme .mat-footer-cell {
  color: white;
}
.darkTheme .mat-calendar-arrow {
  fill: white;
}
.darkTheme .mat-datepicker-toggle,
.darkTheme .mat-datepicker-content .mat-calendar-next-button,
.darkTheme .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.darkTheme .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.darkTheme .mat-calendar-table-header,
.darkTheme .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .mat-calendar-body-cell-content,
.darkTheme .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.darkTheme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.darkTheme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .mat-calendar-body-in-range::before {
  background: rgba(71, 178, 247, 0.2);
}
.darkTheme .mat-calendar-body-comparison-identical,
.darkTheme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.darkTheme .mat-calendar-body-comparison-bridge-start::before,
.darkTheme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(71, 178, 247, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .mat-calendar-body-comparison-bridge-end::before,
.darkTheme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(71, 178, 247, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.darkTheme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.darkTheme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.darkTheme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.darkTheme .mat-calendar-body-selected {
  background-color: #47b2f7;
  color: white;
}
.darkTheme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(71, 178, 247, 0.4);
}
.darkTheme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.darkTheme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.darkTheme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(71, 178, 247, 0.3);
}
@media (hover: hover) {
  .darkTheme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(71, 178, 247, 0.3);
  }
}
.darkTheme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.darkTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(0, 194, 146, 0.2);
}
.darkTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.darkTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.darkTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.darkTheme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 194, 146, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.darkTheme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 194, 146, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.darkTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.darkTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.darkTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.darkTheme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #00c292;
  color: white;
}
.darkTheme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 194, 146, 0.4);
}
.darkTheme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.darkTheme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.darkTheme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 194, 146, 0.3);
}
@media (hover: hover) {
  .darkTheme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 194, 146, 0.3);
  }
}
.darkTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(211, 47, 47, 0.2);
}
.darkTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.darkTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.darkTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.darkTheme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(211, 47, 47, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.darkTheme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(211, 47, 47, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.darkTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.darkTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.darkTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.darkTheme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #d32f2f;
  color: white;
}
.darkTheme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(211, 47, 47, 0.4);
}
.darkTheme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.darkTheme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.darkTheme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(211, 47, 47, 0.3);
}
@media (hover: hover) {
  .darkTheme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(211, 47, 47, 0.3);
  }
}
.darkTheme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-datepicker-toggle-active {
  color: #47b2f7;
}
.darkTheme .mat-datepicker-toggle-active.mat-accent {
  color: #00c292;
}
.darkTheme .mat-datepicker-toggle-active.mat-warn {
  color: #d32f2f;
}
.darkTheme .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.darkTheme .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .mat-expansion-panel {
  background: #424242;
  color: white;
}
.darkTheme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .darkTheme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .darkTheme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .darkTheme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.darkTheme .mat-expansion-panel-header-title {
  color: white;
}
.darkTheme .mat-expansion-panel-header-description,
.darkTheme .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.darkTheme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.darkTheme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.darkTheme .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .mat-form-field.mat-focused .mat-form-field-label {
  color: #47b2f7;
}
.darkTheme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #00c292;
}
.darkTheme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #d32f2f;
}
.darkTheme .mat-focused .mat-form-field-required-marker {
  color: #00c292;
}
.darkTheme .mat-form-field-ripple {
  background-color: white;
}
.darkTheme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #47b2f7;
}
.darkTheme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #00c292;
}
.darkTheme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #d32f2f;
}
.darkTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #47b2f7;
}
.darkTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #00c292;
}
.darkTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #d32f2f;
}
.darkTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #d32f2f;
}
.darkTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.darkTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #d32f2f;
}
.darkTheme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.darkTheme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #d32f2f;
}
.darkTheme .mat-error {
  color: #d32f2f;
}
.darkTheme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.darkTheme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.darkTheme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.darkTheme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.darkTheme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.darkTheme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.darkTheme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #47b2f7;
}
.darkTheme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #00c292;
}
.darkTheme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #d32f2f;
}
.darkTheme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #d32f2f;
}
.darkTheme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.darkTheme .mat-icon.mat-primary {
  color: #47b2f7;
}
.darkTheme .mat-icon.mat-accent {
  color: #00c292;
}
.darkTheme .mat-icon.mat-warn {
  color: #d32f2f;
}
.darkTheme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .mat-input-element:disabled,
.darkTheme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-input-element {
  caret-color: #47b2f7;
}
.darkTheme .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.darkTheme .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.darkTheme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #00c292;
}
.darkTheme .mat-form-field.mat-warn .mat-input-element,
.darkTheme .mat-form-field-invalid .mat-input-element {
  caret-color: #d32f2f;
}
.darkTheme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #d32f2f;
}
.darkTheme .mat-list-base .mat-list-item {
  color: white;
}
.darkTheme .mat-list-base .mat-list-option {
  color: white;
}
.darkTheme .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-list-option:hover, .darkTheme .mat-list-option:focus,
.darkTheme .mat-nav-list .mat-list-item:hover,
.darkTheme .mat-nav-list .mat-list-item:focus,
.darkTheme .mat-action-list .mat-list-item:hover,
.darkTheme .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.darkTheme .mat-list-single-selected-option, .darkTheme .mat-list-single-selected-option:hover, .darkTheme .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.darkTheme .mat-menu-panel {
  background: #424242;
}
.darkTheme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-menu-item {
  background: transparent;
  color: white;
}
.darkTheme .mat-menu-item[disabled],
.darkTheme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.darkTheme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-menu-item .mat-icon-no-color,
.darkTheme .mat-menu-submenu-icon {
  color: white;
}
.darkTheme .mat-menu-item:hover:not([disabled]),
.darkTheme .mat-menu-item.cdk-program-focused:not([disabled]),
.darkTheme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.darkTheme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.darkTheme .mat-paginator {
  background: #424242;
}
.darkTheme .mat-paginator,
.darkTheme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .mat-paginator-decrement,
.darkTheme .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.darkTheme .mat-paginator-first,
.darkTheme .mat-paginator-last {
  border-top: 2px solid white;
}
.darkTheme .mat-icon-button[disabled] .mat-paginator-decrement,
.darkTheme .mat-icon-button[disabled] .mat-paginator-increment,
.darkTheme .mat-icon-button[disabled] .mat-paginator-first,
.darkTheme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-progress-bar-background {
  fill: #365162;
}
.darkTheme .mat-progress-bar-buffer {
  background-color: #365162;
}
.darkTheme .mat-progress-bar-fill::after {
  background-color: #47b2f7;
}
.darkTheme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #245549;
}
.darkTheme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #245549;
}
.darkTheme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #00c292;
}
.darkTheme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #593030;
}
.darkTheme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #593030;
}
.darkTheme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #d32f2f;
}
.darkTheme .mat-progress-spinner circle, .darkTheme .mat-spinner circle {
  stroke: #47b2f7;
}
.darkTheme .mat-progress-spinner.mat-accent circle, .darkTheme .mat-spinner.mat-accent circle {
  stroke: #00c292;
}
.darkTheme .mat-progress-spinner.mat-warn circle, .darkTheme .mat-spinner.mat-warn circle {
  stroke: #d32f2f;
}
.darkTheme .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #47b2f7;
}
.darkTheme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.darkTheme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .darkTheme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .darkTheme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #47b2f7;
}
.darkTheme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #00c292;
}
.darkTheme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.darkTheme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .darkTheme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .darkTheme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #00c292;
}
.darkTheme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #d32f2f;
}
.darkTheme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.darkTheme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .darkTheme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .darkTheme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #d32f2f;
}
.darkTheme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.darkTheme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.darkTheme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.darkTheme .mat-select-value {
  color: white;
}
.darkTheme .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .mat-select-panel {
  background: #424242;
}
.darkTheme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.darkTheme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #47b2f7;
}
.darkTheme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #00c292;
}
.darkTheme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #d32f2f;
}
.darkTheme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #d32f2f;
}
.darkTheme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.darkTheme .mat-drawer {
  background-color: #424242;
  color: white;
}
.darkTheme .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.darkTheme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.darkTheme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.darkTheme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.darkTheme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.darkTheme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.darkTheme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #00c292;
}
.darkTheme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 194, 146, 0.54);
}
.darkTheme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #00c292;
}
.darkTheme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #47b2f7;
}
.darkTheme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(71, 178, 247, 0.54);
}
.darkTheme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #47b2f7;
}
.darkTheme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #d32f2f;
}
.darkTheme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(211, 47, 47, 0.54);
}
.darkTheme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #d32f2f;
}
.darkTheme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.darkTheme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.darkTheme .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .mat-slider.mat-primary .mat-slider-track-fill,
.darkTheme .mat-slider.mat-primary .mat-slider-thumb,
.darkTheme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #47b2f7;
}
.darkTheme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.darkTheme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(71, 178, 247, 0.2);
}
.darkTheme .mat-slider.mat-accent .mat-slider-track-fill,
.darkTheme .mat-slider.mat-accent .mat-slider-thumb,
.darkTheme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #00c292;
}
.darkTheme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.darkTheme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(0, 194, 146, 0.2);
}
.darkTheme .mat-slider.mat-warn .mat-slider-track-fill,
.darkTheme .mat-slider.mat-warn .mat-slider-thumb,
.darkTheme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #d32f2f;
}
.darkTheme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.darkTheme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(211, 47, 47, 0.2);
}
.darkTheme .mat-slider:hover .mat-slider-track-background,
.darkTheme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.darkTheme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.darkTheme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.darkTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.darkTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.darkTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.darkTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .darkTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .darkTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.darkTheme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.darkTheme .mat-step-header.cdk-keyboard-focused, .darkTheme .mat-step-header.cdk-program-focused, .darkTheme .mat-step-header:hover:not([aria-disabled]), .darkTheme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.darkTheme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .darkTheme .mat-step-header:hover {
    background: none;
  }
}
.darkTheme .mat-step-header .mat-step-label,
.darkTheme .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.darkTheme .mat-step-header .mat-step-icon-selected,
.darkTheme .mat-step-header .mat-step-icon-state-done,
.darkTheme .mat-step-header .mat-step-icon-state-edit {
  background-color: #47b2f7;
  color: white;
}
.darkTheme .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.darkTheme .mat-step-header.mat-accent .mat-step-icon-selected,
.darkTheme .mat-step-header.mat-accent .mat-step-icon-state-done,
.darkTheme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #00c292;
  color: white;
}
.darkTheme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.darkTheme .mat-step-header.mat-warn .mat-step-icon-selected,
.darkTheme .mat-step-header.mat-warn .mat-step-icon-state-done,
.darkTheme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #d32f2f;
  color: white;
}
.darkTheme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #d32f2f;
}
.darkTheme .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.darkTheme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #d32f2f;
}
.darkTheme .mat-stepper-horizontal, .darkTheme .mat-stepper-vertical {
  background-color: #424242;
}
.darkTheme .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .mat-horizontal-stepper-header::before,
.darkTheme .mat-horizontal-stepper-header::after,
.darkTheme .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .mat-sort-header-arrow {
  color: #c6c6c6;
}
.darkTheme .mat-tab-nav-bar,
.darkTheme .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.darkTheme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.darkTheme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.darkTheme .mat-tab-label, .darkTheme .mat-tab-link {
  color: white;
}
.darkTheme .mat-tab-label.mat-tab-disabled, .darkTheme .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-tab-header-pagination-chevron {
  border-color: white;
}
.darkTheme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.darkTheme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.darkTheme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(243, 201, 189, 0.3);
}
.darkTheme .mat-tab-group.mat-primary .mat-ink-bar, .darkTheme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #47b2f7;
}
.darkTheme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .darkTheme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .darkTheme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .darkTheme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.darkTheme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(3, 201, 215, 0.3);
}
.darkTheme .mat-tab-group.mat-accent .mat-ink-bar, .darkTheme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #00c292;
}
.darkTheme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .darkTheme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .darkTheme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .darkTheme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.darkTheme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.darkTheme .mat-tab-group.mat-warn .mat-ink-bar, .darkTheme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #d32f2f;
}
.darkTheme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .darkTheme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .darkTheme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .darkTheme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.darkTheme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(243, 201, 189, 0.3);
}
.darkTheme .mat-tab-group.mat-background-primary > .mat-tab-header, .darkTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .darkTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .darkTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .darkTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .darkTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #47b2f7;
}
.darkTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .darkTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .darkTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .darkTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.darkTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .darkTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.darkTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .darkTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.darkTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .darkTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.darkTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.darkTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.darkTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .darkTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.darkTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.darkTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.darkTheme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(3, 201, 215, 0.3);
}
.darkTheme .mat-tab-group.mat-background-accent > .mat-tab-header, .darkTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .darkTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .darkTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .darkTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .darkTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #00c292;
}
.darkTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .darkTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .darkTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .darkTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.darkTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .darkTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.darkTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .darkTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.darkTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .darkTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.darkTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.darkTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.darkTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .darkTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.darkTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.darkTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.darkTheme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.darkTheme .mat-tab-group.mat-background-warn > .mat-tab-header, .darkTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .darkTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .darkTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .darkTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .darkTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #d32f2f;
}
.darkTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .darkTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .darkTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .darkTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.darkTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .darkTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.darkTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .darkTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.darkTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .darkTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.darkTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.darkTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.darkTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .darkTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.darkTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.darkTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.darkTheme .mat-toolbar {
  background: #212121;
  color: white;
}
.darkTheme .mat-toolbar.mat-primary {
  background: #47b2f7;
  color: white;
}
.darkTheme .mat-toolbar.mat-accent {
  background: #00c292;
  color: white;
}
.darkTheme .mat-toolbar.mat-warn {
  background: #d32f2f;
  color: white;
}
.darkTheme .mat-toolbar .mat-form-field-underline,
.darkTheme .mat-toolbar .mat-form-field-ripple,
.darkTheme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.darkTheme .mat-toolbar .mat-form-field-label,
.darkTheme .mat-toolbar .mat-focused .mat-form-field-label,
.darkTheme .mat-toolbar .mat-select-value,
.darkTheme .mat-toolbar .mat-select-arrow,
.darkTheme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.darkTheme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.darkTheme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.darkTheme .mat-tree {
  background: #424242;
}
.darkTheme .mat-tree-node,
.darkTheme .mat-nested-tree-node {
  color: white;
}
.darkTheme .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .mat-simple-snackbar-action {
  color: inherit;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: "DM Sans", sans-serif;;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 500 36px / 36px "DM Sans", sans-serif;;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 24px / 36px "DM Sans", sans-serif;;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 500 16px / 16px "DM Sans", sans-serif;;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 500 15px / 15px "DM Sans", sans-serif;;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px "DM Sans", sans-serif;;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px "DM Sans", sans-serif;;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px "DM Sans", sans-serif;;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px "DM Sans", sans-serif;;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px "DM Sans", sans-serif;;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px "DM Sans", sans-serif;;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px "DM Sans", sans-serif;;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px "DM Sans", sans-serif;;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px "DM Sans", sans-serif;;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px "DM Sans", sans-serif;;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: "DM Sans", sans-serif;;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: "DM Sans", sans-serif;;
}

.mat-card {
  font-family: "DM Sans", sans-serif;;
}

.mat-card-title {
  font-size: 36px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 24px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: "DM Sans", sans-serif;;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: "DM Sans", sans-serif;;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: "DM Sans", sans-serif;;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 24px / 36px "DM Sans", sans-serif;;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: "DM Sans", sans-serif;;
  font-size: 15px;
  font-weight: 500;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px "DM Sans", sans-serif;;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: "DM Sans", sans-serif;;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: "DM Sans", sans-serif;;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: "DM Sans", sans-serif;;
  font-size: 12px;
}

.mat-radio-button {
  font-family: "DM Sans", sans-serif;;
}

.mat-select {
  font-family: "DM Sans", sans-serif;;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: "DM Sans", sans-serif;;
}

.mat-slider-thumb-label-text {
  font-family: "DM Sans", sans-serif;;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: "DM Sans", sans-serif;;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: "DM Sans", sans-serif;;
}

.mat-tab-label, .mat-tab-link {
  font-family: "DM Sans", sans-serif;;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 24px / 36px "DM Sans", sans-serif;;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: "DM Sans", sans-serif;;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: "DM Sans", sans-serif;;
}

.mat-list-option {
  font-family: "DM Sans", sans-serif;;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: "DM Sans", sans-serif;;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: "DM Sans", sans-serif;;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: "DM Sans", sans-serif;;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px "DM Sans", sans-serif;;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: "DM Sans", sans-serif;;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: "DM Sans", sans-serif;;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

/*Light colors*/
.m-t-0 {
  margin-top: 0px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-0 {
  margin: 0px !important;
}

.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.ltr .m-l-0 {
  margin-left: 0px !important;
}

.ltr .m-l-5 {
  margin-left: 5px !important;
}

.ltr .m-l-10 {
  margin-left: 10px !important;
}

.ltr .m-l-15 {
  margin-left: 15px !important;
}

.ltr .m-l-20 {
  margin-left: 20px !important;
}

.ltr .m-l-25 {
  margin-left: 25px !important;
}

.ltr .m-l-30 {
  margin-left: 30px !important;
}

.ltr .m-r-0 {
  margin-right: 0px !important;
}

.ltr .m-r-5 {
  margin-right: 5px !important;
}

.ltr .m-r-10 {
  margin-right: 10px !important;
}

.ltr .m-r-15 {
  margin-right: 15px !important;
}

.ltr .m-r-20 {
  margin-right: 20px !important;
}

.ltr .m-r-25 {
  margin-right: 25px !important;
}

.ltr .m-r-30 {
  margin-right: 30px !important;
}

.rtl .m-l-0 {
  margin-right: 0px !important;
}

.rtl .m-l-5 {
  margin-right: 5px !important;
}

.rtl .m-l-10 {
  margin-right: 10px !important;
}

.rtl .m-l-15 {
  margin-right: 15px !important;
}

.rtl .m-l-20 {
  margin-right: 20px !important;
}

.rtl .m-l-25 {
  margin-right: 25px !important;
}

.rtl .m-l-30 {
  margin-right: 30px !important;
}

.rtl .m-r-0 {
  margin-left: 0px !important;
}

.rtl .m-r-5 {
  margin-left: 5px !important;
}

.rtl .m-r-10 {
  margin-left: 10px !important;
}

.rtl .m-r-15 {
  margin-left: 15px !important;
}

.rtl .m-r-20 {
  margin-left: 20px !important;
}

.rtl .m-r-25 {
  margin-left: 25px !important;
}

.rtl .m-r-30 {
  margin-left: 30px !important;
}

.p-t-0 {
  padding-top: 0px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-0 {
  padding: 0px !important;
}

.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-b-0 {
  padding-bottom: 0px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.ltr .p-l-0 {
  padding-left: 0px !important;
}

.ltr .p-l-5 {
  padding-left: 5px !important;
}

.ltr .p-l-10 {
  padding-left: 10px !important;
}

.ltr .p-l-15 {
  padding-left: 15px !important;
}

.ltr .p-l-20 {
  padding-left: 20px !important;
}

.ltr .p-l-25 {
  padding-left: 25px !important;
}

.ltr .p-l-30 {
  padding-left: 30px !important;
}

.ltr .p-r-0 {
  padding-right: 0px !important;
}

.ltr .p-r-5 {
  padding-right: 5px !important;
}

.ltr .p-r-10 {
  padding-right: 10px !important;
}

.ltr .p-r-15 {
  padding-right: 15px !important;
}

.ltr .p-r-20 {
  padding-right: 20px !important;
}

.ltr .p-r-25 {
  padding-right: 25px !important;
}

.ltr .p-r-30 {
  padding-right: 30px !important;
}

.rtl .p-r-0 {
  padding-left: 0px !important;
}

.rtl .p-r-5 {
  padding-left: 5px !important;
}

.rtl .p-r-10 {
  padding-left: 10px !important;
}

.rtl .p-r-15 {
  padding-left: 15px !important;
}

.rtl .p-r-20 {
  padding-left: 20px !important;
}

.rtl .p-r-25 {
  padding-left: 25px !important;
}

.rtl .p-r-30 {
  padding-left: 30px !important;
}

.rtl .p-l-0 {
  padding-right: 0px !important;
}

.rtl .p-l-5 {
  padding-right: 5px !important;
}

.rtl .p-l-10 {
  padding-right: 10px !important;
}

.rtl .p-l-15 {
  padding-right: 15px !important;
}

.rtl .p-l-20 {
  padding-right: 20px !important;
}

.rtl .p-l-25 {
  padding-right: 25px !important;
}

.rtl .p-l-30 {
  padding-right: 30px !important;
}

.bg-primary {
  background-color: #47B2F7 !important;
}

.text-primary {
  color: #47B2F7 !important;
}

.bg-accent {
  background-color: #00c292 !important;
}

.text-accent {
  color: #00c292 !important;
}

.bg-success {
  background-color: #00c292 !important;
}

.text-success {
  color: #00c292 !important;
}

.bg-warning {
  background-color: #fec90f !important;
}

.text-warning {
  color: #fec90f !important;
}

.bg-danger {
  background-color: #fc4b6c !important;
}

.text-danger {
  color: #fc4b6c !important;
}

.bg-info {
  background-color: #949db2 !important;
}

.text-info {
  color: #949db2 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.text-white {
  color: #ffffff !important;
}

.bg-muted {
  background-color: #777e89 !important;
}

.text-muted {
  color: #777e89 !important;
}

.bg-light {
  background-color: #949db2 !important;
}

.text-light {
  color: #949db2 !important;
}

.bg-light-danger {
  background-color: #f9e7eb !important;
}

.text-light-danger {
  color: #f9e7eb !important;
}

.bg-light-accent {
  background-color: #e5fafb !important;
}

.text-light-accent {
  color: #e5fafb !important;
}

.bg-light-success {
  background-color: #ebfaf2 !important;
}

.text-light-success {
  color: #ebfaf2 !important;
}

.bg-light-warning {
  background-color: #fff8ec !important;
}

.text-light-warning {
  color: #fff8ec !important;
}

.bg-light-primary {
  background-color: #f1effd !important;
}

.text-light-primary {
  color: #f1effd !important;
}

.bg-light-info {
  background-color: #e3f3fd !important;
}

.text-light-info {
  color: #e3f3fd !important;
}

.bg-light-inverse {
  background-color: #f6f6f6 !important;
}

.text-light-inverse {
  color: #f6f6f6 !important;
}

.bg-light-megna {
  background-color: #e0f2f4 !important;
}

.text-light-megna {
  color: #e0f2f4 !important;
}

.horizontal .page-wrapper {
  margin-left: 0 !important;
}

.leftsidebar {
  width: 265px;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  border: 0px;
}

.vsidebar,
.hsidebar {
  padding: 0 15px;
}
.vsidebar .routeIcon,
.hsidebar .routeIcon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.vsidebar .menu-list-item,
.hsidebar .menu-list-item {
  margin: 0 0 5px 0;
}
.vsidebar .menu-list-item .mat-list-item-content,
.hsidebar .menu-list-item .mat-list-item-content {
  border-radius: 6px;
  padding: 0 6px 0 10px;
}
.vsidebar .menu-list-item:hover,
.hsidebar .menu-list-item:hover {
  background-color: transparent;
}
.vsidebar .menu-list-item:hover .mat-list-item-content,
.hsidebar .menu-list-item:hover .mat-list-item-content {
  background-color: rgba(0, 0, 0, 0.05);
}

.minisidebar .leftsidebar {
  width: 80px;
}

.vsidebar .menu-list-item.activeMenu .mat-list-item-content, .vsidebar .menu-list-item.activeMenu:hover .mat-list-item-content,
.hsidebar .menu-list-item.activeMenu .mat-list-item-content,
.hsidebar .menu-list-item.activeMenu:hover .mat-list-item-content {
  color: #ffffff;
  background-color: #47B2F7;
}

.topbar .topsearch {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  width: 100%;
  padding: 15px;
  height: 64px;
}
.topbar .mat-textsmall {
  font-size: 16px;
}
.topbar .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent;
}

.fixedTopbar .main-container .topbar {
  position: sticky;
  z-index: 10;
  position: -webkit-sticky;
  top: 0;
  display: block;
}

.lightTheme .topsearch,
.lightTheme .topbar .mat-toolbar {
  background: #fafbfb;
}

.darkTheme .topsearch,
.darkTheme .topbar .mat-toolbar {
  background: #303030;
}

.topbardd {
  width: 385px;
  max-width: 385px !important;
  padding: 10px 20px 20px;
}
.topbardd .mat-list-base .mat-list-item .mat-list-icon.mat-list-item-img {
  width: 45px;
  height: 45px;
  line-height: 50px;
  text-align: center;
}
.topbardd .mat-list-base .mat-list-item .mat-list-icon.mat-list-item-img img {
  border-radius: 100%;
}
.topbardd .ddheadtitle {
  font-size: 18px;
}
.topbardd .ddtitle {
  font-weight: 500 !important;
}

.notify {
  position: absolute;
  top: 6px;
  right: 4px;
}
.notify .point {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  display: block;
}

.main-container .mat-card {
  border-radius: 20px;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08) !important;
  padding: 0;
  margin: 15px;
  overflow: hidden;
}
.main-container .mat-card .mat-card-content {
  padding: 30px;
}
.main-container .mat-card .mat-card-title {
  font-size: 21px;
}
.main-container .mat-chip.mat-standard-chip {
  min-height: 24px;
  font-size: 0.75rem;
  border-radius: 6px;
}
.main-container .mat-mini-fab {
  width: 40px;
  height: 40px;
  box-shadow: unset;
}
.main-container .mat-fab {
  width: 48px;
  height: 48px;
  box-shadow: unset;
}
.main-container .mat-fab .mat-button-wrapper {
  padding: 11px 0;
}
.main-container .mat-header-cell {
  font-size: 15px;
  color: inherit;
}
.main-container th.mat-header-cell,
.main-container td.mat-cell,
.main-container td.mat-footer-cell {
  padding: 16px;
}
.main-container .mat-progress-bar {
  border-radius: 3px;
}

.mat-form-field-label {
  font-weight: 500 !important;
}

.mat-checkbox-label {
  font-size: 15px;
}

.mat-elevation-z4 {
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
}

.mat-form-field {
  width: 100%;
}

.theme-select {
  width: 120px;
}
.theme-select .mat-form-field-infix {
  padding: 6px 0 1px !important;
}
.theme-select.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: translateY(-4%) !important;
}
.theme-select.mat-form-field-appearance-outline .mat-form-field-outline {
  height: 41px;
}

.customizerSidebar {
  width: 260px;
}

.customizerbtn.mat-fab {
  position: fixed;
  bottom: 15px;
  z-index: 1;
}

.ltr .customizerbtn.mat-fab {
  right: 25px;
}

.rtl .customizerbtn.mat-fab {
  left: 25px;
}

.themeColorOption {
  padding: 15px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.themeColorOption li {
  list-style: none;
  padding: 5px;
  line-height: 0;
  color: #fff;
  cursor: pointer;
  border-radius: 100%;
}
.themeColorOption li.orangeTheme {
  background-color: #47B2F7;
}
.themeColorOption li.greenTheme {
  background-color: #00c292;
}
.themeColorOption li.blueTheme {
  background-color: #949db2;
}
.themeColorOption li.redTheme {
  background-color: #fc4b6c;
}
.themeColorOption li.indigoTheme {
  background-color: #132e6e;
}
.themeColorOption li.purpleTheme {
  background-color: #6018e6;
}

* {
  box-sizing: border-box;
}

body {
  font-family: "DM Sans", sans-serif;
}

.main-container .page-wrapper {
  height: calc(100vh - 0px);
}
.main-container .page-content {
  max-width: 1300px;
  padding: 30px 15px;
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

html .cursor-pointer {
  cursor: pointer;
}
html .hstack {
  display: flex;
  flex-direction: row;
}
html .vstack {
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
}
html .gap-1 {
  gap: 10px;
}
html .gap-2 {
  gap: 15px;
}
html .gap-3 {
  gap: 20px;
}
html .gap-4 {
  gap: 30px;
}
html .fw-medium {
  font-weight: 500 !important;
}
html .fw-normal {
  font-weight: 400;
}
html .fw-bold {
  font-weight: 600;
}
html .fw-bolder {
  font-weight: 700;
}
html .op-5 {
  opacity: 0.5;
}
html .fs-18 {
  font-size: 18px;
}
html .fs-30 {
  font-size: 30px;
}
html .text-center {
  text-align: center;
}
html .text-right {
  text-align: right;
}
html .text-left {
  text-align: left;
}
html a.link {
  color: #455a64;
  text-decoration: none;
}
html a.link:hover {
  color: #47B2F7;
}
html .line-height-0 {
  line-height: 0;
}

.w-100 {
  width: 100%;
}

.d-flex {
  display: flex;
}

.ml-auto {
  margin-left: auto;
}

.flex-column {
  flex-direction: column;
}

.d-none {
  display: none !important;
}

.hidden {
  visibility: hidden !important;
}

.align-items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-content-between {
  justify-content: space-between;
}

.d-flex-vh-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.position-relative {
  position: relative;
}

.feather-10 {
  line-height: 10px;
  width: 10px !important;
  height: 10px !important;
}
.feather-10 svg {
  width: 10px;
  height: 10px;
}

.feather-14 {
  line-height: 14px;
  width: 14px !important;
  height: 14px !important;
}
.feather-14 svg {
  width: 14px;
  height: 14px;
}

.feather-18 {
  line-height: 18px;
  width: 18px !important;
  height: 18px !important;
}
.feather-18 svg {
  width: 18px;
  height: 18px;
}

.feather-20 {
  line-height: 20px;
  width: 20px;
  height: 20px;
}
.feather-20 svg {
  width: 20px;
  height: 20px;
}

.feather-30 {
  line-height: 30px;
  width: 30px;
  height: 30px;
}
.feather-30 svg {
  width: 30px;
  height: 30px;
}

.feather-40 {
  line-height: 40px;
  width: 40px;
  height: 40px;
}
.feather-40 svg {
  width: 40px;
  height: 40px;
}

.fill-accent svg {
  fill: #00c292;
  color: #00c292;
}

.fill-primary svg {
  fill: #47B2F7;
  color: #47B2F7;
}

.fill-warning svg {
  fill: #fec90f;
  color: #fec90f;
}

.fill-muted svg {
  fill: #777e89;
  color: #777e89;
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.border-left {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.rounded-circle {
  border-radius: 100%;
}

.rounded-lg {
  border-radius: 6px !important;
}

.rounded-xl {
  border-radius: 10px !important;
}

.rounded-xxl {
  border-radius: 20px !important;
}

.thumb-md {
  width: 50px !important;
  height: 40px !important;
}

.lh-lg {
  line-height: 35px;
}

.lh-md {
  line-height: 1.5;
}

.lh-sm {
  line-height: 24px;
}

@media (max-width: 1099px) {
  .d-md-block {
    display: block !important;
  }
}
@media (min-width: 1100px) {
  .d-lg-block {
    display: block !important;
  }
}
.d-none {
  display: none;
}

@media (max-width: 600px) {
  .d-sm-block {
    display: block !important;
  }
}
@media (min-width: 600px) {
  .hastack-md {
    display: flex;
  }
  .vstack-md {
    display: flex;
  }
}
.table-responsive {
  overflow: auto;
}

.button-group button {
  margin-right: 8px;
  margin-bottom: 8px;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (min-width: 1280px) {
  .d-lg-none {
    display: none;
  }
}
.no-wrap {
  white-space: nowrap;
}

a {
  text-decoration: none;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.m-0 {
  margin: 0;
}

.ml-10 {
  margin-left: 10px;
}

.horizontal.main-container .page-content {
  padding: 30px 15px;
}
.horizontal .horizontal-container {
  max-width: 1270px;
  margin: 0 auto;
}
.horizontal .hori-navbar {
  margin: 0px 0 30px 0;
  background: white;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  position: relative;
}
.horizontal .hori-navbar .parentBox {
  position: relative;
  z-index: 5;
  margin: 5px 0;
}
.horizontal .hori-navbar .parentBox.mega-menu {
  position: static;
}
.horizontal .hori-navbar .parentBox.mega-menu:hover > .childBox > .ddmenu {
  display: inline-block;
}
.horizontal .hori-navbar .parentBox.mega-menu > .childBox {
  width: 100%;
  left: 0;
}
.horizontal .hori-navbar .parentBox.mega-menu > .childBox > .ddmenu {
  width: 24%;
}
.horizontal .hori-navbar .parentBox.two-column:hover > .childBox > .ddmenu {
  display: inline-block;
}
.horizontal .hori-navbar .parentBox.two-column > .childBox {
  width: 600px;
}
.horizontal .hori-navbar .parentBox.two-column > .childBox > .ddmenu {
  width: 49%;
}
.horizontal .hori-navbar .parentBox .menuLink {
  padding: 10px;
  border-radius: 6px;
}
.horizontal .hori-navbar .parentBox:hover > .menuLink {
  background-color: rgba(0, 0, 0, 0.025);
}
.horizontal .hori-navbar .parentBox:hover > .activeMenu,
.horizontal .hori-navbar .parentBox .activeMenu {
  color: #ffffff;
  background-color: #00c292;
}
.horizontal .hori-navbar .parentBox .down-icon {
  line-height: 0;
}
.horizontal .hori-navbar .parentBox .childBox {
  border-radius: 8px;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  position: absolute;
  width: 300px;
}
.horizontal .hori-navbar .parentBox .childBox .ddmenu {
  display: none;
  padding: 10px;
  position: relative;
}
.horizontal .hori-navbar .parentBox:hover > .childBox > .ddmenu:hover {
  background-color: rgba(0, 0, 0, 0.025);
}
.horizontal .hori-navbar .parentBox:hover > .childBox > .ddmenu:hover:hover > .childBox > .ddmenu:hover {
  background-color: rgba(0, 0, 0, 0.025);
}
.horizontal .hori-navbar .parentBox:hover > .childBox > .ddmenu:hover > .childBox {
  left: 300px;
  top: 0px;
  z-index: 9;
}
.horizontal .hori-navbar .parentBox:hover > .childBox > .ddmenu:hover > .childBox > .ddmenu:hover > .childBox {
  left: 300px;
  top: 0;
}
.horizontal .hori-navbar .parentBox:hover > .childBox > .ddmenu {
  display: block;
}
.horizontal .hori-navbar .parentBox:hover > .childBox > .ddmenu:hover > .childBox > .ddmenu {
  display: block;
}
.horizontal .hori-navbar .parentBox:hover > .childBox > .ddmenu:hover > .childBox > .ddmenu:hover > .childBox > .ddmenu {
  display: block;
}

.lightTheme .horizontal .topbar {
  background: #fff;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
}
.lightTheme .horizontal .hori-navbar,
.lightTheme .horizontal .childBox {
  background: #fff;
}

.darkTheme .horizontal .topbar {
  background: #202020;
}
.darkTheme .horizontal .hori-navbar,
.darkTheme .horizontal .childBox {
  background: #424242;
}

@media (min-width: 1100px) {
  .horizontal .leftsidebar {
    display: none;
  }
}
.ltr .horizontal .hori-navbar .parentBox:last-child:hover > .childBox > .ddmenu:hover > .childBox {
  right: 300px;
  left: unset;
}
.ltr .horizontal .hori-navbar .parentBox:last-child:hover > .childBox > .ddmenu:hover > .childBox > .ddmenu:hover > .childBox {
  right: 300px;
  left: unset;
}

.rtl .horizontal .hori-navbar .parentBox:last-child:hover > .childBox > .ddmenu:hover > .childBox {
  left: 300px;
  right: unset;
}
.rtl .horizontal .hori-navbar .parentBox:last-child:hover > .childBox > .ddmenu:hover > .childBox > .ddmenu:hover > .childBox {
  left: 300px;
  right: unset;
}

@media (min-width: 1100px) {
  .mainboxContainer.minisidebar .leftsidebar .brand {
    width: 58px;
    overflow: hidden;
    margin: 0 auto;
  }
  .mainboxContainer.minisidebar .leftsidebar:hover {
    width: 265px;
    transition: width 0.2s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  .mainboxContainer.minisidebar .leftsidebar:hover .brand {
    width: 100%;
    overflow: unset;
    margin: unset;
  }
  .mainboxContainer.minisidebar .leftsidebar:hover .vsidebar .vmenu-list-item .mat-small {
    width: 100%;
  }
  .mainboxContainer.minisidebar .leftsidebar:hover .vsidebar .vmenu-list-item .menu-list-item .routeIcon {
    margin-right: 10px;
  }
  .mainboxContainer.minisidebar .leftsidebar:hover .vsidebar .vmenu-list-item .menu-list-item .hide-menu,
.mainboxContainer.minisidebar .leftsidebar:hover .vsidebar .vmenu-list-item .menu-list-item .arrow-icon {
    display: flex !important;
  }
  .mainboxContainer.minisidebar .leftsidebar .vsidebar .vmenu-list-item .mat-small {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 45px;
    display: block;
  }
  .mainboxContainer.minisidebar .leftsidebar .vsidebar .vmenu-list-item .menu-list-item .routeIcon {
    margin-right: 0;
  }
  .mainboxContainer.minisidebar .leftsidebar .vsidebar .vmenu-list-item .menu-list-item .hide-menu,
.mainboxContainer.minisidebar .leftsidebar .vsidebar .vmenu-list-item .menu-list-item .arrow-icon {
    display: none !important;
  }
}
@media (max-width: 1099px) {
  .mainboxContainer.minisidebar .leftsidebar {
    width: 265px;
  }
}
.activity {
  border-left: 2px solid #bdbdbd;
  position: relative;
}
.activity .activity-status {
  position: absolute;
  left: -1px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.activity .activity-status::before {
  position: absolute;
  width: 20px;
  height: 35px;
  content: "";
  background-color: #ffffff;
}
.activity .activity-status .status {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ffffff;
}
.activity .activity-status .status.status-success {
  border: 2px solid #00c292;
}
.activity .activity-status .status.status-primary {
  border: 2px solid #47B2F7;
}
.activity .activity-status .status.status-info {
  border: 2px solid #949db2;
}
.activity .activity-status .status.status-warning {
  border: 2px solid #fec90f;
}
.activity .activity-status .status.status-danger {
  border: 2px solid #fc4b6c;
}
.activity .activity-text {
  min-height: 60px;
}

@media (max-width: 1279px) {
  .detail-part.movetodetail {
    display: block;
    position: absolute;
    background: #ffffff;
    z-index: 9;
  }
  .welcome-app {
    display: none;
  }
}
.mail-pagination .ngx-pagination {
  margin: 2px 0 1px;
}

.price-listing .mat-list-item .mat-list-item-content {
  display: block !important;
}

.notes-app .mat-list-item-content {
  padding: 0 !important;
}

.rtl .mainboxContainer .welcome-card::before {
  background-position: right -9px top 24px;
  transform: scaleX(-1);
}
.rtl .mainboxContainer .earnings-bg::before {
  transform: scaleX(-1);
}
.rtl .mainboxContainer .activity {
  border-left: 0;
  border-right: 2px solid #bdbdbd;
}
.rtl .mainboxContainer .activity .activity-status {
  left: unset;
  right: -1px;
}
.rtl .ml-auto {
  margin-left: unset;
  margin-right: auto;
}
.rtl .border-right {
  border-right: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.rtl .border-left {
  border-left: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.rtl .text-right {
  text-align: left !important;
}
.rtl .note-color {
  right: 0;
  left: unset;
}

.darkTheme .bg-light-primary,
.darkTheme .cal-week-view .cal-starts-within-day .cal-event,
.darkTheme .cal-week-view .cal-time-events .cal-event {
  background-color: #644035 !important;
}
.darkTheme .bg-light-accent,
.darkTheme .cal-week-view .cal-header.cal-today {
  background-color: #046f76 !important;
}
.darkTheme .bg-light-inverse,
.darkTheme .cal-week-view .cal-ends-within-day .cal-event {
  background-color: #504f4f !important;
}
.darkTheme .bg-white {
  background-color: #424242 !important;
}
.darkTheme a.link,
.darkTheme a {
  color: #ffffff;
  text-decoration: none;
}
.darkTheme .cal-month-view,
.darkTheme .cal-week-view,
.darkTheme .cal-month-view .cal-cell-row .cal-cell:hover,
.darkTheme .cal-month-view .cal-cell.cal-has-events.cal-open,
.darkTheme .cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover,
.darkTheme .angular-editor-toolbar,
.darkTheme .ae-font .ae-picker-options,
.darkTheme .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.darkTheme .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background-color: #303030 !important;
}
.darkTheme .cal-month-view .cal-cell-row:hover,
.darkTheme .cal-week-view .cal-hour-odd,
.darkTheme .cal-week-view .cal-day-headers .cal-header:hover,
.darkTheme .cal-week-view .cal-day-headers .cal-drag-over,
.darkTheme .ae-font .ae-picker-label,
.darkTheme .angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button,
.darkTheme .activity .activity-status::before,
.darkTheme .activity .activity-status .status,
.darkTheme .ngx-datatable.material,
.darkTheme .ngx-datatable.material .datatable-header .datatable-header-cell {
  background-color: #424242 !important;
}
.darkTheme .cal-month-view .cal-day-cell:not(:last-child),
.darkTheme .cal-month-view .cal-days .cal-cell-row,
.darkTheme .cal-month-view .cal-days,
.darkTheme .cal-week-view .cal-day-headers,
.darkTheme .cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
.darkTheme .cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment,
.darkTheme .cal-week-view .cal-day-headers .cal-header:not(:last-child),
.darkTheme .cal-week-view .cal-time-events,
.darkTheme .cal-week-view .cal-day-column,
.darkTheme .angular-editor-toolbar,
.darkTheme .angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button,
.darkTheme .angular-editor .angular-editor-wrapper .angular-editor-textarea,
.darkTheme .ae-font .ae-picker-label,
.darkTheme .ae-font.ae-expanded .ae-picker-options,
.darkTheme .mat-table-sticky:first-child,
.darkTheme .mat-table-sticky:last-child,
.darkTheme .mat-table-sticky,
.darkTheme .ngx-datatable.material,
.darkTheme .ngx-datatable.material .datatable-header .resize-handle,
.darkTheme .ngx-datatable.material .datatable-header {
  border-color: rgba(255, 255, 255, 0.12) !important;
}
.darkTheme .notes-item.selected {
  background-color: #504f4f !important;
}
.darkTheme .text-muted,
.darkTheme .angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button,
.darkTheme .ae-font .ae-picker-label,
.darkTheme .ae-font .ae-picker-options,
.darkTheme .ae-font .ae-picker-options .ae-picker-item.selected,
.darkTheme .ae-font .ae-picker-options .ae-picker-item,
.darkTheme .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell,
.darkTheme .ngx-datatable.material .datatable-header .datatable-header-cell,
.darkTheme .ngx-datatable.material .datatable-footer .datatable-pager a,
.darkTheme .ngx-datatable.material .datatable-footer {
  color: #adb0bb !important;
}
.darkTheme .ae-font .ae-picker-label svg .ae-stroke {
  stroke: #adb0bb !important;
}
.darkTheme .ae-font .ae-picker-label:before,
.darkTheme .ae-font .ae-picker-label:hover:before {
  background: linear-gradient(to right, #303030, #303030 100%);
}

.table-responsive .header-label {
  display: none;
}

@media (max-width: 600px) {
  .table-responsive .mat-header-row {
    display: none;
  }
  .table-responsive mat-cell:first-of-type,
.table-responsive mat-header-cell:first-of-type,
.table-responsive mat-footer-cell:first-of-type {
    padding-left: 0;
  }
  .table-responsive .mat-row {
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 15px;
  }
  .table-responsive .header-label {
    width: 80px;
    display: inline-block;
    font-weight: 600;
  }
}
.ngx-datatable.material {
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.login-register {
  height: 100vh;
  display: flex;
  background-size: cover;
  align-items: center;
}
.login-register .login-register-box {
  max-width: 400px;
  min-width: 280px;
  margin: 0 auto;
  vertical-align: middle;
}

.error-card h1 {
  font-size: 210px;
  font-weight: 800;
  line-height: 210px;
  margin: 0px;
  text-shadow: 4px 5px 1px #b3bbbf;
}

@media (max-width: 767px) {
  .error-card h1 {
    font-size: 120px;
    line-height: 120px;
  }
}
@media (min-width: 1200px) {
  .left-bg-img {
    position: absolute;
    height: 100vh;
    right: -50px;
  }
}
.left-bg-img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
}

.auth-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: 100vh;
  -webkit-box-pack: center;
  justify-content: center;
}

.right-bg-content {
  box-sizing: border-box;
  flex-flow: row wrap;
  width: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

.divider-text {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 110px;
  top: -11px;
  background: #fafafa;
  display: block;
  text-align: center;
}

.auth-brand {
  position: absolute;
  top: 0;
}

.lightTheme .blueTheme .leftsidebar .menu-list-item.activeMenu .mat-list-item-content, .lightTheme .blueTheme .leftsidebar .menu-list-item.activeMenu:hover .mat-list-item-content {
  background-color: #1a97f5;
}
.lightTheme .blueTheme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.lightTheme .blueTheme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-option:hover:not(.mat-option-disabled), .lightTheme .blueTheme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.lightTheme .blueTheme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.lightTheme .blueTheme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .blueTheme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #1e4db7;
}
.lightTheme .blueTheme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #1a97f5;
}
.lightTheme .blueTheme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e91e63;
}
.lightTheme .blueTheme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .blueTheme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .blueTheme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .blueTheme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.lightTheme .blueTheme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.lightTheme .blueTheme .mat-primary .mat-pseudo-checkbox-checked,
.lightTheme .blueTheme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #1e4db7;
}
.lightTheme .blueTheme .mat-pseudo-checkbox-checked,
.lightTheme .blueTheme .mat-pseudo-checkbox-indeterminate,
.lightTheme .blueTheme .mat-accent .mat-pseudo-checkbox-checked,
.lightTheme .blueTheme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #1a97f5;
}
.lightTheme .blueTheme .mat-warn .mat-pseudo-checkbox-checked,
.lightTheme .blueTheme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e91e63;
}
.lightTheme .blueTheme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.lightTheme .blueTheme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.lightTheme .blueTheme .mat-app-background, .lightTheme .blueTheme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.lightTheme .blueTheme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.lightTheme .blueTheme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-badge-content {
  color: white;
  background: #1e4db7;
}
.cdk-high-contrast-active .lightTheme .blueTheme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.lightTheme .blueTheme .mat-badge-accent .mat-badge-content {
  background: #1a97f5;
  color: white;
}
.lightTheme .blueTheme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e91e63;
}
.lightTheme .blueTheme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .blueTheme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-button, .lightTheme .blueTheme .mat-icon-button, .lightTheme .blueTheme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.lightTheme .blueTheme .mat-button.mat-primary, .lightTheme .blueTheme .mat-icon-button.mat-primary, .lightTheme .blueTheme .mat-stroked-button.mat-primary {
  color: #1e4db7;
}
.lightTheme .blueTheme .mat-button.mat-accent, .lightTheme .blueTheme .mat-icon-button.mat-accent, .lightTheme .blueTheme .mat-stroked-button.mat-accent {
  color: #1a97f5;
}
.lightTheme .blueTheme .mat-button.mat-warn, .lightTheme .blueTheme .mat-icon-button.mat-warn, .lightTheme .blueTheme .mat-stroked-button.mat-warn {
  color: #e91e63;
}
.lightTheme .blueTheme .mat-button.mat-primary.mat-button-disabled, .lightTheme .blueTheme .mat-button.mat-accent.mat-button-disabled, .lightTheme .blueTheme .mat-button.mat-warn.mat-button-disabled, .lightTheme .blueTheme .mat-button.mat-button-disabled.mat-button-disabled, .lightTheme .blueTheme .mat-icon-button.mat-primary.mat-button-disabled, .lightTheme .blueTheme .mat-icon-button.mat-accent.mat-button-disabled, .lightTheme .blueTheme .mat-icon-button.mat-warn.mat-button-disabled, .lightTheme .blueTheme .mat-icon-button.mat-button-disabled.mat-button-disabled, .lightTheme .blueTheme .mat-stroked-button.mat-primary.mat-button-disabled, .lightTheme .blueTheme .mat-stroked-button.mat-accent.mat-button-disabled, .lightTheme .blueTheme .mat-stroked-button.mat-warn.mat-button-disabled, .lightTheme .blueTheme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.lightTheme .blueTheme .mat-button.mat-primary .mat-button-focus-overlay, .lightTheme .blueTheme .mat-icon-button.mat-primary .mat-button-focus-overlay, .lightTheme .blueTheme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #1e4db7;
}
.lightTheme .blueTheme .mat-button.mat-accent .mat-button-focus-overlay, .lightTheme .blueTheme .mat-icon-button.mat-accent .mat-button-focus-overlay, .lightTheme .blueTheme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #1a97f5;
}
.lightTheme .blueTheme .mat-button.mat-warn .mat-button-focus-overlay, .lightTheme .blueTheme .mat-icon-button.mat-warn .mat-button-focus-overlay, .lightTheme .blueTheme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e91e63;
}
.lightTheme .blueTheme .mat-button.mat-button-disabled .mat-button-focus-overlay, .lightTheme .blueTheme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .lightTheme .blueTheme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.lightTheme .blueTheme .mat-button .mat-ripple-element, .lightTheme .blueTheme .mat-icon-button .mat-ripple-element, .lightTheme .blueTheme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.lightTheme .blueTheme .mat-button-focus-overlay {
  background: black;
}
.lightTheme .blueTheme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-flat-button, .lightTheme .blueTheme .mat-raised-button, .lightTheme .blueTheme .mat-fab, .lightTheme .blueTheme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.lightTheme .blueTheme .mat-flat-button.mat-primary, .lightTheme .blueTheme .mat-raised-button.mat-primary, .lightTheme .blueTheme .mat-fab.mat-primary, .lightTheme .blueTheme .mat-mini-fab.mat-primary {
  color: white;
}
.lightTheme .blueTheme .mat-flat-button.mat-accent, .lightTheme .blueTheme .mat-raised-button.mat-accent, .lightTheme .blueTheme .mat-fab.mat-accent, .lightTheme .blueTheme .mat-mini-fab.mat-accent {
  color: white;
}
.lightTheme .blueTheme .mat-flat-button.mat-warn, .lightTheme .blueTheme .mat-raised-button.mat-warn, .lightTheme .blueTheme .mat-fab.mat-warn, .lightTheme .blueTheme .mat-mini-fab.mat-warn {
  color: white;
}
.lightTheme .blueTheme .mat-flat-button.mat-primary.mat-button-disabled, .lightTheme .blueTheme .mat-flat-button.mat-accent.mat-button-disabled, .lightTheme .blueTheme .mat-flat-button.mat-warn.mat-button-disabled, .lightTheme .blueTheme .mat-flat-button.mat-button-disabled.mat-button-disabled, .lightTheme .blueTheme .mat-raised-button.mat-primary.mat-button-disabled, .lightTheme .blueTheme .mat-raised-button.mat-accent.mat-button-disabled, .lightTheme .blueTheme .mat-raised-button.mat-warn.mat-button-disabled, .lightTheme .blueTheme .mat-raised-button.mat-button-disabled.mat-button-disabled, .lightTheme .blueTheme .mat-fab.mat-primary.mat-button-disabled, .lightTheme .blueTheme .mat-fab.mat-accent.mat-button-disabled, .lightTheme .blueTheme .mat-fab.mat-warn.mat-button-disabled, .lightTheme .blueTheme .mat-fab.mat-button-disabled.mat-button-disabled, .lightTheme .blueTheme .mat-mini-fab.mat-primary.mat-button-disabled, .lightTheme .blueTheme .mat-mini-fab.mat-accent.mat-button-disabled, .lightTheme .blueTheme .mat-mini-fab.mat-warn.mat-button-disabled, .lightTheme .blueTheme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.lightTheme .blueTheme .mat-flat-button.mat-primary, .lightTheme .blueTheme .mat-raised-button.mat-primary, .lightTheme .blueTheme .mat-fab.mat-primary, .lightTheme .blueTheme .mat-mini-fab.mat-primary {
  background-color: #1e4db7;
}
.lightTheme .blueTheme .mat-flat-button.mat-accent, .lightTheme .blueTheme .mat-raised-button.mat-accent, .lightTheme .blueTheme .mat-fab.mat-accent, .lightTheme .blueTheme .mat-mini-fab.mat-accent {
  background-color: #1a97f5;
}
.lightTheme .blueTheme .mat-flat-button.mat-warn, .lightTheme .blueTheme .mat-raised-button.mat-warn, .lightTheme .blueTheme .mat-fab.mat-warn, .lightTheme .blueTheme .mat-mini-fab.mat-warn {
  background-color: #e91e63;
}
.lightTheme .blueTheme .mat-flat-button.mat-primary.mat-button-disabled, .lightTheme .blueTheme .mat-flat-button.mat-accent.mat-button-disabled, .lightTheme .blueTheme .mat-flat-button.mat-warn.mat-button-disabled, .lightTheme .blueTheme .mat-flat-button.mat-button-disabled.mat-button-disabled, .lightTheme .blueTheme .mat-raised-button.mat-primary.mat-button-disabled, .lightTheme .blueTheme .mat-raised-button.mat-accent.mat-button-disabled, .lightTheme .blueTheme .mat-raised-button.mat-warn.mat-button-disabled, .lightTheme .blueTheme .mat-raised-button.mat-button-disabled.mat-button-disabled, .lightTheme .blueTheme .mat-fab.mat-primary.mat-button-disabled, .lightTheme .blueTheme .mat-fab.mat-accent.mat-button-disabled, .lightTheme .blueTheme .mat-fab.mat-warn.mat-button-disabled, .lightTheme .blueTheme .mat-fab.mat-button-disabled.mat-button-disabled, .lightTheme .blueTheme .mat-mini-fab.mat-primary.mat-button-disabled, .lightTheme .blueTheme .mat-mini-fab.mat-accent.mat-button-disabled, .lightTheme .blueTheme .mat-mini-fab.mat-warn.mat-button-disabled, .lightTheme .blueTheme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-flat-button.mat-primary .mat-ripple-element, .lightTheme .blueTheme .mat-raised-button.mat-primary .mat-ripple-element, .lightTheme .blueTheme .mat-fab.mat-primary .mat-ripple-element, .lightTheme .blueTheme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .blueTheme .mat-flat-button.mat-accent .mat-ripple-element, .lightTheme .blueTheme .mat-raised-button.mat-accent .mat-ripple-element, .lightTheme .blueTheme .mat-fab.mat-accent .mat-ripple-element, .lightTheme .blueTheme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .blueTheme .mat-flat-button.mat-warn .mat-ripple-element, .lightTheme .blueTheme .mat-raised-button.mat-warn .mat-ripple-element, .lightTheme .blueTheme .mat-fab.mat-warn .mat-ripple-element, .lightTheme .blueTheme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .blueTheme .mat-stroked-button:not([class*=mat-elevation-z]), .lightTheme .blueTheme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-fab:not([class*=mat-elevation-z]), .lightTheme .blueTheme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .lightTheme .blueTheme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .lightTheme .blueTheme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.lightTheme .blueTheme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.lightTheme .blueTheme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.lightTheme .blueTheme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .blueTheme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.lightTheme .blueTheme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.lightTheme .blueTheme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.lightTheme .blueTheme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.lightTheme .blueTheme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.lightTheme .blueTheme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .blueTheme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.lightTheme .blueTheme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.lightTheme .blueTheme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.lightTheme .blueTheme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.lightTheme .blueTheme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.lightTheme .blueTheme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .blueTheme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.lightTheme .blueTheme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.lightTheme .blueTheme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.lightTheme .blueTheme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.lightTheme .blueTheme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .lightTheme .blueTheme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #1e4db7;
}
.lightTheme .blueTheme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .lightTheme .blueTheme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #1a97f5;
}
.lightTheme .blueTheme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .lightTheme .blueTheme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e91e63;
}
.lightTheme .blueTheme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .lightTheme .blueTheme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.lightTheme .blueTheme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.lightTheme .blueTheme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .blueTheme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.lightTheme .blueTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.lightTheme .blueTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #1e4db7;
}
.lightTheme .blueTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.lightTheme .blueTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #1a97f5;
}
.lightTheme .blueTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.lightTheme .blueTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e91e63;
}
.lightTheme .blueTheme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.lightTheme .blueTheme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.lightTheme .blueTheme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.lightTheme .blueTheme .mat-chip.mat-standard-chip::after {
  background: black;
}
.lightTheme .blueTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #1e4db7;
  color: white;
}
.lightTheme .blueTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.lightTheme .blueTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .blueTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e91e63;
  color: white;
}
.lightTheme .blueTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.lightTheme .blueTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .blueTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #1a97f5;
  color: white;
}
.lightTheme .blueTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.lightTheme .blueTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .blueTheme .mat-table {
  background: white;
}
.lightTheme .blueTheme .mat-table thead, .lightTheme .blueTheme .mat-table tbody, .lightTheme .blueTheme .mat-table tfoot,
.lightTheme .blueTheme mat-header-row, .lightTheme .blueTheme mat-row, .lightTheme .blueTheme mat-footer-row,
.lightTheme .blueTheme [mat-header-row], .lightTheme .blueTheme [mat-row], .lightTheme .blueTheme [mat-footer-row],
.lightTheme .blueTheme .mat-table-sticky {
  background: inherit;
}
.lightTheme .blueTheme mat-row, .lightTheme .blueTheme mat-header-row, .lightTheme .blueTheme mat-footer-row,
.lightTheme .blueTheme th.mat-header-cell, .lightTheme .blueTheme td.mat-cell, .lightTheme .blueTheme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .blueTheme .mat-cell, .lightTheme .blueTheme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.lightTheme .blueTheme .mat-datepicker-toggle,
.lightTheme .blueTheme .mat-datepicker-content .mat-calendar-next-button,
.lightTheme .blueTheme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .blueTheme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-calendar-table-header,
.lightTheme .blueTheme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .blueTheme .mat-calendar-body-cell-content,
.lightTheme .blueTheme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.lightTheme .blueTheme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .blueTheme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .blueTheme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.lightTheme .blueTheme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .blueTheme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.lightTheme .blueTheme .mat-calendar-body-in-range::before {
  background: rgba(30, 77, 183, 0.2);
}
.lightTheme .blueTheme .mat-calendar-body-comparison-identical,
.lightTheme .blueTheme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.lightTheme .blueTheme .mat-calendar-body-comparison-bridge-start::before,
.lightTheme .blueTheme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(30, 77, 183, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .blueTheme .mat-calendar-body-comparison-bridge-end::before,
.lightTheme .blueTheme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(30, 77, 183, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .blueTheme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.lightTheme .blueTheme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.lightTheme .blueTheme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.lightTheme .blueTheme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.lightTheme .blueTheme .mat-calendar-body-selected {
  background-color: #1e4db7;
  color: white;
}
.lightTheme .blueTheme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(30, 77, 183, 0.4);
}
.lightTheme .blueTheme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.lightTheme .blueTheme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.lightTheme .blueTheme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(30, 77, 183, 0.3);
}
@media (hover: hover) {
  .lightTheme .blueTheme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(30, 77, 183, 0.3);
  }
}
.lightTheme .blueTheme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(26, 151, 245, 0.2);
}
.lightTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.lightTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.lightTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.lightTheme .blueTheme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(26, 151, 245, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.lightTheme .blueTheme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(26, 151, 245, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.lightTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.lightTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.lightTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.lightTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #1a97f5;
  color: white;
}
.lightTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(26, 151, 245, 0.4);
}
.lightTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.lightTheme .blueTheme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.lightTheme .blueTheme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(26, 151, 245, 0.3);
}
@media (hover: hover) {
  .lightTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(26, 151, 245, 0.3);
  }
}
.lightTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(233, 30, 99, 0.2);
}
.lightTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.lightTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.lightTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.lightTheme .blueTheme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.lightTheme .blueTheme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.lightTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.lightTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.lightTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.lightTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e91e63;
  color: white;
}
.lightTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(233, 30, 99, 0.4);
}
.lightTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.lightTheme .blueTheme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.lightTheme .blueTheme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(233, 30, 99, 0.3);
}
@media (hover: hover) {
  .lightTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(233, 30, 99, 0.3);
  }
}
.lightTheme .blueTheme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-datepicker-toggle-active {
  color: #1e4db7;
}
.lightTheme .blueTheme .mat-datepicker-toggle-active.mat-accent {
  color: #1a97f5;
}
.lightTheme .blueTheme .mat-datepicker-toggle-active.mat-warn {
  color: #e91e63;
}
.lightTheme .blueTheme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .blueTheme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .lightTheme .blueTheme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .lightTheme .blueTheme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .lightTheme .blueTheme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.lightTheme .blueTheme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-expansion-panel-header-description,
.lightTheme .blueTheme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .blueTheme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.lightTheme .blueTheme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.lightTheme .blueTheme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.lightTheme .blueTheme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.lightTheme .blueTheme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.lightTheme .blueTheme .mat-form-field.mat-focused .mat-form-field-label {
  color: #1e4db7;
}
.lightTheme .blueTheme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #1a97f5;
}
.lightTheme .blueTheme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e91e63;
}
.lightTheme .blueTheme .mat-focused .mat-form-field-required-marker {
  color: #1a97f5;
}
.lightTheme .blueTheme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #1e4db7;
}
.lightTheme .blueTheme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #1a97f5;
}
.lightTheme .blueTheme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e91e63;
}
.lightTheme .blueTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #1e4db7;
}
.lightTheme .blueTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #1a97f5;
}
.lightTheme .blueTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e91e63;
}
.lightTheme .blueTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e91e63;
}
.lightTheme .blueTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.lightTheme .blueTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e91e63;
}
.lightTheme .blueTheme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.lightTheme .blueTheme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e91e63;
}
.lightTheme .blueTheme .mat-error {
  color: #e91e63;
}
.lightTheme .blueTheme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .blueTheme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .blueTheme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.lightTheme .blueTheme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.lightTheme .blueTheme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.lightTheme .blueTheme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.lightTheme .blueTheme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.lightTheme .blueTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.lightTheme .blueTheme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.lightTheme .blueTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .blueTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.lightTheme .blueTheme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #1e4db7;
}
.lightTheme .blueTheme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #1a97f5;
}
.lightTheme .blueTheme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e91e63;
}
.lightTheme .blueTheme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e91e63;
}
.lightTheme .blueTheme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .blueTheme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.lightTheme .blueTheme .mat-icon.mat-primary {
  color: #1e4db7;
}
.lightTheme .blueTheme .mat-icon.mat-accent {
  color: #1a97f5;
}
.lightTheme .blueTheme .mat-icon.mat-warn {
  color: #e91e63;
}
.lightTheme .blueTheme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .blueTheme .mat-input-element:disabled,
.lightTheme .blueTheme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .blueTheme .mat-input-element {
  caret-color: #1e4db7;
}
.lightTheme .blueTheme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.lightTheme .blueTheme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.lightTheme .blueTheme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.lightTheme .blueTheme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.lightTheme .blueTheme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #1a97f5;
}
.lightTheme .blueTheme .mat-form-field.mat-warn .mat-input-element,
.lightTheme .blueTheme .mat-form-field-invalid .mat-input-element {
  caret-color: #e91e63;
}
.lightTheme .blueTheme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e91e63;
}
.lightTheme .blueTheme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .blueTheme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .blueTheme .mat-list-option:hover, .lightTheme .blueTheme .mat-list-option:focus,
.lightTheme .blueTheme .mat-nav-list .mat-list-item:hover,
.lightTheme .blueTheme .mat-nav-list .mat-list-item:focus,
.lightTheme .blueTheme .mat-action-list .mat-list-item:hover,
.lightTheme .blueTheme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.lightTheme .blueTheme .mat-list-single-selected-option, .lightTheme .blueTheme .mat-list-single-selected-option:hover, .lightTheme .blueTheme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-menu-panel {
  background: white;
}
.lightTheme .blueTheme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-menu-item[disabled],
.lightTheme .blueTheme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.lightTheme .blueTheme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .blueTheme .mat-menu-item .mat-icon-no-color,
.lightTheme .blueTheme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .blueTheme .mat-menu-item:hover:not([disabled]),
.lightTheme .blueTheme .mat-menu-item.cdk-program-focused:not([disabled]),
.lightTheme .blueTheme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.lightTheme .blueTheme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.lightTheme .blueTheme .mat-paginator {
  background: white;
}
.lightTheme .blueTheme .mat-paginator,
.lightTheme .blueTheme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .blueTheme .mat-paginator-decrement,
.lightTheme .blueTheme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.lightTheme .blueTheme .mat-paginator-first,
.lightTheme .blueTheme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.lightTheme .blueTheme .mat-icon-button[disabled] .mat-paginator-decrement,
.lightTheme .blueTheme .mat-icon-button[disabled] .mat-paginator-increment,
.lightTheme .blueTheme .mat-icon-button[disabled] .mat-paginator-first,
.lightTheme .blueTheme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .blueTheme .mat-progress-bar-background {
  fill: #c3cfe9;
}
.lightTheme .blueTheme .mat-progress-bar-buffer {
  background-color: #c3cfe9;
}
.lightTheme .blueTheme .mat-progress-bar-fill::after {
  background-color: #1e4db7;
}
.lightTheme .blueTheme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #c2e1f9;
}
.lightTheme .blueTheme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #c2e1f9;
}
.lightTheme .blueTheme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #1a97f5;
}
.lightTheme .blueTheme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f6c3d4;
}
.lightTheme .blueTheme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f6c3d4;
}
.lightTheme .blueTheme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e91e63;
}
.lightTheme .blueTheme .mat-progress-spinner circle, .lightTheme .blueTheme .mat-spinner circle {
  stroke: #1e4db7;
}
.lightTheme .blueTheme .mat-progress-spinner.mat-accent circle, .lightTheme .blueTheme .mat-spinner.mat-accent circle {
  stroke: #1a97f5;
}
.lightTheme .blueTheme .mat-progress-spinner.mat-warn circle, .lightTheme .blueTheme .mat-spinner.mat-warn circle {
  stroke: #e91e63;
}
.lightTheme .blueTheme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.lightTheme .blueTheme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #1e4db7;
}
.lightTheme .blueTheme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.lightTheme .blueTheme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .lightTheme .blueTheme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .lightTheme .blueTheme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #1e4db7;
}
.lightTheme .blueTheme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #1a97f5;
}
.lightTheme .blueTheme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.lightTheme .blueTheme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .lightTheme .blueTheme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .lightTheme .blueTheme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #1a97f5;
}
.lightTheme .blueTheme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e91e63;
}
.lightTheme .blueTheme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.lightTheme .blueTheme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .lightTheme .blueTheme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .lightTheme .blueTheme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e91e63;
}
.lightTheme .blueTheme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.lightTheme .blueTheme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .blueTheme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.lightTheme .blueTheme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .blueTheme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .blueTheme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.lightTheme .blueTheme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.lightTheme .blueTheme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .blueTheme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .blueTheme .mat-select-panel {
  background: white;
}
.lightTheme .blueTheme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #1e4db7;
}
.lightTheme .blueTheme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #1a97f5;
}
.lightTheme .blueTheme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e91e63;
}
.lightTheme .blueTheme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e91e63;
}
.lightTheme .blueTheme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .blueTheme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.lightTheme .blueTheme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.lightTheme .blueTheme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.lightTheme .blueTheme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.lightTheme .blueTheme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #1a97f5;
}
.lightTheme .blueTheme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(26, 151, 245, 0.54);
}
.lightTheme .blueTheme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #1a97f5;
}
.lightTheme .blueTheme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #1e4db7;
}
.lightTheme .blueTheme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(30, 77, 183, 0.54);
}
.lightTheme .blueTheme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #1e4db7;
}
.lightTheme .blueTheme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e91e63;
}
.lightTheme .blueTheme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(233, 30, 99, 0.54);
}
.lightTheme .blueTheme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e91e63;
}
.lightTheme .blueTheme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.lightTheme .blueTheme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.lightTheme .blueTheme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .blueTheme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.lightTheme .blueTheme .mat-slider.mat-primary .mat-slider-track-fill,
.lightTheme .blueTheme .mat-slider.mat-primary .mat-slider-thumb,
.lightTheme .blueTheme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #1e4db7;
}
.lightTheme .blueTheme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.lightTheme .blueTheme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(30, 77, 183, 0.2);
}
.lightTheme .blueTheme .mat-slider.mat-accent .mat-slider-track-fill,
.lightTheme .blueTheme .mat-slider.mat-accent .mat-slider-thumb,
.lightTheme .blueTheme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #1a97f5;
}
.lightTheme .blueTheme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.lightTheme .blueTheme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(26, 151, 245, 0.2);
}
.lightTheme .blueTheme .mat-slider.mat-warn .mat-slider-track-fill,
.lightTheme .blueTheme .mat-slider.mat-warn .mat-slider-thumb,
.lightTheme .blueTheme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e91e63;
}
.lightTheme .blueTheme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.lightTheme .blueTheme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(233, 30, 99, 0.2);
}
.lightTheme .blueTheme .mat-slider:hover .mat-slider-track-background,
.lightTheme .blueTheme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .blueTheme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.lightTheme .blueTheme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.lightTheme .blueTheme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.lightTheme .blueTheme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.lightTheme .blueTheme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.lightTheme .blueTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.lightTheme .blueTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.lightTheme .blueTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.lightTheme .blueTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .lightTheme .blueTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .blueTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .lightTheme .blueTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.lightTheme .blueTheme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.lightTheme .blueTheme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.lightTheme .blueTheme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.lightTheme .blueTheme .mat-step-header.cdk-keyboard-focused, .lightTheme .blueTheme .mat-step-header.cdk-program-focused, .lightTheme .blueTheme .mat-step-header:hover:not([aria-disabled]), .lightTheme .blueTheme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.lightTheme .blueTheme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .lightTheme .blueTheme .mat-step-header:hover {
    background: none;
  }
}
.lightTheme .blueTheme .mat-step-header .mat-step-label,
.lightTheme .blueTheme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .blueTheme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.lightTheme .blueTheme .mat-step-header .mat-step-icon-selected,
.lightTheme .blueTheme .mat-step-header .mat-step-icon-state-done,
.lightTheme .blueTheme .mat-step-header .mat-step-icon-state-edit {
  background-color: #1e4db7;
  color: white;
}
.lightTheme .blueTheme .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.lightTheme .blueTheme .mat-step-header.mat-accent .mat-step-icon-selected,
.lightTheme .blueTheme .mat-step-header.mat-accent .mat-step-icon-state-done,
.lightTheme .blueTheme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #1a97f5;
  color: white;
}
.lightTheme .blueTheme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.lightTheme .blueTheme .mat-step-header.mat-warn .mat-step-icon-selected,
.lightTheme .blueTheme .mat-step-header.mat-warn .mat-step-icon-state-done,
.lightTheme .blueTheme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e91e63;
  color: white;
}
.lightTheme .blueTheme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e91e63;
}
.lightTheme .blueTheme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e91e63;
}
.lightTheme .blueTheme .mat-stepper-horizontal, .lightTheme .blueTheme .mat-stepper-vertical {
  background-color: white;
}
.lightTheme .blueTheme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-horizontal-stepper-header::before,
.lightTheme .blueTheme .mat-horizontal-stepper-header::after,
.lightTheme .blueTheme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-sort-header-arrow {
  color: #757575;
}
.lightTheme .blueTheme .mat-tab-nav-bar,
.lightTheme .blueTheme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.lightTheme .blueTheme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.lightTheme .blueTheme .mat-tab-label, .lightTheme .blueTheme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-tab-label.mat-tab-disabled, .lightTheme .blueTheme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .blueTheme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .blueTheme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.lightTheme .blueTheme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.lightTheme .blueTheme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .blueTheme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .blueTheme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .blueTheme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .blueTheme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .blueTheme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .blueTheme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .blueTheme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(101, 145, 246, 0.3);
}
.lightTheme .blueTheme .mat-tab-group.mat-primary .mat-ink-bar, .lightTheme .blueTheme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #1e4db7;
}
.lightTheme .blueTheme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .lightTheme .blueTheme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .lightTheme .blueTheme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .lightTheme .blueTheme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.lightTheme .blueTheme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .blueTheme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .blueTheme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .blueTheme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .blueTheme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .blueTheme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .blueTheme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .blueTheme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(26, 151, 245, 0.3);
}
.lightTheme .blueTheme .mat-tab-group.mat-accent .mat-ink-bar, .lightTheme .blueTheme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #1a97f5;
}
.lightTheme .blueTheme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .lightTheme .blueTheme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .lightTheme .blueTheme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .lightTheme .blueTheme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.lightTheme .blueTheme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .blueTheme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .blueTheme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .blueTheme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .blueTheme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .blueTheme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .blueTheme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .blueTheme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.lightTheme .blueTheme .mat-tab-group.mat-warn .mat-ink-bar, .lightTheme .blueTheme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e91e63;
}
.lightTheme .blueTheme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .lightTheme .blueTheme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .lightTheme .blueTheme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .lightTheme .blueTheme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.lightTheme .blueTheme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .blueTheme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .blueTheme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .blueTheme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .blueTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .blueTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(101, 145, 246, 0.3);
}
.lightTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-header, .lightTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .lightTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #1e4db7;
}
.lightTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .lightTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.lightTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.lightTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.lightTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.lightTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.lightTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.lightTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.lightTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.lightTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.lightTheme .blueTheme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .blueTheme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .blueTheme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .blueTheme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .blueTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .blueTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(26, 151, 245, 0.3);
}
.lightTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-header, .lightTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .lightTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #1a97f5;
}
.lightTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .lightTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.lightTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.lightTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.lightTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.lightTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.lightTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.lightTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.lightTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.lightTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.lightTheme .blueTheme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .blueTheme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .blueTheme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .blueTheme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .blueTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .blueTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.lightTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-header, .lightTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .lightTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e91e63;
}
.lightTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .lightTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.lightTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.lightTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.lightTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.lightTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.lightTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.lightTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .lightTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.lightTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.lightTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.lightTheme .blueTheme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-toolbar.mat-primary {
  background: #1e4db7;
  color: white;
}
.lightTheme .blueTheme .mat-toolbar.mat-accent {
  background: #1a97f5;
  color: white;
}
.lightTheme .blueTheme .mat-toolbar.mat-warn {
  background: #e91e63;
  color: white;
}
.lightTheme .blueTheme .mat-toolbar .mat-form-field-underline,
.lightTheme .blueTheme .mat-toolbar .mat-form-field-ripple,
.lightTheme .blueTheme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.lightTheme .blueTheme .mat-toolbar .mat-form-field-label,
.lightTheme .blueTheme .mat-toolbar .mat-focused .mat-form-field-label,
.lightTheme .blueTheme .mat-toolbar .mat-select-value,
.lightTheme .blueTheme .mat-toolbar .mat-select-arrow,
.lightTheme .blueTheme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.lightTheme .blueTheme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.lightTheme .blueTheme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.lightTheme .blueTheme .mat-tree {
  background: white;
}
.lightTheme .blueTheme .mat-tree-node,
.lightTheme .blueTheme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .blueTheme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .blueTheme .mat-simple-snackbar-action {
  color: #1a97f5;
}
.lightTheme .greenTheme .leftsidebar .menu-list-item.activeMenu .mat-list-item-content, .lightTheme .greenTheme .leftsidebar .menu-list-item.activeMenu:hover .mat-list-item-content {
  background-color: #00cec3;
}
.lightTheme .greenTheme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.lightTheme .greenTheme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-option:hover:not(.mat-option-disabled), .lightTheme .greenTheme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.lightTheme .greenTheme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.lightTheme .greenTheme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .greenTheme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #00cec3;
}
.lightTheme .greenTheme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #066a73;
}
.lightTheme .greenTheme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e91e63;
}
.lightTheme .greenTheme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .greenTheme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .greenTheme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .greenTheme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.lightTheme .greenTheme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.lightTheme .greenTheme .mat-primary .mat-pseudo-checkbox-checked,
.lightTheme .greenTheme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #00cec3;
}
.lightTheme .greenTheme .mat-pseudo-checkbox-checked,
.lightTheme .greenTheme .mat-pseudo-checkbox-indeterminate,
.lightTheme .greenTheme .mat-accent .mat-pseudo-checkbox-checked,
.lightTheme .greenTheme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #066a73;
}
.lightTheme .greenTheme .mat-warn .mat-pseudo-checkbox-checked,
.lightTheme .greenTheme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e91e63;
}
.lightTheme .greenTheme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.lightTheme .greenTheme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.lightTheme .greenTheme .mat-app-background, .lightTheme .greenTheme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.lightTheme .greenTheme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.lightTheme .greenTheme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-badge-content {
  color: white;
  background: #00cec3;
}
.cdk-high-contrast-active .lightTheme .greenTheme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.lightTheme .greenTheme .mat-badge-accent .mat-badge-content {
  background: #066a73;
  color: white;
}
.lightTheme .greenTheme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e91e63;
}
.lightTheme .greenTheme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .greenTheme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-button, .lightTheme .greenTheme .mat-icon-button, .lightTheme .greenTheme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.lightTheme .greenTheme .mat-button.mat-primary, .lightTheme .greenTheme .mat-icon-button.mat-primary, .lightTheme .greenTheme .mat-stroked-button.mat-primary {
  color: #00cec3;
}
.lightTheme .greenTheme .mat-button.mat-accent, .lightTheme .greenTheme .mat-icon-button.mat-accent, .lightTheme .greenTheme .mat-stroked-button.mat-accent {
  color: #066a73;
}
.lightTheme .greenTheme .mat-button.mat-warn, .lightTheme .greenTheme .mat-icon-button.mat-warn, .lightTheme .greenTheme .mat-stroked-button.mat-warn {
  color: #e91e63;
}
.lightTheme .greenTheme .mat-button.mat-primary.mat-button-disabled, .lightTheme .greenTheme .mat-button.mat-accent.mat-button-disabled, .lightTheme .greenTheme .mat-button.mat-warn.mat-button-disabled, .lightTheme .greenTheme .mat-button.mat-button-disabled.mat-button-disabled, .lightTheme .greenTheme .mat-icon-button.mat-primary.mat-button-disabled, .lightTheme .greenTheme .mat-icon-button.mat-accent.mat-button-disabled, .lightTheme .greenTheme .mat-icon-button.mat-warn.mat-button-disabled, .lightTheme .greenTheme .mat-icon-button.mat-button-disabled.mat-button-disabled, .lightTheme .greenTheme .mat-stroked-button.mat-primary.mat-button-disabled, .lightTheme .greenTheme .mat-stroked-button.mat-accent.mat-button-disabled, .lightTheme .greenTheme .mat-stroked-button.mat-warn.mat-button-disabled, .lightTheme .greenTheme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.lightTheme .greenTheme .mat-button.mat-primary .mat-button-focus-overlay, .lightTheme .greenTheme .mat-icon-button.mat-primary .mat-button-focus-overlay, .lightTheme .greenTheme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #00cec3;
}
.lightTheme .greenTheme .mat-button.mat-accent .mat-button-focus-overlay, .lightTheme .greenTheme .mat-icon-button.mat-accent .mat-button-focus-overlay, .lightTheme .greenTheme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #066a73;
}
.lightTheme .greenTheme .mat-button.mat-warn .mat-button-focus-overlay, .lightTheme .greenTheme .mat-icon-button.mat-warn .mat-button-focus-overlay, .lightTheme .greenTheme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e91e63;
}
.lightTheme .greenTheme .mat-button.mat-button-disabled .mat-button-focus-overlay, .lightTheme .greenTheme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .lightTheme .greenTheme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.lightTheme .greenTheme .mat-button .mat-ripple-element, .lightTheme .greenTheme .mat-icon-button .mat-ripple-element, .lightTheme .greenTheme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.lightTheme .greenTheme .mat-button-focus-overlay {
  background: black;
}
.lightTheme .greenTheme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-flat-button, .lightTheme .greenTheme .mat-raised-button, .lightTheme .greenTheme .mat-fab, .lightTheme .greenTheme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.lightTheme .greenTheme .mat-flat-button.mat-primary, .lightTheme .greenTheme .mat-raised-button.mat-primary, .lightTheme .greenTheme .mat-fab.mat-primary, .lightTheme .greenTheme .mat-mini-fab.mat-primary {
  color: white;
}
.lightTheme .greenTheme .mat-flat-button.mat-accent, .lightTheme .greenTheme .mat-raised-button.mat-accent, .lightTheme .greenTheme .mat-fab.mat-accent, .lightTheme .greenTheme .mat-mini-fab.mat-accent {
  color: white;
}
.lightTheme .greenTheme .mat-flat-button.mat-warn, .lightTheme .greenTheme .mat-raised-button.mat-warn, .lightTheme .greenTheme .mat-fab.mat-warn, .lightTheme .greenTheme .mat-mini-fab.mat-warn {
  color: white;
}
.lightTheme .greenTheme .mat-flat-button.mat-primary.mat-button-disabled, .lightTheme .greenTheme .mat-flat-button.mat-accent.mat-button-disabled, .lightTheme .greenTheme .mat-flat-button.mat-warn.mat-button-disabled, .lightTheme .greenTheme .mat-flat-button.mat-button-disabled.mat-button-disabled, .lightTheme .greenTheme .mat-raised-button.mat-primary.mat-button-disabled, .lightTheme .greenTheme .mat-raised-button.mat-accent.mat-button-disabled, .lightTheme .greenTheme .mat-raised-button.mat-warn.mat-button-disabled, .lightTheme .greenTheme .mat-raised-button.mat-button-disabled.mat-button-disabled, .lightTheme .greenTheme .mat-fab.mat-primary.mat-button-disabled, .lightTheme .greenTheme .mat-fab.mat-accent.mat-button-disabled, .lightTheme .greenTheme .mat-fab.mat-warn.mat-button-disabled, .lightTheme .greenTheme .mat-fab.mat-button-disabled.mat-button-disabled, .lightTheme .greenTheme .mat-mini-fab.mat-primary.mat-button-disabled, .lightTheme .greenTheme .mat-mini-fab.mat-accent.mat-button-disabled, .lightTheme .greenTheme .mat-mini-fab.mat-warn.mat-button-disabled, .lightTheme .greenTheme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.lightTheme .greenTheme .mat-flat-button.mat-primary, .lightTheme .greenTheme .mat-raised-button.mat-primary, .lightTheme .greenTheme .mat-fab.mat-primary, .lightTheme .greenTheme .mat-mini-fab.mat-primary {
  background-color: #00cec3;
}
.lightTheme .greenTheme .mat-flat-button.mat-accent, .lightTheme .greenTheme .mat-raised-button.mat-accent, .lightTheme .greenTheme .mat-fab.mat-accent, .lightTheme .greenTheme .mat-mini-fab.mat-accent {
  background-color: #066a73;
}
.lightTheme .greenTheme .mat-flat-button.mat-warn, .lightTheme .greenTheme .mat-raised-button.mat-warn, .lightTheme .greenTheme .mat-fab.mat-warn, .lightTheme .greenTheme .mat-mini-fab.mat-warn {
  background-color: #e91e63;
}
.lightTheme .greenTheme .mat-flat-button.mat-primary.mat-button-disabled, .lightTheme .greenTheme .mat-flat-button.mat-accent.mat-button-disabled, .lightTheme .greenTheme .mat-flat-button.mat-warn.mat-button-disabled, .lightTheme .greenTheme .mat-flat-button.mat-button-disabled.mat-button-disabled, .lightTheme .greenTheme .mat-raised-button.mat-primary.mat-button-disabled, .lightTheme .greenTheme .mat-raised-button.mat-accent.mat-button-disabled, .lightTheme .greenTheme .mat-raised-button.mat-warn.mat-button-disabled, .lightTheme .greenTheme .mat-raised-button.mat-button-disabled.mat-button-disabled, .lightTheme .greenTheme .mat-fab.mat-primary.mat-button-disabled, .lightTheme .greenTheme .mat-fab.mat-accent.mat-button-disabled, .lightTheme .greenTheme .mat-fab.mat-warn.mat-button-disabled, .lightTheme .greenTheme .mat-fab.mat-button-disabled.mat-button-disabled, .lightTheme .greenTheme .mat-mini-fab.mat-primary.mat-button-disabled, .lightTheme .greenTheme .mat-mini-fab.mat-accent.mat-button-disabled, .lightTheme .greenTheme .mat-mini-fab.mat-warn.mat-button-disabled, .lightTheme .greenTheme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-flat-button.mat-primary .mat-ripple-element, .lightTheme .greenTheme .mat-raised-button.mat-primary .mat-ripple-element, .lightTheme .greenTheme .mat-fab.mat-primary .mat-ripple-element, .lightTheme .greenTheme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .greenTheme .mat-flat-button.mat-accent .mat-ripple-element, .lightTheme .greenTheme .mat-raised-button.mat-accent .mat-ripple-element, .lightTheme .greenTheme .mat-fab.mat-accent .mat-ripple-element, .lightTheme .greenTheme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .greenTheme .mat-flat-button.mat-warn .mat-ripple-element, .lightTheme .greenTheme .mat-raised-button.mat-warn .mat-ripple-element, .lightTheme .greenTheme .mat-fab.mat-warn .mat-ripple-element, .lightTheme .greenTheme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .greenTheme .mat-stroked-button:not([class*=mat-elevation-z]), .lightTheme .greenTheme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-fab:not([class*=mat-elevation-z]), .lightTheme .greenTheme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .lightTheme .greenTheme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .lightTheme .greenTheme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.lightTheme .greenTheme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.lightTheme .greenTheme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.lightTheme .greenTheme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .greenTheme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.lightTheme .greenTheme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.lightTheme .greenTheme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.lightTheme .greenTheme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.lightTheme .greenTheme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.lightTheme .greenTheme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .greenTheme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.lightTheme .greenTheme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.lightTheme .greenTheme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.lightTheme .greenTheme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.lightTheme .greenTheme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.lightTheme .greenTheme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .greenTheme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.lightTheme .greenTheme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.lightTheme .greenTheme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.lightTheme .greenTheme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.lightTheme .greenTheme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .lightTheme .greenTheme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #00cec3;
}
.lightTheme .greenTheme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .lightTheme .greenTheme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #066a73;
}
.lightTheme .greenTheme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .lightTheme .greenTheme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e91e63;
}
.lightTheme .greenTheme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .lightTheme .greenTheme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.lightTheme .greenTheme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.lightTheme .greenTheme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .greenTheme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.lightTheme .greenTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.lightTheme .greenTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #00cec3;
}
.lightTheme .greenTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.lightTheme .greenTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #066a73;
}
.lightTheme .greenTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.lightTheme .greenTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e91e63;
}
.lightTheme .greenTheme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.lightTheme .greenTheme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.lightTheme .greenTheme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.lightTheme .greenTheme .mat-chip.mat-standard-chip::after {
  background: black;
}
.lightTheme .greenTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #00cec3;
  color: white;
}
.lightTheme .greenTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.lightTheme .greenTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .greenTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e91e63;
  color: white;
}
.lightTheme .greenTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.lightTheme .greenTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .greenTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #066a73;
  color: white;
}
.lightTheme .greenTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.lightTheme .greenTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .greenTheme .mat-table {
  background: white;
}
.lightTheme .greenTheme .mat-table thead, .lightTheme .greenTheme .mat-table tbody, .lightTheme .greenTheme .mat-table tfoot,
.lightTheme .greenTheme mat-header-row, .lightTheme .greenTheme mat-row, .lightTheme .greenTheme mat-footer-row,
.lightTheme .greenTheme [mat-header-row], .lightTheme .greenTheme [mat-row], .lightTheme .greenTheme [mat-footer-row],
.lightTheme .greenTheme .mat-table-sticky {
  background: inherit;
}
.lightTheme .greenTheme mat-row, .lightTheme .greenTheme mat-header-row, .lightTheme .greenTheme mat-footer-row,
.lightTheme .greenTheme th.mat-header-cell, .lightTheme .greenTheme td.mat-cell, .lightTheme .greenTheme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .greenTheme .mat-cell, .lightTheme .greenTheme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.lightTheme .greenTheme .mat-datepicker-toggle,
.lightTheme .greenTheme .mat-datepicker-content .mat-calendar-next-button,
.lightTheme .greenTheme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .greenTheme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-calendar-table-header,
.lightTheme .greenTheme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .greenTheme .mat-calendar-body-cell-content,
.lightTheme .greenTheme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.lightTheme .greenTheme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .greenTheme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .greenTheme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.lightTheme .greenTheme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .greenTheme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.lightTheme .greenTheme .mat-calendar-body-in-range::before {
  background: rgba(0, 206, 195, 0.2);
}
.lightTheme .greenTheme .mat-calendar-body-comparison-identical,
.lightTheme .greenTheme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.lightTheme .greenTheme .mat-calendar-body-comparison-bridge-start::before,
.lightTheme .greenTheme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 206, 195, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .greenTheme .mat-calendar-body-comparison-bridge-end::before,
.lightTheme .greenTheme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 206, 195, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .greenTheme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.lightTheme .greenTheme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.lightTheme .greenTheme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.lightTheme .greenTheme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.lightTheme .greenTheme .mat-calendar-body-selected {
  background-color: #00cec3;
  color: white;
}
.lightTheme .greenTheme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 206, 195, 0.4);
}
.lightTheme .greenTheme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.lightTheme .greenTheme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.lightTheme .greenTheme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 206, 195, 0.3);
}
@media (hover: hover) {
  .lightTheme .greenTheme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 206, 195, 0.3);
  }
}
.lightTheme .greenTheme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(6, 106, 115, 0.2);
}
.lightTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.lightTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.lightTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.lightTheme .greenTheme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(6, 106, 115, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.lightTheme .greenTheme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(6, 106, 115, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.lightTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.lightTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.lightTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.lightTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #066a73;
  color: white;
}
.lightTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(6, 106, 115, 0.4);
}
.lightTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.lightTheme .greenTheme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.lightTheme .greenTheme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(6, 106, 115, 0.3);
}
@media (hover: hover) {
  .lightTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(6, 106, 115, 0.3);
  }
}
.lightTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(233, 30, 99, 0.2);
}
.lightTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.lightTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.lightTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.lightTheme .greenTheme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.lightTheme .greenTheme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.lightTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.lightTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.lightTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.lightTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e91e63;
  color: white;
}
.lightTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(233, 30, 99, 0.4);
}
.lightTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.lightTheme .greenTheme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.lightTheme .greenTheme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(233, 30, 99, 0.3);
}
@media (hover: hover) {
  .lightTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(233, 30, 99, 0.3);
  }
}
.lightTheme .greenTheme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-datepicker-toggle-active {
  color: #00cec3;
}
.lightTheme .greenTheme .mat-datepicker-toggle-active.mat-accent {
  color: #066a73;
}
.lightTheme .greenTheme .mat-datepicker-toggle-active.mat-warn {
  color: #e91e63;
}
.lightTheme .greenTheme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .greenTheme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .lightTheme .greenTheme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .lightTheme .greenTheme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .lightTheme .greenTheme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.lightTheme .greenTheme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-expansion-panel-header-description,
.lightTheme .greenTheme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .greenTheme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.lightTheme .greenTheme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.lightTheme .greenTheme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.lightTheme .greenTheme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.lightTheme .greenTheme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.lightTheme .greenTheme .mat-form-field.mat-focused .mat-form-field-label {
  color: #00cec3;
}
.lightTheme .greenTheme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #066a73;
}
.lightTheme .greenTheme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e91e63;
}
.lightTheme .greenTheme .mat-focused .mat-form-field-required-marker {
  color: #066a73;
}
.lightTheme .greenTheme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #00cec3;
}
.lightTheme .greenTheme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #066a73;
}
.lightTheme .greenTheme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e91e63;
}
.lightTheme .greenTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #00cec3;
}
.lightTheme .greenTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #066a73;
}
.lightTheme .greenTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e91e63;
}
.lightTheme .greenTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e91e63;
}
.lightTheme .greenTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.lightTheme .greenTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e91e63;
}
.lightTheme .greenTheme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.lightTheme .greenTheme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e91e63;
}
.lightTheme .greenTheme .mat-error {
  color: #e91e63;
}
.lightTheme .greenTheme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .greenTheme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .greenTheme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.lightTheme .greenTheme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.lightTheme .greenTheme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.lightTheme .greenTheme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.lightTheme .greenTheme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.lightTheme .greenTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.lightTheme .greenTheme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.lightTheme .greenTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .greenTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.lightTheme .greenTheme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #00cec3;
}
.lightTheme .greenTheme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #066a73;
}
.lightTheme .greenTheme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e91e63;
}
.lightTheme .greenTheme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e91e63;
}
.lightTheme .greenTheme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .greenTheme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.lightTheme .greenTheme .mat-icon.mat-primary {
  color: #00cec3;
}
.lightTheme .greenTheme .mat-icon.mat-accent {
  color: #066a73;
}
.lightTheme .greenTheme .mat-icon.mat-warn {
  color: #e91e63;
}
.lightTheme .greenTheme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .greenTheme .mat-input-element:disabled,
.lightTheme .greenTheme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .greenTheme .mat-input-element {
  caret-color: #00cec3;
}
.lightTheme .greenTheme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.lightTheme .greenTheme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.lightTheme .greenTheme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.lightTheme .greenTheme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.lightTheme .greenTheme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #066a73;
}
.lightTheme .greenTheme .mat-form-field.mat-warn .mat-input-element,
.lightTheme .greenTheme .mat-form-field-invalid .mat-input-element {
  caret-color: #e91e63;
}
.lightTheme .greenTheme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e91e63;
}
.lightTheme .greenTheme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .greenTheme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .greenTheme .mat-list-option:hover, .lightTheme .greenTheme .mat-list-option:focus,
.lightTheme .greenTheme .mat-nav-list .mat-list-item:hover,
.lightTheme .greenTheme .mat-nav-list .mat-list-item:focus,
.lightTheme .greenTheme .mat-action-list .mat-list-item:hover,
.lightTheme .greenTheme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.lightTheme .greenTheme .mat-list-single-selected-option, .lightTheme .greenTheme .mat-list-single-selected-option:hover, .lightTheme .greenTheme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-menu-panel {
  background: white;
}
.lightTheme .greenTheme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-menu-item[disabled],
.lightTheme .greenTheme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.lightTheme .greenTheme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .greenTheme .mat-menu-item .mat-icon-no-color,
.lightTheme .greenTheme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .greenTheme .mat-menu-item:hover:not([disabled]),
.lightTheme .greenTheme .mat-menu-item.cdk-program-focused:not([disabled]),
.lightTheme .greenTheme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.lightTheme .greenTheme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.lightTheme .greenTheme .mat-paginator {
  background: white;
}
.lightTheme .greenTheme .mat-paginator,
.lightTheme .greenTheme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .greenTheme .mat-paginator-decrement,
.lightTheme .greenTheme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.lightTheme .greenTheme .mat-paginator-first,
.lightTheme .greenTheme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.lightTheme .greenTheme .mat-icon-button[disabled] .mat-paginator-decrement,
.lightTheme .greenTheme .mat-icon-button[disabled] .mat-paginator-increment,
.lightTheme .greenTheme .mat-icon-button[disabled] .mat-paginator-first,
.lightTheme .greenTheme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .greenTheme .mat-progress-bar-background {
  fill: #bcefec;
}
.lightTheme .greenTheme .mat-progress-bar-buffer {
  background-color: #bcefec;
}
.lightTheme .greenTheme .mat-progress-bar-fill::after {
  background-color: #00cec3;
}
.lightTheme .greenTheme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #bdd6d8;
}
.lightTheme .greenTheme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #bdd6d8;
}
.lightTheme .greenTheme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #066a73;
}
.lightTheme .greenTheme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f6c3d4;
}
.lightTheme .greenTheme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f6c3d4;
}
.lightTheme .greenTheme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e91e63;
}
.lightTheme .greenTheme .mat-progress-spinner circle, .lightTheme .greenTheme .mat-spinner circle {
  stroke: #00cec3;
}
.lightTheme .greenTheme .mat-progress-spinner.mat-accent circle, .lightTheme .greenTheme .mat-spinner.mat-accent circle {
  stroke: #066a73;
}
.lightTheme .greenTheme .mat-progress-spinner.mat-warn circle, .lightTheme .greenTheme .mat-spinner.mat-warn circle {
  stroke: #e91e63;
}
.lightTheme .greenTheme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.lightTheme .greenTheme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #00cec3;
}
.lightTheme .greenTheme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.lightTheme .greenTheme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .lightTheme .greenTheme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .lightTheme .greenTheme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #00cec3;
}
.lightTheme .greenTheme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #066a73;
}
.lightTheme .greenTheme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.lightTheme .greenTheme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .lightTheme .greenTheme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .lightTheme .greenTheme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #066a73;
}
.lightTheme .greenTheme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e91e63;
}
.lightTheme .greenTheme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.lightTheme .greenTheme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .lightTheme .greenTheme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .lightTheme .greenTheme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e91e63;
}
.lightTheme .greenTheme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.lightTheme .greenTheme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .greenTheme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.lightTheme .greenTheme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .greenTheme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .greenTheme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.lightTheme .greenTheme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.lightTheme .greenTheme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .greenTheme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .greenTheme .mat-select-panel {
  background: white;
}
.lightTheme .greenTheme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #00cec3;
}
.lightTheme .greenTheme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #066a73;
}
.lightTheme .greenTheme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e91e63;
}
.lightTheme .greenTheme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e91e63;
}
.lightTheme .greenTheme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .greenTheme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.lightTheme .greenTheme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.lightTheme .greenTheme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.lightTheme .greenTheme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.lightTheme .greenTheme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #066a73;
}
.lightTheme .greenTheme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(6, 106, 115, 0.54);
}
.lightTheme .greenTheme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #066a73;
}
.lightTheme .greenTheme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #00cec3;
}
.lightTheme .greenTheme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 206, 195, 0.54);
}
.lightTheme .greenTheme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #00cec3;
}
.lightTheme .greenTheme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e91e63;
}
.lightTheme .greenTheme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(233, 30, 99, 0.54);
}
.lightTheme .greenTheme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e91e63;
}
.lightTheme .greenTheme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.lightTheme .greenTheme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.lightTheme .greenTheme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .greenTheme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.lightTheme .greenTheme .mat-slider.mat-primary .mat-slider-track-fill,
.lightTheme .greenTheme .mat-slider.mat-primary .mat-slider-thumb,
.lightTheme .greenTheme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #00cec3;
}
.lightTheme .greenTheme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.lightTheme .greenTheme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 206, 195, 0.2);
}
.lightTheme .greenTheme .mat-slider.mat-accent .mat-slider-track-fill,
.lightTheme .greenTheme .mat-slider.mat-accent .mat-slider-thumb,
.lightTheme .greenTheme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #066a73;
}
.lightTheme .greenTheme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.lightTheme .greenTheme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(6, 106, 115, 0.2);
}
.lightTheme .greenTheme .mat-slider.mat-warn .mat-slider-track-fill,
.lightTheme .greenTheme .mat-slider.mat-warn .mat-slider-thumb,
.lightTheme .greenTheme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e91e63;
}
.lightTheme .greenTheme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.lightTheme .greenTheme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(233, 30, 99, 0.2);
}
.lightTheme .greenTheme .mat-slider:hover .mat-slider-track-background,
.lightTheme .greenTheme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .greenTheme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.lightTheme .greenTheme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.lightTheme .greenTheme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.lightTheme .greenTheme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.lightTheme .greenTheme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.lightTheme .greenTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.lightTheme .greenTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.lightTheme .greenTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.lightTheme .greenTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .lightTheme .greenTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .greenTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .lightTheme .greenTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.lightTheme .greenTheme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.lightTheme .greenTheme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.lightTheme .greenTheme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.lightTheme .greenTheme .mat-step-header.cdk-keyboard-focused, .lightTheme .greenTheme .mat-step-header.cdk-program-focused, .lightTheme .greenTheme .mat-step-header:hover:not([aria-disabled]), .lightTheme .greenTheme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.lightTheme .greenTheme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .lightTheme .greenTheme .mat-step-header:hover {
    background: none;
  }
}
.lightTheme .greenTheme .mat-step-header .mat-step-label,
.lightTheme .greenTheme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .greenTheme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.lightTheme .greenTheme .mat-step-header .mat-step-icon-selected,
.lightTheme .greenTheme .mat-step-header .mat-step-icon-state-done,
.lightTheme .greenTheme .mat-step-header .mat-step-icon-state-edit {
  background-color: #00cec3;
  color: white;
}
.lightTheme .greenTheme .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.lightTheme .greenTheme .mat-step-header.mat-accent .mat-step-icon-selected,
.lightTheme .greenTheme .mat-step-header.mat-accent .mat-step-icon-state-done,
.lightTheme .greenTheme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #066a73;
  color: white;
}
.lightTheme .greenTheme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.lightTheme .greenTheme .mat-step-header.mat-warn .mat-step-icon-selected,
.lightTheme .greenTheme .mat-step-header.mat-warn .mat-step-icon-state-done,
.lightTheme .greenTheme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e91e63;
  color: white;
}
.lightTheme .greenTheme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e91e63;
}
.lightTheme .greenTheme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e91e63;
}
.lightTheme .greenTheme .mat-stepper-horizontal, .lightTheme .greenTheme .mat-stepper-vertical {
  background-color: white;
}
.lightTheme .greenTheme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-horizontal-stepper-header::before,
.lightTheme .greenTheme .mat-horizontal-stepper-header::after,
.lightTheme .greenTheme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-sort-header-arrow {
  color: #757575;
}
.lightTheme .greenTheme .mat-tab-nav-bar,
.lightTheme .greenTheme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.lightTheme .greenTheme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.lightTheme .greenTheme .mat-tab-label, .lightTheme .greenTheme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-tab-label.mat-tab-disabled, .lightTheme .greenTheme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .greenTheme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .greenTheme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.lightTheme .greenTheme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.lightTheme .greenTheme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .greenTheme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .greenTheme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .greenTheme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .greenTheme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .greenTheme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .greenTheme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .greenTheme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 206, 195, 0.3);
}
.lightTheme .greenTheme .mat-tab-group.mat-primary .mat-ink-bar, .lightTheme .greenTheme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #00cec3;
}
.lightTheme .greenTheme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .lightTheme .greenTheme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .lightTheme .greenTheme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .lightTheme .greenTheme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.lightTheme .greenTheme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .greenTheme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .greenTheme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .greenTheme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .greenTheme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .greenTheme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .greenTheme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .greenTheme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(6, 106, 115, 0.3);
}
.lightTheme .greenTheme .mat-tab-group.mat-accent .mat-ink-bar, .lightTheme .greenTheme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #066a73;
}
.lightTheme .greenTheme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .lightTheme .greenTheme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .lightTheme .greenTheme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .lightTheme .greenTheme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.lightTheme .greenTheme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .greenTheme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .greenTheme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .greenTheme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .greenTheme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .greenTheme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .greenTheme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .greenTheme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.lightTheme .greenTheme .mat-tab-group.mat-warn .mat-ink-bar, .lightTheme .greenTheme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e91e63;
}
.lightTheme .greenTheme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .lightTheme .greenTheme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .lightTheme .greenTheme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .lightTheme .greenTheme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.lightTheme .greenTheme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .greenTheme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .greenTheme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .greenTheme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .greenTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .greenTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 206, 195, 0.3);
}
.lightTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-header, .lightTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .lightTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #00cec3;
}
.lightTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .lightTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.lightTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.lightTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.lightTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.lightTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.lightTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.lightTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.lightTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.lightTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.lightTheme .greenTheme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .greenTheme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .greenTheme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .greenTheme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .greenTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .greenTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(6, 106, 115, 0.3);
}
.lightTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-header, .lightTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .lightTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #066a73;
}
.lightTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .lightTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.lightTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.lightTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.lightTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.lightTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.lightTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.lightTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.lightTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.lightTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.lightTheme .greenTheme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .greenTheme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .greenTheme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .greenTheme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .greenTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .greenTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.lightTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-header, .lightTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .lightTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e91e63;
}
.lightTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .lightTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.lightTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.lightTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.lightTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.lightTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.lightTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.lightTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .lightTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.lightTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.lightTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.lightTheme .greenTheme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-toolbar.mat-primary {
  background: #00cec3;
  color: white;
}
.lightTheme .greenTheme .mat-toolbar.mat-accent {
  background: #066a73;
  color: white;
}
.lightTheme .greenTheme .mat-toolbar.mat-warn {
  background: #e91e63;
  color: white;
}
.lightTheme .greenTheme .mat-toolbar .mat-form-field-underline,
.lightTheme .greenTheme .mat-toolbar .mat-form-field-ripple,
.lightTheme .greenTheme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.lightTheme .greenTheme .mat-toolbar .mat-form-field-label,
.lightTheme .greenTheme .mat-toolbar .mat-focused .mat-form-field-label,
.lightTheme .greenTheme .mat-toolbar .mat-select-value,
.lightTheme .greenTheme .mat-toolbar .mat-select-arrow,
.lightTheme .greenTheme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.lightTheme .greenTheme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.lightTheme .greenTheme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.lightTheme .greenTheme .mat-tree {
  background: white;
}
.lightTheme .greenTheme .mat-tree-node,
.lightTheme .greenTheme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .greenTheme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .greenTheme .mat-simple-snackbar-action {
  color: #066a73;
}
.lightTheme .purpleTheme .leftsidebar .menu-list-item.activeMenu .mat-list-item-content, .lightTheme .purpleTheme .leftsidebar .menu-list-item.activeMenu:hover .mat-list-item-content {
  background-color: #7352ff;
}
.lightTheme .purpleTheme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.lightTheme .purpleTheme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-option:hover:not(.mat-option-disabled), .lightTheme .purpleTheme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.lightTheme .purpleTheme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.lightTheme .purpleTheme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .purpleTheme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #7352ff;
}
.lightTheme .purpleTheme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #402e8d;
}
.lightTheme .purpleTheme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e91e63;
}
.lightTheme .purpleTheme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .purpleTheme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .purpleTheme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .purpleTheme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.lightTheme .purpleTheme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.lightTheme .purpleTheme .mat-primary .mat-pseudo-checkbox-checked,
.lightTheme .purpleTheme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #7352ff;
}
.lightTheme .purpleTheme .mat-pseudo-checkbox-checked,
.lightTheme .purpleTheme .mat-pseudo-checkbox-indeterminate,
.lightTheme .purpleTheme .mat-accent .mat-pseudo-checkbox-checked,
.lightTheme .purpleTheme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #402e8d;
}
.lightTheme .purpleTheme .mat-warn .mat-pseudo-checkbox-checked,
.lightTheme .purpleTheme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e91e63;
}
.lightTheme .purpleTheme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.lightTheme .purpleTheme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.lightTheme .purpleTheme .mat-app-background, .lightTheme .purpleTheme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.lightTheme .purpleTheme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.lightTheme .purpleTheme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-badge-content {
  color: white;
  background: #7352ff;
}
.cdk-high-contrast-active .lightTheme .purpleTheme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.lightTheme .purpleTheme .mat-badge-accent .mat-badge-content {
  background: #402e8d;
  color: white;
}
.lightTheme .purpleTheme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e91e63;
}
.lightTheme .purpleTheme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .purpleTheme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-button, .lightTheme .purpleTheme .mat-icon-button, .lightTheme .purpleTheme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.lightTheme .purpleTheme .mat-button.mat-primary, .lightTheme .purpleTheme .mat-icon-button.mat-primary, .lightTheme .purpleTheme .mat-stroked-button.mat-primary {
  color: #7352ff;
}
.lightTheme .purpleTheme .mat-button.mat-accent, .lightTheme .purpleTheme .mat-icon-button.mat-accent, .lightTheme .purpleTheme .mat-stroked-button.mat-accent {
  color: #402e8d;
}
.lightTheme .purpleTheme .mat-button.mat-warn, .lightTheme .purpleTheme .mat-icon-button.mat-warn, .lightTheme .purpleTheme .mat-stroked-button.mat-warn {
  color: #e91e63;
}
.lightTheme .purpleTheme .mat-button.mat-primary.mat-button-disabled, .lightTheme .purpleTheme .mat-button.mat-accent.mat-button-disabled, .lightTheme .purpleTheme .mat-button.mat-warn.mat-button-disabled, .lightTheme .purpleTheme .mat-button.mat-button-disabled.mat-button-disabled, .lightTheme .purpleTheme .mat-icon-button.mat-primary.mat-button-disabled, .lightTheme .purpleTheme .mat-icon-button.mat-accent.mat-button-disabled, .lightTheme .purpleTheme .mat-icon-button.mat-warn.mat-button-disabled, .lightTheme .purpleTheme .mat-icon-button.mat-button-disabled.mat-button-disabled, .lightTheme .purpleTheme .mat-stroked-button.mat-primary.mat-button-disabled, .lightTheme .purpleTheme .mat-stroked-button.mat-accent.mat-button-disabled, .lightTheme .purpleTheme .mat-stroked-button.mat-warn.mat-button-disabled, .lightTheme .purpleTheme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.lightTheme .purpleTheme .mat-button.mat-primary .mat-button-focus-overlay, .lightTheme .purpleTheme .mat-icon-button.mat-primary .mat-button-focus-overlay, .lightTheme .purpleTheme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #7352ff;
}
.lightTheme .purpleTheme .mat-button.mat-accent .mat-button-focus-overlay, .lightTheme .purpleTheme .mat-icon-button.mat-accent .mat-button-focus-overlay, .lightTheme .purpleTheme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #402e8d;
}
.lightTheme .purpleTheme .mat-button.mat-warn .mat-button-focus-overlay, .lightTheme .purpleTheme .mat-icon-button.mat-warn .mat-button-focus-overlay, .lightTheme .purpleTheme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e91e63;
}
.lightTheme .purpleTheme .mat-button.mat-button-disabled .mat-button-focus-overlay, .lightTheme .purpleTheme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .lightTheme .purpleTheme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.lightTheme .purpleTheme .mat-button .mat-ripple-element, .lightTheme .purpleTheme .mat-icon-button .mat-ripple-element, .lightTheme .purpleTheme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.lightTheme .purpleTheme .mat-button-focus-overlay {
  background: black;
}
.lightTheme .purpleTheme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-flat-button, .lightTheme .purpleTheme .mat-raised-button, .lightTheme .purpleTheme .mat-fab, .lightTheme .purpleTheme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.lightTheme .purpleTheme .mat-flat-button.mat-primary, .lightTheme .purpleTheme .mat-raised-button.mat-primary, .lightTheme .purpleTheme .mat-fab.mat-primary, .lightTheme .purpleTheme .mat-mini-fab.mat-primary {
  color: white;
}
.lightTheme .purpleTheme .mat-flat-button.mat-accent, .lightTheme .purpleTheme .mat-raised-button.mat-accent, .lightTheme .purpleTheme .mat-fab.mat-accent, .lightTheme .purpleTheme .mat-mini-fab.mat-accent {
  color: white;
}
.lightTheme .purpleTheme .mat-flat-button.mat-warn, .lightTheme .purpleTheme .mat-raised-button.mat-warn, .lightTheme .purpleTheme .mat-fab.mat-warn, .lightTheme .purpleTheme .mat-mini-fab.mat-warn {
  color: white;
}
.lightTheme .purpleTheme .mat-flat-button.mat-primary.mat-button-disabled, .lightTheme .purpleTheme .mat-flat-button.mat-accent.mat-button-disabled, .lightTheme .purpleTheme .mat-flat-button.mat-warn.mat-button-disabled, .lightTheme .purpleTheme .mat-flat-button.mat-button-disabled.mat-button-disabled, .lightTheme .purpleTheme .mat-raised-button.mat-primary.mat-button-disabled, .lightTheme .purpleTheme .mat-raised-button.mat-accent.mat-button-disabled, .lightTheme .purpleTheme .mat-raised-button.mat-warn.mat-button-disabled, .lightTheme .purpleTheme .mat-raised-button.mat-button-disabled.mat-button-disabled, .lightTheme .purpleTheme .mat-fab.mat-primary.mat-button-disabled, .lightTheme .purpleTheme .mat-fab.mat-accent.mat-button-disabled, .lightTheme .purpleTheme .mat-fab.mat-warn.mat-button-disabled, .lightTheme .purpleTheme .mat-fab.mat-button-disabled.mat-button-disabled, .lightTheme .purpleTheme .mat-mini-fab.mat-primary.mat-button-disabled, .lightTheme .purpleTheme .mat-mini-fab.mat-accent.mat-button-disabled, .lightTheme .purpleTheme .mat-mini-fab.mat-warn.mat-button-disabled, .lightTheme .purpleTheme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.lightTheme .purpleTheme .mat-flat-button.mat-primary, .lightTheme .purpleTheme .mat-raised-button.mat-primary, .lightTheme .purpleTheme .mat-fab.mat-primary, .lightTheme .purpleTheme .mat-mini-fab.mat-primary {
  background-color: #7352ff;
}
.lightTheme .purpleTheme .mat-flat-button.mat-accent, .lightTheme .purpleTheme .mat-raised-button.mat-accent, .lightTheme .purpleTheme .mat-fab.mat-accent, .lightTheme .purpleTheme .mat-mini-fab.mat-accent {
  background-color: #402e8d;
}
.lightTheme .purpleTheme .mat-flat-button.mat-warn, .lightTheme .purpleTheme .mat-raised-button.mat-warn, .lightTheme .purpleTheme .mat-fab.mat-warn, .lightTheme .purpleTheme .mat-mini-fab.mat-warn {
  background-color: #e91e63;
}
.lightTheme .purpleTheme .mat-flat-button.mat-primary.mat-button-disabled, .lightTheme .purpleTheme .mat-flat-button.mat-accent.mat-button-disabled, .lightTheme .purpleTheme .mat-flat-button.mat-warn.mat-button-disabled, .lightTheme .purpleTheme .mat-flat-button.mat-button-disabled.mat-button-disabled, .lightTheme .purpleTheme .mat-raised-button.mat-primary.mat-button-disabled, .lightTheme .purpleTheme .mat-raised-button.mat-accent.mat-button-disabled, .lightTheme .purpleTheme .mat-raised-button.mat-warn.mat-button-disabled, .lightTheme .purpleTheme .mat-raised-button.mat-button-disabled.mat-button-disabled, .lightTheme .purpleTheme .mat-fab.mat-primary.mat-button-disabled, .lightTheme .purpleTheme .mat-fab.mat-accent.mat-button-disabled, .lightTheme .purpleTheme .mat-fab.mat-warn.mat-button-disabled, .lightTheme .purpleTheme .mat-fab.mat-button-disabled.mat-button-disabled, .lightTheme .purpleTheme .mat-mini-fab.mat-primary.mat-button-disabled, .lightTheme .purpleTheme .mat-mini-fab.mat-accent.mat-button-disabled, .lightTheme .purpleTheme .mat-mini-fab.mat-warn.mat-button-disabled, .lightTheme .purpleTheme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-flat-button.mat-primary .mat-ripple-element, .lightTheme .purpleTheme .mat-raised-button.mat-primary .mat-ripple-element, .lightTheme .purpleTheme .mat-fab.mat-primary .mat-ripple-element, .lightTheme .purpleTheme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .purpleTheme .mat-flat-button.mat-accent .mat-ripple-element, .lightTheme .purpleTheme .mat-raised-button.mat-accent .mat-ripple-element, .lightTheme .purpleTheme .mat-fab.mat-accent .mat-ripple-element, .lightTheme .purpleTheme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .purpleTheme .mat-flat-button.mat-warn .mat-ripple-element, .lightTheme .purpleTheme .mat-raised-button.mat-warn .mat-ripple-element, .lightTheme .purpleTheme .mat-fab.mat-warn .mat-ripple-element, .lightTheme .purpleTheme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .purpleTheme .mat-stroked-button:not([class*=mat-elevation-z]), .lightTheme .purpleTheme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-fab:not([class*=mat-elevation-z]), .lightTheme .purpleTheme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .lightTheme .purpleTheme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .lightTheme .purpleTheme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.lightTheme .purpleTheme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.lightTheme .purpleTheme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.lightTheme .purpleTheme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .purpleTheme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.lightTheme .purpleTheme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.lightTheme .purpleTheme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.lightTheme .purpleTheme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.lightTheme .purpleTheme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.lightTheme .purpleTheme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .purpleTheme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.lightTheme .purpleTheme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.lightTheme .purpleTheme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.lightTheme .purpleTheme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.lightTheme .purpleTheme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.lightTheme .purpleTheme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .purpleTheme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.lightTheme .purpleTheme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.lightTheme .purpleTheme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.lightTheme .purpleTheme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.lightTheme .purpleTheme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .lightTheme .purpleTheme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #7352ff;
}
.lightTheme .purpleTheme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .lightTheme .purpleTheme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #402e8d;
}
.lightTheme .purpleTheme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .lightTheme .purpleTheme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e91e63;
}
.lightTheme .purpleTheme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .lightTheme .purpleTheme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.lightTheme .purpleTheme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.lightTheme .purpleTheme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .purpleTheme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.lightTheme .purpleTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.lightTheme .purpleTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #7352ff;
}
.lightTheme .purpleTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.lightTheme .purpleTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #402e8d;
}
.lightTheme .purpleTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.lightTheme .purpleTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e91e63;
}
.lightTheme .purpleTheme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.lightTheme .purpleTheme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.lightTheme .purpleTheme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.lightTheme .purpleTheme .mat-chip.mat-standard-chip::after {
  background: black;
}
.lightTheme .purpleTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #7352ff;
  color: white;
}
.lightTheme .purpleTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.lightTheme .purpleTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .purpleTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e91e63;
  color: white;
}
.lightTheme .purpleTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.lightTheme .purpleTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .purpleTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #402e8d;
  color: white;
}
.lightTheme .purpleTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.lightTheme .purpleTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .purpleTheme .mat-table {
  background: white;
}
.lightTheme .purpleTheme .mat-table thead, .lightTheme .purpleTheme .mat-table tbody, .lightTheme .purpleTheme .mat-table tfoot,
.lightTheme .purpleTheme mat-header-row, .lightTheme .purpleTheme mat-row, .lightTheme .purpleTheme mat-footer-row,
.lightTheme .purpleTheme [mat-header-row], .lightTheme .purpleTheme [mat-row], .lightTheme .purpleTheme [mat-footer-row],
.lightTheme .purpleTheme .mat-table-sticky {
  background: inherit;
}
.lightTheme .purpleTheme mat-row, .lightTheme .purpleTheme mat-header-row, .lightTheme .purpleTheme mat-footer-row,
.lightTheme .purpleTheme th.mat-header-cell, .lightTheme .purpleTheme td.mat-cell, .lightTheme .purpleTheme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .purpleTheme .mat-cell, .lightTheme .purpleTheme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.lightTheme .purpleTheme .mat-datepicker-toggle,
.lightTheme .purpleTheme .mat-datepicker-content .mat-calendar-next-button,
.lightTheme .purpleTheme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .purpleTheme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-calendar-table-header,
.lightTheme .purpleTheme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .purpleTheme .mat-calendar-body-cell-content,
.lightTheme .purpleTheme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.lightTheme .purpleTheme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .purpleTheme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .purpleTheme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.lightTheme .purpleTheme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .purpleTheme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.lightTheme .purpleTheme .mat-calendar-body-in-range::before {
  background: rgba(115, 82, 255, 0.2);
}
.lightTheme .purpleTheme .mat-calendar-body-comparison-identical,
.lightTheme .purpleTheme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.lightTheme .purpleTheme .mat-calendar-body-comparison-bridge-start::before,
.lightTheme .purpleTheme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(115, 82, 255, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .purpleTheme .mat-calendar-body-comparison-bridge-end::before,
.lightTheme .purpleTheme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(115, 82, 255, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .purpleTheme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.lightTheme .purpleTheme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.lightTheme .purpleTheme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.lightTheme .purpleTheme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.lightTheme .purpleTheme .mat-calendar-body-selected {
  background-color: #7352ff;
  color: white;
}
.lightTheme .purpleTheme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(115, 82, 255, 0.4);
}
.lightTheme .purpleTheme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.lightTheme .purpleTheme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.lightTheme .purpleTheme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(115, 82, 255, 0.3);
}
@media (hover: hover) {
  .lightTheme .purpleTheme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(115, 82, 255, 0.3);
  }
}
.lightTheme .purpleTheme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(64, 46, 141, 0.2);
}
.lightTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.lightTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.lightTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.lightTheme .purpleTheme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(64, 46, 141, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.lightTheme .purpleTheme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(64, 46, 141, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.lightTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.lightTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.lightTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.lightTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #402e8d;
  color: white;
}
.lightTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(64, 46, 141, 0.4);
}
.lightTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.lightTheme .purpleTheme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.lightTheme .purpleTheme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(64, 46, 141, 0.3);
}
@media (hover: hover) {
  .lightTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(64, 46, 141, 0.3);
  }
}
.lightTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(233, 30, 99, 0.2);
}
.lightTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.lightTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.lightTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.lightTheme .purpleTheme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.lightTheme .purpleTheme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.lightTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.lightTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.lightTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.lightTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e91e63;
  color: white;
}
.lightTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(233, 30, 99, 0.4);
}
.lightTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.lightTheme .purpleTheme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.lightTheme .purpleTheme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(233, 30, 99, 0.3);
}
@media (hover: hover) {
  .lightTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(233, 30, 99, 0.3);
  }
}
.lightTheme .purpleTheme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-datepicker-toggle-active {
  color: #7352ff;
}
.lightTheme .purpleTheme .mat-datepicker-toggle-active.mat-accent {
  color: #402e8d;
}
.lightTheme .purpleTheme .mat-datepicker-toggle-active.mat-warn {
  color: #e91e63;
}
.lightTheme .purpleTheme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .purpleTheme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .lightTheme .purpleTheme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .lightTheme .purpleTheme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .lightTheme .purpleTheme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.lightTheme .purpleTheme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-expansion-panel-header-description,
.lightTheme .purpleTheme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .purpleTheme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.lightTheme .purpleTheme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.lightTheme .purpleTheme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.lightTheme .purpleTheme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.lightTheme .purpleTheme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.lightTheme .purpleTheme .mat-form-field.mat-focused .mat-form-field-label {
  color: #7352ff;
}
.lightTheme .purpleTheme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #402e8d;
}
.lightTheme .purpleTheme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e91e63;
}
.lightTheme .purpleTheme .mat-focused .mat-form-field-required-marker {
  color: #402e8d;
}
.lightTheme .purpleTheme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #7352ff;
}
.lightTheme .purpleTheme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #402e8d;
}
.lightTheme .purpleTheme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e91e63;
}
.lightTheme .purpleTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #7352ff;
}
.lightTheme .purpleTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #402e8d;
}
.lightTheme .purpleTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e91e63;
}
.lightTheme .purpleTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e91e63;
}
.lightTheme .purpleTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.lightTheme .purpleTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e91e63;
}
.lightTheme .purpleTheme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.lightTheme .purpleTheme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e91e63;
}
.lightTheme .purpleTheme .mat-error {
  color: #e91e63;
}
.lightTheme .purpleTheme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .purpleTheme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .purpleTheme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.lightTheme .purpleTheme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.lightTheme .purpleTheme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.lightTheme .purpleTheme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.lightTheme .purpleTheme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.lightTheme .purpleTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.lightTheme .purpleTheme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.lightTheme .purpleTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .purpleTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.lightTheme .purpleTheme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #7352ff;
}
.lightTheme .purpleTheme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #402e8d;
}
.lightTheme .purpleTheme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e91e63;
}
.lightTheme .purpleTheme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e91e63;
}
.lightTheme .purpleTheme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .purpleTheme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.lightTheme .purpleTheme .mat-icon.mat-primary {
  color: #7352ff;
}
.lightTheme .purpleTheme .mat-icon.mat-accent {
  color: #402e8d;
}
.lightTheme .purpleTheme .mat-icon.mat-warn {
  color: #e91e63;
}
.lightTheme .purpleTheme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .purpleTheme .mat-input-element:disabled,
.lightTheme .purpleTheme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .purpleTheme .mat-input-element {
  caret-color: #7352ff;
}
.lightTheme .purpleTheme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.lightTheme .purpleTheme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.lightTheme .purpleTheme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.lightTheme .purpleTheme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.lightTheme .purpleTheme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #402e8d;
}
.lightTheme .purpleTheme .mat-form-field.mat-warn .mat-input-element,
.lightTheme .purpleTheme .mat-form-field-invalid .mat-input-element {
  caret-color: #e91e63;
}
.lightTheme .purpleTheme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e91e63;
}
.lightTheme .purpleTheme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .purpleTheme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .purpleTheme .mat-list-option:hover, .lightTheme .purpleTheme .mat-list-option:focus,
.lightTheme .purpleTheme .mat-nav-list .mat-list-item:hover,
.lightTheme .purpleTheme .mat-nav-list .mat-list-item:focus,
.lightTheme .purpleTheme .mat-action-list .mat-list-item:hover,
.lightTheme .purpleTheme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.lightTheme .purpleTheme .mat-list-single-selected-option, .lightTheme .purpleTheme .mat-list-single-selected-option:hover, .lightTheme .purpleTheme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-menu-panel {
  background: white;
}
.lightTheme .purpleTheme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-menu-item[disabled],
.lightTheme .purpleTheme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.lightTheme .purpleTheme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .purpleTheme .mat-menu-item .mat-icon-no-color,
.lightTheme .purpleTheme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .purpleTheme .mat-menu-item:hover:not([disabled]),
.lightTheme .purpleTheme .mat-menu-item.cdk-program-focused:not([disabled]),
.lightTheme .purpleTheme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.lightTheme .purpleTheme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.lightTheme .purpleTheme .mat-paginator {
  background: white;
}
.lightTheme .purpleTheme .mat-paginator,
.lightTheme .purpleTheme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .purpleTheme .mat-paginator-decrement,
.lightTheme .purpleTheme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.lightTheme .purpleTheme .mat-paginator-first,
.lightTheme .purpleTheme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.lightTheme .purpleTheme .mat-icon-button[disabled] .mat-paginator-decrement,
.lightTheme .purpleTheme .mat-icon-button[disabled] .mat-paginator-increment,
.lightTheme .purpleTheme .mat-icon-button[disabled] .mat-paginator-first,
.lightTheme .purpleTheme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .purpleTheme .mat-progress-bar-background {
  fill: #d8d0fb;
}
.lightTheme .purpleTheme .mat-progress-bar-buffer {
  background-color: #d8d0fb;
}
.lightTheme .purpleTheme .mat-progress-bar-fill::after {
  background-color: #7352ff;
}
.lightTheme .purpleTheme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #ccc7df;
}
.lightTheme .purpleTheme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #ccc7df;
}
.lightTheme .purpleTheme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #402e8d;
}
.lightTheme .purpleTheme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f6c3d4;
}
.lightTheme .purpleTheme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f6c3d4;
}
.lightTheme .purpleTheme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e91e63;
}
.lightTheme .purpleTheme .mat-progress-spinner circle, .lightTheme .purpleTheme .mat-spinner circle {
  stroke: #7352ff;
}
.lightTheme .purpleTheme .mat-progress-spinner.mat-accent circle, .lightTheme .purpleTheme .mat-spinner.mat-accent circle {
  stroke: #402e8d;
}
.lightTheme .purpleTheme .mat-progress-spinner.mat-warn circle, .lightTheme .purpleTheme .mat-spinner.mat-warn circle {
  stroke: #e91e63;
}
.lightTheme .purpleTheme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.lightTheme .purpleTheme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #7352ff;
}
.lightTheme .purpleTheme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.lightTheme .purpleTheme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .lightTheme .purpleTheme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .lightTheme .purpleTheme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #7352ff;
}
.lightTheme .purpleTheme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #402e8d;
}
.lightTheme .purpleTheme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.lightTheme .purpleTheme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .lightTheme .purpleTheme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .lightTheme .purpleTheme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #402e8d;
}
.lightTheme .purpleTheme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e91e63;
}
.lightTheme .purpleTheme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.lightTheme .purpleTheme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .lightTheme .purpleTheme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .lightTheme .purpleTheme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e91e63;
}
.lightTheme .purpleTheme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.lightTheme .purpleTheme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .purpleTheme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.lightTheme .purpleTheme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .purpleTheme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .purpleTheme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.lightTheme .purpleTheme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.lightTheme .purpleTheme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .purpleTheme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .purpleTheme .mat-select-panel {
  background: white;
}
.lightTheme .purpleTheme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #7352ff;
}
.lightTheme .purpleTheme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #402e8d;
}
.lightTheme .purpleTheme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e91e63;
}
.lightTheme .purpleTheme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e91e63;
}
.lightTheme .purpleTheme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .purpleTheme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.lightTheme .purpleTheme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.lightTheme .purpleTheme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.lightTheme .purpleTheme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.lightTheme .purpleTheme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #402e8d;
}
.lightTheme .purpleTheme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(64, 46, 141, 0.54);
}
.lightTheme .purpleTheme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #402e8d;
}
.lightTheme .purpleTheme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #7352ff;
}
.lightTheme .purpleTheme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(115, 82, 255, 0.54);
}
.lightTheme .purpleTheme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #7352ff;
}
.lightTheme .purpleTheme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e91e63;
}
.lightTheme .purpleTheme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(233, 30, 99, 0.54);
}
.lightTheme .purpleTheme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e91e63;
}
.lightTheme .purpleTheme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.lightTheme .purpleTheme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.lightTheme .purpleTheme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .purpleTheme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.lightTheme .purpleTheme .mat-slider.mat-primary .mat-slider-track-fill,
.lightTheme .purpleTheme .mat-slider.mat-primary .mat-slider-thumb,
.lightTheme .purpleTheme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #7352ff;
}
.lightTheme .purpleTheme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.lightTheme .purpleTheme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(115, 82, 255, 0.2);
}
.lightTheme .purpleTheme .mat-slider.mat-accent .mat-slider-track-fill,
.lightTheme .purpleTheme .mat-slider.mat-accent .mat-slider-thumb,
.lightTheme .purpleTheme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #402e8d;
}
.lightTheme .purpleTheme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.lightTheme .purpleTheme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(64, 46, 141, 0.2);
}
.lightTheme .purpleTheme .mat-slider.mat-warn .mat-slider-track-fill,
.lightTheme .purpleTheme .mat-slider.mat-warn .mat-slider-thumb,
.lightTheme .purpleTheme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e91e63;
}
.lightTheme .purpleTheme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.lightTheme .purpleTheme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(233, 30, 99, 0.2);
}
.lightTheme .purpleTheme .mat-slider:hover .mat-slider-track-background,
.lightTheme .purpleTheme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .purpleTheme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.lightTheme .purpleTheme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.lightTheme .purpleTheme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.lightTheme .purpleTheme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.lightTheme .purpleTheme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.lightTheme .purpleTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.lightTheme .purpleTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.lightTheme .purpleTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.lightTheme .purpleTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .lightTheme .purpleTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .purpleTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .lightTheme .purpleTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.lightTheme .purpleTheme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.lightTheme .purpleTheme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.lightTheme .purpleTheme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.lightTheme .purpleTheme .mat-step-header.cdk-keyboard-focused, .lightTheme .purpleTheme .mat-step-header.cdk-program-focused, .lightTheme .purpleTheme .mat-step-header:hover:not([aria-disabled]), .lightTheme .purpleTheme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.lightTheme .purpleTheme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .lightTheme .purpleTheme .mat-step-header:hover {
    background: none;
  }
}
.lightTheme .purpleTheme .mat-step-header .mat-step-label,
.lightTheme .purpleTheme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .purpleTheme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.lightTheme .purpleTheme .mat-step-header .mat-step-icon-selected,
.lightTheme .purpleTheme .mat-step-header .mat-step-icon-state-done,
.lightTheme .purpleTheme .mat-step-header .mat-step-icon-state-edit {
  background-color: #7352ff;
  color: white;
}
.lightTheme .purpleTheme .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.lightTheme .purpleTheme .mat-step-header.mat-accent .mat-step-icon-selected,
.lightTheme .purpleTheme .mat-step-header.mat-accent .mat-step-icon-state-done,
.lightTheme .purpleTheme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #402e8d;
  color: white;
}
.lightTheme .purpleTheme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.lightTheme .purpleTheme .mat-step-header.mat-warn .mat-step-icon-selected,
.lightTheme .purpleTheme .mat-step-header.mat-warn .mat-step-icon-state-done,
.lightTheme .purpleTheme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e91e63;
  color: white;
}
.lightTheme .purpleTheme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e91e63;
}
.lightTheme .purpleTheme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e91e63;
}
.lightTheme .purpleTheme .mat-stepper-horizontal, .lightTheme .purpleTheme .mat-stepper-vertical {
  background-color: white;
}
.lightTheme .purpleTheme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-horizontal-stepper-header::before,
.lightTheme .purpleTheme .mat-horizontal-stepper-header::after,
.lightTheme .purpleTheme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-sort-header-arrow {
  color: #757575;
}
.lightTheme .purpleTheme .mat-tab-nav-bar,
.lightTheme .purpleTheme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.lightTheme .purpleTheme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.lightTheme .purpleTheme .mat-tab-label, .lightTheme .purpleTheme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-tab-label.mat-tab-disabled, .lightTheme .purpleTheme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .purpleTheme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .purpleTheme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.lightTheme .purpleTheme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.lightTheme .purpleTheme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .purpleTheme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .purpleTheme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .purpleTheme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .purpleTheme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .purpleTheme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(115, 82, 255, 0.3);
}
.lightTheme .purpleTheme .mat-tab-group.mat-primary .mat-ink-bar, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #7352ff;
}
.lightTheme .purpleTheme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .lightTheme .purpleTheme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.lightTheme .purpleTheme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .purpleTheme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .purpleTheme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .purpleTheme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .purpleTheme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .purpleTheme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(64, 46, 141, 0.3);
}
.lightTheme .purpleTheme .mat-tab-group.mat-accent .mat-ink-bar, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #402e8d;
}
.lightTheme .purpleTheme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .lightTheme .purpleTheme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.lightTheme .purpleTheme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .purpleTheme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .purpleTheme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .purpleTheme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .purpleTheme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .purpleTheme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.lightTheme .purpleTheme .mat-tab-group.mat-warn .mat-ink-bar, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e91e63;
}
.lightTheme .purpleTheme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .lightTheme .purpleTheme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.lightTheme .purpleTheme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .purpleTheme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .purpleTheme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .purpleTheme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(115, 82, 255, 0.3);
}
.lightTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-header, .lightTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .lightTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #7352ff;
}
.lightTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .lightTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.lightTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.lightTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.lightTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.lightTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.lightTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.lightTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.lightTheme .purpleTheme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .purpleTheme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .purpleTheme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .purpleTheme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(64, 46, 141, 0.3);
}
.lightTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-header, .lightTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .lightTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #402e8d;
}
.lightTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .lightTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.lightTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.lightTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.lightTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.lightTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.lightTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.lightTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.lightTheme .purpleTheme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .purpleTheme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .purpleTheme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .purpleTheme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.lightTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-header, .lightTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .lightTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e91e63;
}
.lightTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .lightTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.lightTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.lightTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.lightTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.lightTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.lightTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.lightTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.lightTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.lightTheme .purpleTheme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-toolbar.mat-primary {
  background: #7352ff;
  color: white;
}
.lightTheme .purpleTheme .mat-toolbar.mat-accent {
  background: #402e8d;
  color: white;
}
.lightTheme .purpleTheme .mat-toolbar.mat-warn {
  background: #e91e63;
  color: white;
}
.lightTheme .purpleTheme .mat-toolbar .mat-form-field-underline,
.lightTheme .purpleTheme .mat-toolbar .mat-form-field-ripple,
.lightTheme .purpleTheme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.lightTheme .purpleTheme .mat-toolbar .mat-form-field-label,
.lightTheme .purpleTheme .mat-toolbar .mat-focused .mat-form-field-label,
.lightTheme .purpleTheme .mat-toolbar .mat-select-value,
.lightTheme .purpleTheme .mat-toolbar .mat-select-arrow,
.lightTheme .purpleTheme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.lightTheme .purpleTheme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.lightTheme .purpleTheme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.lightTheme .purpleTheme .mat-tree {
  background: white;
}
.lightTheme .purpleTheme .mat-tree-node,
.lightTheme .purpleTheme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .purpleTheme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .purpleTheme .mat-simple-snackbar-action {
  color: #402e8d;
}
.lightTheme .redTheme .leftsidebar .menu-list-item.activeMenu .mat-list-item-content, .lightTheme .redTheme .leftsidebar .menu-list-item.activeMenu:hover .mat-list-item-content {
  background-color: #ff5c8e;
}
.lightTheme .redTheme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.lightTheme .redTheme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-option:hover:not(.mat-option-disabled), .lightTheme .redTheme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.lightTheme .redTheme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.lightTheme .redTheme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .redTheme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff5c8e;
}
.lightTheme .redTheme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #5e244d;
}
.lightTheme .redTheme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e91e63;
}
.lightTheme .redTheme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .redTheme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .redTheme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .redTheme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.lightTheme .redTheme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.lightTheme .redTheme .mat-primary .mat-pseudo-checkbox-checked,
.lightTheme .redTheme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #ff5c8e;
}
.lightTheme .redTheme .mat-pseudo-checkbox-checked,
.lightTheme .redTheme .mat-pseudo-checkbox-indeterminate,
.lightTheme .redTheme .mat-accent .mat-pseudo-checkbox-checked,
.lightTheme .redTheme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #5e244d;
}
.lightTheme .redTheme .mat-warn .mat-pseudo-checkbox-checked,
.lightTheme .redTheme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e91e63;
}
.lightTheme .redTheme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.lightTheme .redTheme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.lightTheme .redTheme .mat-app-background, .lightTheme .redTheme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.lightTheme .redTheme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.lightTheme .redTheme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-badge-content {
  color: white;
  background: #ff5c8e;
}
.cdk-high-contrast-active .lightTheme .redTheme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.lightTheme .redTheme .mat-badge-accent .mat-badge-content {
  background: #5e244d;
  color: white;
}
.lightTheme .redTheme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e91e63;
}
.lightTheme .redTheme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .redTheme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-button, .lightTheme .redTheme .mat-icon-button, .lightTheme .redTheme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.lightTheme .redTheme .mat-button.mat-primary, .lightTheme .redTheme .mat-icon-button.mat-primary, .lightTheme .redTheme .mat-stroked-button.mat-primary {
  color: #ff5c8e;
}
.lightTheme .redTheme .mat-button.mat-accent, .lightTheme .redTheme .mat-icon-button.mat-accent, .lightTheme .redTheme .mat-stroked-button.mat-accent {
  color: #5e244d;
}
.lightTheme .redTheme .mat-button.mat-warn, .lightTheme .redTheme .mat-icon-button.mat-warn, .lightTheme .redTheme .mat-stroked-button.mat-warn {
  color: #e91e63;
}
.lightTheme .redTheme .mat-button.mat-primary.mat-button-disabled, .lightTheme .redTheme .mat-button.mat-accent.mat-button-disabled, .lightTheme .redTheme .mat-button.mat-warn.mat-button-disabled, .lightTheme .redTheme .mat-button.mat-button-disabled.mat-button-disabled, .lightTheme .redTheme .mat-icon-button.mat-primary.mat-button-disabled, .lightTheme .redTheme .mat-icon-button.mat-accent.mat-button-disabled, .lightTheme .redTheme .mat-icon-button.mat-warn.mat-button-disabled, .lightTheme .redTheme .mat-icon-button.mat-button-disabled.mat-button-disabled, .lightTheme .redTheme .mat-stroked-button.mat-primary.mat-button-disabled, .lightTheme .redTheme .mat-stroked-button.mat-accent.mat-button-disabled, .lightTheme .redTheme .mat-stroked-button.mat-warn.mat-button-disabled, .lightTheme .redTheme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.lightTheme .redTheme .mat-button.mat-primary .mat-button-focus-overlay, .lightTheme .redTheme .mat-icon-button.mat-primary .mat-button-focus-overlay, .lightTheme .redTheme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #ff5c8e;
}
.lightTheme .redTheme .mat-button.mat-accent .mat-button-focus-overlay, .lightTheme .redTheme .mat-icon-button.mat-accent .mat-button-focus-overlay, .lightTheme .redTheme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #5e244d;
}
.lightTheme .redTheme .mat-button.mat-warn .mat-button-focus-overlay, .lightTheme .redTheme .mat-icon-button.mat-warn .mat-button-focus-overlay, .lightTheme .redTheme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e91e63;
}
.lightTheme .redTheme .mat-button.mat-button-disabled .mat-button-focus-overlay, .lightTheme .redTheme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .lightTheme .redTheme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.lightTheme .redTheme .mat-button .mat-ripple-element, .lightTheme .redTheme .mat-icon-button .mat-ripple-element, .lightTheme .redTheme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.lightTheme .redTheme .mat-button-focus-overlay {
  background: black;
}
.lightTheme .redTheme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-flat-button, .lightTheme .redTheme .mat-raised-button, .lightTheme .redTheme .mat-fab, .lightTheme .redTheme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.lightTheme .redTheme .mat-flat-button.mat-primary, .lightTheme .redTheme .mat-raised-button.mat-primary, .lightTheme .redTheme .mat-fab.mat-primary, .lightTheme .redTheme .mat-mini-fab.mat-primary {
  color: white;
}
.lightTheme .redTheme .mat-flat-button.mat-accent, .lightTheme .redTheme .mat-raised-button.mat-accent, .lightTheme .redTheme .mat-fab.mat-accent, .lightTheme .redTheme .mat-mini-fab.mat-accent {
  color: white;
}
.lightTheme .redTheme .mat-flat-button.mat-warn, .lightTheme .redTheme .mat-raised-button.mat-warn, .lightTheme .redTheme .mat-fab.mat-warn, .lightTheme .redTheme .mat-mini-fab.mat-warn {
  color: white;
}
.lightTheme .redTheme .mat-flat-button.mat-primary.mat-button-disabled, .lightTheme .redTheme .mat-flat-button.mat-accent.mat-button-disabled, .lightTheme .redTheme .mat-flat-button.mat-warn.mat-button-disabled, .lightTheme .redTheme .mat-flat-button.mat-button-disabled.mat-button-disabled, .lightTheme .redTheme .mat-raised-button.mat-primary.mat-button-disabled, .lightTheme .redTheme .mat-raised-button.mat-accent.mat-button-disabled, .lightTheme .redTheme .mat-raised-button.mat-warn.mat-button-disabled, .lightTheme .redTheme .mat-raised-button.mat-button-disabled.mat-button-disabled, .lightTheme .redTheme .mat-fab.mat-primary.mat-button-disabled, .lightTheme .redTheme .mat-fab.mat-accent.mat-button-disabled, .lightTheme .redTheme .mat-fab.mat-warn.mat-button-disabled, .lightTheme .redTheme .mat-fab.mat-button-disabled.mat-button-disabled, .lightTheme .redTheme .mat-mini-fab.mat-primary.mat-button-disabled, .lightTheme .redTheme .mat-mini-fab.mat-accent.mat-button-disabled, .lightTheme .redTheme .mat-mini-fab.mat-warn.mat-button-disabled, .lightTheme .redTheme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.lightTheme .redTheme .mat-flat-button.mat-primary, .lightTheme .redTheme .mat-raised-button.mat-primary, .lightTheme .redTheme .mat-fab.mat-primary, .lightTheme .redTheme .mat-mini-fab.mat-primary {
  background-color: #ff5c8e;
}
.lightTheme .redTheme .mat-flat-button.mat-accent, .lightTheme .redTheme .mat-raised-button.mat-accent, .lightTheme .redTheme .mat-fab.mat-accent, .lightTheme .redTheme .mat-mini-fab.mat-accent {
  background-color: #5e244d;
}
.lightTheme .redTheme .mat-flat-button.mat-warn, .lightTheme .redTheme .mat-raised-button.mat-warn, .lightTheme .redTheme .mat-fab.mat-warn, .lightTheme .redTheme .mat-mini-fab.mat-warn {
  background-color: #e91e63;
}
.lightTheme .redTheme .mat-flat-button.mat-primary.mat-button-disabled, .lightTheme .redTheme .mat-flat-button.mat-accent.mat-button-disabled, .lightTheme .redTheme .mat-flat-button.mat-warn.mat-button-disabled, .lightTheme .redTheme .mat-flat-button.mat-button-disabled.mat-button-disabled, .lightTheme .redTheme .mat-raised-button.mat-primary.mat-button-disabled, .lightTheme .redTheme .mat-raised-button.mat-accent.mat-button-disabled, .lightTheme .redTheme .mat-raised-button.mat-warn.mat-button-disabled, .lightTheme .redTheme .mat-raised-button.mat-button-disabled.mat-button-disabled, .lightTheme .redTheme .mat-fab.mat-primary.mat-button-disabled, .lightTheme .redTheme .mat-fab.mat-accent.mat-button-disabled, .lightTheme .redTheme .mat-fab.mat-warn.mat-button-disabled, .lightTheme .redTheme .mat-fab.mat-button-disabled.mat-button-disabled, .lightTheme .redTheme .mat-mini-fab.mat-primary.mat-button-disabled, .lightTheme .redTheme .mat-mini-fab.mat-accent.mat-button-disabled, .lightTheme .redTheme .mat-mini-fab.mat-warn.mat-button-disabled, .lightTheme .redTheme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-flat-button.mat-primary .mat-ripple-element, .lightTheme .redTheme .mat-raised-button.mat-primary .mat-ripple-element, .lightTheme .redTheme .mat-fab.mat-primary .mat-ripple-element, .lightTheme .redTheme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .redTheme .mat-flat-button.mat-accent .mat-ripple-element, .lightTheme .redTheme .mat-raised-button.mat-accent .mat-ripple-element, .lightTheme .redTheme .mat-fab.mat-accent .mat-ripple-element, .lightTheme .redTheme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .redTheme .mat-flat-button.mat-warn .mat-ripple-element, .lightTheme .redTheme .mat-raised-button.mat-warn .mat-ripple-element, .lightTheme .redTheme .mat-fab.mat-warn .mat-ripple-element, .lightTheme .redTheme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .redTheme .mat-stroked-button:not([class*=mat-elevation-z]), .lightTheme .redTheme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-fab:not([class*=mat-elevation-z]), .lightTheme .redTheme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .lightTheme .redTheme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .lightTheme .redTheme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.lightTheme .redTheme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.lightTheme .redTheme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.lightTheme .redTheme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .redTheme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.lightTheme .redTheme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.lightTheme .redTheme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.lightTheme .redTheme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.lightTheme .redTheme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.lightTheme .redTheme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .redTheme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.lightTheme .redTheme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.lightTheme .redTheme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.lightTheme .redTheme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.lightTheme .redTheme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.lightTheme .redTheme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .redTheme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.lightTheme .redTheme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.lightTheme .redTheme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.lightTheme .redTheme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.lightTheme .redTheme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .lightTheme .redTheme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #ff5c8e;
}
.lightTheme .redTheme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .lightTheme .redTheme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #5e244d;
}
.lightTheme .redTheme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .lightTheme .redTheme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e91e63;
}
.lightTheme .redTheme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .lightTheme .redTheme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.lightTheme .redTheme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.lightTheme .redTheme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .redTheme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.lightTheme .redTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.lightTheme .redTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #ff5c8e;
}
.lightTheme .redTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.lightTheme .redTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #5e244d;
}
.lightTheme .redTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.lightTheme .redTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e91e63;
}
.lightTheme .redTheme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.lightTheme .redTheme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.lightTheme .redTheme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.lightTheme .redTheme .mat-chip.mat-standard-chip::after {
  background: black;
}
.lightTheme .redTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #ff5c8e;
  color: white;
}
.lightTheme .redTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.lightTheme .redTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .redTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e91e63;
  color: white;
}
.lightTheme .redTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.lightTheme .redTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .redTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #5e244d;
  color: white;
}
.lightTheme .redTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.lightTheme .redTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .redTheme .mat-table {
  background: white;
}
.lightTheme .redTheme .mat-table thead, .lightTheme .redTheme .mat-table tbody, .lightTheme .redTheme .mat-table tfoot,
.lightTheme .redTheme mat-header-row, .lightTheme .redTheme mat-row, .lightTheme .redTheme mat-footer-row,
.lightTheme .redTheme [mat-header-row], .lightTheme .redTheme [mat-row], .lightTheme .redTheme [mat-footer-row],
.lightTheme .redTheme .mat-table-sticky {
  background: inherit;
}
.lightTheme .redTheme mat-row, .lightTheme .redTheme mat-header-row, .lightTheme .redTheme mat-footer-row,
.lightTheme .redTheme th.mat-header-cell, .lightTheme .redTheme td.mat-cell, .lightTheme .redTheme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .redTheme .mat-cell, .lightTheme .redTheme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.lightTheme .redTheme .mat-datepicker-toggle,
.lightTheme .redTheme .mat-datepicker-content .mat-calendar-next-button,
.lightTheme .redTheme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .redTheme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-calendar-table-header,
.lightTheme .redTheme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .redTheme .mat-calendar-body-cell-content,
.lightTheme .redTheme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.lightTheme .redTheme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .redTheme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .redTheme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.lightTheme .redTheme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .redTheme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.lightTheme .redTheme .mat-calendar-body-in-range::before {
  background: rgba(255, 92, 142, 0.2);
}
.lightTheme .redTheme .mat-calendar-body-comparison-identical,
.lightTheme .redTheme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.lightTheme .redTheme .mat-calendar-body-comparison-bridge-start::before,
.lightTheme .redTheme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 92, 142, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .redTheme .mat-calendar-body-comparison-bridge-end::before,
.lightTheme .redTheme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 92, 142, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .redTheme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.lightTheme .redTheme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.lightTheme .redTheme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.lightTheme .redTheme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.lightTheme .redTheme .mat-calendar-body-selected {
  background-color: #ff5c8e;
  color: white;
}
.lightTheme .redTheme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 92, 142, 0.4);
}
.lightTheme .redTheme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.lightTheme .redTheme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.lightTheme .redTheme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 92, 142, 0.3);
}
@media (hover: hover) {
  .lightTheme .redTheme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 92, 142, 0.3);
  }
}
.lightTheme .redTheme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(94, 36, 77, 0.2);
}
.lightTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.lightTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.lightTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.lightTheme .redTheme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(94, 36, 77, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.lightTheme .redTheme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(94, 36, 77, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.lightTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.lightTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.lightTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.lightTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #5e244d;
  color: white;
}
.lightTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(94, 36, 77, 0.4);
}
.lightTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.lightTheme .redTheme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.lightTheme .redTheme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(94, 36, 77, 0.3);
}
@media (hover: hover) {
  .lightTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(94, 36, 77, 0.3);
  }
}
.lightTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(233, 30, 99, 0.2);
}
.lightTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.lightTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.lightTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.lightTheme .redTheme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.lightTheme .redTheme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.lightTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.lightTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.lightTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.lightTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e91e63;
  color: white;
}
.lightTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(233, 30, 99, 0.4);
}
.lightTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.lightTheme .redTheme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.lightTheme .redTheme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(233, 30, 99, 0.3);
}
@media (hover: hover) {
  .lightTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(233, 30, 99, 0.3);
  }
}
.lightTheme .redTheme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-datepicker-toggle-active {
  color: #ff5c8e;
}
.lightTheme .redTheme .mat-datepicker-toggle-active.mat-accent {
  color: #5e244d;
}
.lightTheme .redTheme .mat-datepicker-toggle-active.mat-warn {
  color: #e91e63;
}
.lightTheme .redTheme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .redTheme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .lightTheme .redTheme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .lightTheme .redTheme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .lightTheme .redTheme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.lightTheme .redTheme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-expansion-panel-header-description,
.lightTheme .redTheme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .redTheme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.lightTheme .redTheme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.lightTheme .redTheme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.lightTheme .redTheme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.lightTheme .redTheme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.lightTheme .redTheme .mat-form-field.mat-focused .mat-form-field-label {
  color: #ff5c8e;
}
.lightTheme .redTheme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #5e244d;
}
.lightTheme .redTheme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e91e63;
}
.lightTheme .redTheme .mat-focused .mat-form-field-required-marker {
  color: #5e244d;
}
.lightTheme .redTheme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #ff5c8e;
}
.lightTheme .redTheme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #5e244d;
}
.lightTheme .redTheme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e91e63;
}
.lightTheme .redTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #ff5c8e;
}
.lightTheme .redTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #5e244d;
}
.lightTheme .redTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e91e63;
}
.lightTheme .redTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e91e63;
}
.lightTheme .redTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.lightTheme .redTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e91e63;
}
.lightTheme .redTheme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.lightTheme .redTheme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e91e63;
}
.lightTheme .redTheme .mat-error {
  color: #e91e63;
}
.lightTheme .redTheme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .redTheme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .redTheme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.lightTheme .redTheme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.lightTheme .redTheme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.lightTheme .redTheme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.lightTheme .redTheme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.lightTheme .redTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.lightTheme .redTheme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.lightTheme .redTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .redTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.lightTheme .redTheme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #ff5c8e;
}
.lightTheme .redTheme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #5e244d;
}
.lightTheme .redTheme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e91e63;
}
.lightTheme .redTheme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e91e63;
}
.lightTheme .redTheme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .redTheme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.lightTheme .redTheme .mat-icon.mat-primary {
  color: #ff5c8e;
}
.lightTheme .redTheme .mat-icon.mat-accent {
  color: #5e244d;
}
.lightTheme .redTheme .mat-icon.mat-warn {
  color: #e91e63;
}
.lightTheme .redTheme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .redTheme .mat-input-element:disabled,
.lightTheme .redTheme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .redTheme .mat-input-element {
  caret-color: #ff5c8e;
}
.lightTheme .redTheme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.lightTheme .redTheme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.lightTheme .redTheme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.lightTheme .redTheme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.lightTheme .redTheme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #5e244d;
}
.lightTheme .redTheme .mat-form-field.mat-warn .mat-input-element,
.lightTheme .redTheme .mat-form-field-invalid .mat-input-element {
  caret-color: #e91e63;
}
.lightTheme .redTheme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e91e63;
}
.lightTheme .redTheme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .redTheme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .redTheme .mat-list-option:hover, .lightTheme .redTheme .mat-list-option:focus,
.lightTheme .redTheme .mat-nav-list .mat-list-item:hover,
.lightTheme .redTheme .mat-nav-list .mat-list-item:focus,
.lightTheme .redTheme .mat-action-list .mat-list-item:hover,
.lightTheme .redTheme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.lightTheme .redTheme .mat-list-single-selected-option, .lightTheme .redTheme .mat-list-single-selected-option:hover, .lightTheme .redTheme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-menu-panel {
  background: white;
}
.lightTheme .redTheme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-menu-item[disabled],
.lightTheme .redTheme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.lightTheme .redTheme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .redTheme .mat-menu-item .mat-icon-no-color,
.lightTheme .redTheme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .redTheme .mat-menu-item:hover:not([disabled]),
.lightTheme .redTheme .mat-menu-item.cdk-program-focused:not([disabled]),
.lightTheme .redTheme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.lightTheme .redTheme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.lightTheme .redTheme .mat-paginator {
  background: white;
}
.lightTheme .redTheme .mat-paginator,
.lightTheme .redTheme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .redTheme .mat-paginator-decrement,
.lightTheme .redTheme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.lightTheme .redTheme .mat-paginator-first,
.lightTheme .redTheme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.lightTheme .redTheme .mat-icon-button[disabled] .mat-paginator-decrement,
.lightTheme .redTheme .mat-icon-button[disabled] .mat-paginator-increment,
.lightTheme .redTheme .mat-icon-button[disabled] .mat-paginator-first,
.lightTheme .redTheme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .redTheme .mat-progress-bar-background {
  fill: #fbd3df;
}
.lightTheme .redTheme .mat-progress-bar-buffer {
  background-color: #fbd3df;
}
.lightTheme .redTheme .mat-progress-bar-fill::after {
  background-color: #ff5c8e;
}
.lightTheme .redTheme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #d3c5cf;
}
.lightTheme .redTheme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #d3c5cf;
}
.lightTheme .redTheme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #5e244d;
}
.lightTheme .redTheme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f6c3d4;
}
.lightTheme .redTheme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f6c3d4;
}
.lightTheme .redTheme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e91e63;
}
.lightTheme .redTheme .mat-progress-spinner circle, .lightTheme .redTheme .mat-spinner circle {
  stroke: #ff5c8e;
}
.lightTheme .redTheme .mat-progress-spinner.mat-accent circle, .lightTheme .redTheme .mat-spinner.mat-accent circle {
  stroke: #5e244d;
}
.lightTheme .redTheme .mat-progress-spinner.mat-warn circle, .lightTheme .redTheme .mat-spinner.mat-warn circle {
  stroke: #e91e63;
}
.lightTheme .redTheme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.lightTheme .redTheme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff5c8e;
}
.lightTheme .redTheme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.lightTheme .redTheme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .lightTheme .redTheme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .lightTheme .redTheme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #ff5c8e;
}
.lightTheme .redTheme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #5e244d;
}
.lightTheme .redTheme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.lightTheme .redTheme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .lightTheme .redTheme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .lightTheme .redTheme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #5e244d;
}
.lightTheme .redTheme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e91e63;
}
.lightTheme .redTheme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.lightTheme .redTheme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .lightTheme .redTheme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .lightTheme .redTheme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e91e63;
}
.lightTheme .redTheme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.lightTheme .redTheme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .redTheme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.lightTheme .redTheme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .redTheme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .redTheme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.lightTheme .redTheme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.lightTheme .redTheme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .redTheme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .redTheme .mat-select-panel {
  background: white;
}
.lightTheme .redTheme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #ff5c8e;
}
.lightTheme .redTheme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #5e244d;
}
.lightTheme .redTheme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e91e63;
}
.lightTheme .redTheme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e91e63;
}
.lightTheme .redTheme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .redTheme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.lightTheme .redTheme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.lightTheme .redTheme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.lightTheme .redTheme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.lightTheme .redTheme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #5e244d;
}
.lightTheme .redTheme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(94, 36, 77, 0.54);
}
.lightTheme .redTheme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #5e244d;
}
.lightTheme .redTheme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff5c8e;
}
.lightTheme .redTheme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 92, 142, 0.54);
}
.lightTheme .redTheme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #ff5c8e;
}
.lightTheme .redTheme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e91e63;
}
.lightTheme .redTheme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(233, 30, 99, 0.54);
}
.lightTheme .redTheme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e91e63;
}
.lightTheme .redTheme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.lightTheme .redTheme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.lightTheme .redTheme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .redTheme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.lightTheme .redTheme .mat-slider.mat-primary .mat-slider-track-fill,
.lightTheme .redTheme .mat-slider.mat-primary .mat-slider-thumb,
.lightTheme .redTheme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #ff5c8e;
}
.lightTheme .redTheme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.lightTheme .redTheme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(255, 92, 142, 0.2);
}
.lightTheme .redTheme .mat-slider.mat-accent .mat-slider-track-fill,
.lightTheme .redTheme .mat-slider.mat-accent .mat-slider-thumb,
.lightTheme .redTheme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #5e244d;
}
.lightTheme .redTheme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.lightTheme .redTheme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(94, 36, 77, 0.2);
}
.lightTheme .redTheme .mat-slider.mat-warn .mat-slider-track-fill,
.lightTheme .redTheme .mat-slider.mat-warn .mat-slider-thumb,
.lightTheme .redTheme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e91e63;
}
.lightTheme .redTheme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.lightTheme .redTheme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(233, 30, 99, 0.2);
}
.lightTheme .redTheme .mat-slider:hover .mat-slider-track-background,
.lightTheme .redTheme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .redTheme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.lightTheme .redTheme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.lightTheme .redTheme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.lightTheme .redTheme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.lightTheme .redTheme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.lightTheme .redTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.lightTheme .redTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.lightTheme .redTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.lightTheme .redTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .lightTheme .redTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .redTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .lightTheme .redTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.lightTheme .redTheme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.lightTheme .redTheme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.lightTheme .redTheme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.lightTheme .redTheme .mat-step-header.cdk-keyboard-focused, .lightTheme .redTheme .mat-step-header.cdk-program-focused, .lightTheme .redTheme .mat-step-header:hover:not([aria-disabled]), .lightTheme .redTheme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.lightTheme .redTheme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .lightTheme .redTheme .mat-step-header:hover {
    background: none;
  }
}
.lightTheme .redTheme .mat-step-header .mat-step-label,
.lightTheme .redTheme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .redTheme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.lightTheme .redTheme .mat-step-header .mat-step-icon-selected,
.lightTheme .redTheme .mat-step-header .mat-step-icon-state-done,
.lightTheme .redTheme .mat-step-header .mat-step-icon-state-edit {
  background-color: #ff5c8e;
  color: white;
}
.lightTheme .redTheme .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.lightTheme .redTheme .mat-step-header.mat-accent .mat-step-icon-selected,
.lightTheme .redTheme .mat-step-header.mat-accent .mat-step-icon-state-done,
.lightTheme .redTheme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #5e244d;
  color: white;
}
.lightTheme .redTheme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.lightTheme .redTheme .mat-step-header.mat-warn .mat-step-icon-selected,
.lightTheme .redTheme .mat-step-header.mat-warn .mat-step-icon-state-done,
.lightTheme .redTheme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e91e63;
  color: white;
}
.lightTheme .redTheme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e91e63;
}
.lightTheme .redTheme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e91e63;
}
.lightTheme .redTheme .mat-stepper-horizontal, .lightTheme .redTheme .mat-stepper-vertical {
  background-color: white;
}
.lightTheme .redTheme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-horizontal-stepper-header::before,
.lightTheme .redTheme .mat-horizontal-stepper-header::after,
.lightTheme .redTheme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-sort-header-arrow {
  color: #757575;
}
.lightTheme .redTheme .mat-tab-nav-bar,
.lightTheme .redTheme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.lightTheme .redTheme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.lightTheme .redTheme .mat-tab-label, .lightTheme .redTheme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-tab-label.mat-tab-disabled, .lightTheme .redTheme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .redTheme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .redTheme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.lightTheme .redTheme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.lightTheme .redTheme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .redTheme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .redTheme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .redTheme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .redTheme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .redTheme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .redTheme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .redTheme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 92, 142, 0.3);
}
.lightTheme .redTheme .mat-tab-group.mat-primary .mat-ink-bar, .lightTheme .redTheme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #ff5c8e;
}
.lightTheme .redTheme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .lightTheme .redTheme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .lightTheme .redTheme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .lightTheme .redTheme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.lightTheme .redTheme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .redTheme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .redTheme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .redTheme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .redTheme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .redTheme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .redTheme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .redTheme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(94, 36, 77, 0.3);
}
.lightTheme .redTheme .mat-tab-group.mat-accent .mat-ink-bar, .lightTheme .redTheme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #5e244d;
}
.lightTheme .redTheme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .lightTheme .redTheme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .lightTheme .redTheme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .lightTheme .redTheme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.lightTheme .redTheme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .redTheme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .redTheme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .redTheme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .redTheme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .redTheme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .redTheme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .redTheme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.lightTheme .redTheme .mat-tab-group.mat-warn .mat-ink-bar, .lightTheme .redTheme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e91e63;
}
.lightTheme .redTheme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .lightTheme .redTheme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .lightTheme .redTheme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .lightTheme .redTheme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.lightTheme .redTheme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .redTheme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .redTheme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .redTheme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .redTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .redTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .redTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .redTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 92, 142, 0.3);
}
.lightTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-header, .lightTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .lightTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #ff5c8e;
}
.lightTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .lightTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.lightTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.lightTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.lightTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.lightTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.lightTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.lightTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.lightTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.lightTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.lightTheme .redTheme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .redTheme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .redTheme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .redTheme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .redTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .redTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .redTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .redTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(94, 36, 77, 0.3);
}
.lightTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-header, .lightTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .lightTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #5e244d;
}
.lightTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .lightTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.lightTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.lightTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.lightTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.lightTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.lightTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.lightTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.lightTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.lightTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.lightTheme .redTheme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .redTheme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .redTheme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .redTheme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .redTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .redTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .redTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .redTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.lightTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-header, .lightTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .lightTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e91e63;
}
.lightTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .lightTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.lightTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.lightTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.lightTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.lightTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.lightTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.lightTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .lightTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.lightTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.lightTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.lightTheme .redTheme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-toolbar.mat-primary {
  background: #ff5c8e;
  color: white;
}
.lightTheme .redTheme .mat-toolbar.mat-accent {
  background: #5e244d;
  color: white;
}
.lightTheme .redTheme .mat-toolbar.mat-warn {
  background: #e91e63;
  color: white;
}
.lightTheme .redTheme .mat-toolbar .mat-form-field-underline,
.lightTheme .redTheme .mat-toolbar .mat-form-field-ripple,
.lightTheme .redTheme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.lightTheme .redTheme .mat-toolbar .mat-form-field-label,
.lightTheme .redTheme .mat-toolbar .mat-focused .mat-form-field-label,
.lightTheme .redTheme .mat-toolbar .mat-select-value,
.lightTheme .redTheme .mat-toolbar .mat-select-arrow,
.lightTheme .redTheme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.lightTheme .redTheme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.lightTheme .redTheme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.lightTheme .redTheme .mat-tree {
  background: white;
}
.lightTheme .redTheme .mat-tree-node,
.lightTheme .redTheme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .redTheme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .redTheme .mat-simple-snackbar-action {
  color: #5e244d;
}
.lightTheme .indigoTheme .leftsidebar .menu-list-item.activeMenu .mat-list-item-content, .lightTheme .indigoTheme .leftsidebar .menu-list-item.activeMenu:hover .mat-list-item-content {
  background-color: #1e4db7;
}
.lightTheme .indigoTheme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.lightTheme .indigoTheme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-option:hover:not(.mat-option-disabled), .lightTheme .indigoTheme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.lightTheme .indigoTheme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.lightTheme .indigoTheme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .indigoTheme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #1e4db7;
}
.lightTheme .indigoTheme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #11397b;
}
.lightTheme .indigoTheme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e91e63;
}
.lightTheme .indigoTheme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .indigoTheme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .indigoTheme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .indigoTheme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.lightTheme .indigoTheme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.lightTheme .indigoTheme .mat-primary .mat-pseudo-checkbox-checked,
.lightTheme .indigoTheme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #1e4db7;
}
.lightTheme .indigoTheme .mat-pseudo-checkbox-checked,
.lightTheme .indigoTheme .mat-pseudo-checkbox-indeterminate,
.lightTheme .indigoTheme .mat-accent .mat-pseudo-checkbox-checked,
.lightTheme .indigoTheme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #11397b;
}
.lightTheme .indigoTheme .mat-warn .mat-pseudo-checkbox-checked,
.lightTheme .indigoTheme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e91e63;
}
.lightTheme .indigoTheme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.lightTheme .indigoTheme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.lightTheme .indigoTheme .mat-app-background, .lightTheme .indigoTheme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.lightTheme .indigoTheme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.lightTheme .indigoTheme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-badge-content {
  color: white;
  background: #1e4db7;
}
.cdk-high-contrast-active .lightTheme .indigoTheme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.lightTheme .indigoTheme .mat-badge-accent .mat-badge-content {
  background: #11397b;
  color: white;
}
.lightTheme .indigoTheme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e91e63;
}
.lightTheme .indigoTheme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .indigoTheme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-button, .lightTheme .indigoTheme .mat-icon-button, .lightTheme .indigoTheme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.lightTheme .indigoTheme .mat-button.mat-primary, .lightTheme .indigoTheme .mat-icon-button.mat-primary, .lightTheme .indigoTheme .mat-stroked-button.mat-primary {
  color: #1e4db7;
}
.lightTheme .indigoTheme .mat-button.mat-accent, .lightTheme .indigoTheme .mat-icon-button.mat-accent, .lightTheme .indigoTheme .mat-stroked-button.mat-accent {
  color: #11397b;
}
.lightTheme .indigoTheme .mat-button.mat-warn, .lightTheme .indigoTheme .mat-icon-button.mat-warn, .lightTheme .indigoTheme .mat-stroked-button.mat-warn {
  color: #e91e63;
}
.lightTheme .indigoTheme .mat-button.mat-primary.mat-button-disabled, .lightTheme .indigoTheme .mat-button.mat-accent.mat-button-disabled, .lightTheme .indigoTheme .mat-button.mat-warn.mat-button-disabled, .lightTheme .indigoTheme .mat-button.mat-button-disabled.mat-button-disabled, .lightTheme .indigoTheme .mat-icon-button.mat-primary.mat-button-disabled, .lightTheme .indigoTheme .mat-icon-button.mat-accent.mat-button-disabled, .lightTheme .indigoTheme .mat-icon-button.mat-warn.mat-button-disabled, .lightTheme .indigoTheme .mat-icon-button.mat-button-disabled.mat-button-disabled, .lightTheme .indigoTheme .mat-stroked-button.mat-primary.mat-button-disabled, .lightTheme .indigoTheme .mat-stroked-button.mat-accent.mat-button-disabled, .lightTheme .indigoTheme .mat-stroked-button.mat-warn.mat-button-disabled, .lightTheme .indigoTheme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.lightTheme .indigoTheme .mat-button.mat-primary .mat-button-focus-overlay, .lightTheme .indigoTheme .mat-icon-button.mat-primary .mat-button-focus-overlay, .lightTheme .indigoTheme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #1e4db7;
}
.lightTheme .indigoTheme .mat-button.mat-accent .mat-button-focus-overlay, .lightTheme .indigoTheme .mat-icon-button.mat-accent .mat-button-focus-overlay, .lightTheme .indigoTheme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #11397b;
}
.lightTheme .indigoTheme .mat-button.mat-warn .mat-button-focus-overlay, .lightTheme .indigoTheme .mat-icon-button.mat-warn .mat-button-focus-overlay, .lightTheme .indigoTheme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e91e63;
}
.lightTheme .indigoTheme .mat-button.mat-button-disabled .mat-button-focus-overlay, .lightTheme .indigoTheme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .lightTheme .indigoTheme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.lightTheme .indigoTheme .mat-button .mat-ripple-element, .lightTheme .indigoTheme .mat-icon-button .mat-ripple-element, .lightTheme .indigoTheme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.lightTheme .indigoTheme .mat-button-focus-overlay {
  background: black;
}
.lightTheme .indigoTheme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-flat-button, .lightTheme .indigoTheme .mat-raised-button, .lightTheme .indigoTheme .mat-fab, .lightTheme .indigoTheme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.lightTheme .indigoTheme .mat-flat-button.mat-primary, .lightTheme .indigoTheme .mat-raised-button.mat-primary, .lightTheme .indigoTheme .mat-fab.mat-primary, .lightTheme .indigoTheme .mat-mini-fab.mat-primary {
  color: white;
}
.lightTheme .indigoTheme .mat-flat-button.mat-accent, .lightTheme .indigoTheme .mat-raised-button.mat-accent, .lightTheme .indigoTheme .mat-fab.mat-accent, .lightTheme .indigoTheme .mat-mini-fab.mat-accent {
  color: white;
}
.lightTheme .indigoTheme .mat-flat-button.mat-warn, .lightTheme .indigoTheme .mat-raised-button.mat-warn, .lightTheme .indigoTheme .mat-fab.mat-warn, .lightTheme .indigoTheme .mat-mini-fab.mat-warn {
  color: white;
}
.lightTheme .indigoTheme .mat-flat-button.mat-primary.mat-button-disabled, .lightTheme .indigoTheme .mat-flat-button.mat-accent.mat-button-disabled, .lightTheme .indigoTheme .mat-flat-button.mat-warn.mat-button-disabled, .lightTheme .indigoTheme .mat-flat-button.mat-button-disabled.mat-button-disabled, .lightTheme .indigoTheme .mat-raised-button.mat-primary.mat-button-disabled, .lightTheme .indigoTheme .mat-raised-button.mat-accent.mat-button-disabled, .lightTheme .indigoTheme .mat-raised-button.mat-warn.mat-button-disabled, .lightTheme .indigoTheme .mat-raised-button.mat-button-disabled.mat-button-disabled, .lightTheme .indigoTheme .mat-fab.mat-primary.mat-button-disabled, .lightTheme .indigoTheme .mat-fab.mat-accent.mat-button-disabled, .lightTheme .indigoTheme .mat-fab.mat-warn.mat-button-disabled, .lightTheme .indigoTheme .mat-fab.mat-button-disabled.mat-button-disabled, .lightTheme .indigoTheme .mat-mini-fab.mat-primary.mat-button-disabled, .lightTheme .indigoTheme .mat-mini-fab.mat-accent.mat-button-disabled, .lightTheme .indigoTheme .mat-mini-fab.mat-warn.mat-button-disabled, .lightTheme .indigoTheme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.lightTheme .indigoTheme .mat-flat-button.mat-primary, .lightTheme .indigoTheme .mat-raised-button.mat-primary, .lightTheme .indigoTheme .mat-fab.mat-primary, .lightTheme .indigoTheme .mat-mini-fab.mat-primary {
  background-color: #1e4db7;
}
.lightTheme .indigoTheme .mat-flat-button.mat-accent, .lightTheme .indigoTheme .mat-raised-button.mat-accent, .lightTheme .indigoTheme .mat-fab.mat-accent, .lightTheme .indigoTheme .mat-mini-fab.mat-accent {
  background-color: #11397b;
}
.lightTheme .indigoTheme .mat-flat-button.mat-warn, .lightTheme .indigoTheme .mat-raised-button.mat-warn, .lightTheme .indigoTheme .mat-fab.mat-warn, .lightTheme .indigoTheme .mat-mini-fab.mat-warn {
  background-color: #e91e63;
}
.lightTheme .indigoTheme .mat-flat-button.mat-primary.mat-button-disabled, .lightTheme .indigoTheme .mat-flat-button.mat-accent.mat-button-disabled, .lightTheme .indigoTheme .mat-flat-button.mat-warn.mat-button-disabled, .lightTheme .indigoTheme .mat-flat-button.mat-button-disabled.mat-button-disabled, .lightTheme .indigoTheme .mat-raised-button.mat-primary.mat-button-disabled, .lightTheme .indigoTheme .mat-raised-button.mat-accent.mat-button-disabled, .lightTheme .indigoTheme .mat-raised-button.mat-warn.mat-button-disabled, .lightTheme .indigoTheme .mat-raised-button.mat-button-disabled.mat-button-disabled, .lightTheme .indigoTheme .mat-fab.mat-primary.mat-button-disabled, .lightTheme .indigoTheme .mat-fab.mat-accent.mat-button-disabled, .lightTheme .indigoTheme .mat-fab.mat-warn.mat-button-disabled, .lightTheme .indigoTheme .mat-fab.mat-button-disabled.mat-button-disabled, .lightTheme .indigoTheme .mat-mini-fab.mat-primary.mat-button-disabled, .lightTheme .indigoTheme .mat-mini-fab.mat-accent.mat-button-disabled, .lightTheme .indigoTheme .mat-mini-fab.mat-warn.mat-button-disabled, .lightTheme .indigoTheme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-flat-button.mat-primary .mat-ripple-element, .lightTheme .indigoTheme .mat-raised-button.mat-primary .mat-ripple-element, .lightTheme .indigoTheme .mat-fab.mat-primary .mat-ripple-element, .lightTheme .indigoTheme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .indigoTheme .mat-flat-button.mat-accent .mat-ripple-element, .lightTheme .indigoTheme .mat-raised-button.mat-accent .mat-ripple-element, .lightTheme .indigoTheme .mat-fab.mat-accent .mat-ripple-element, .lightTheme .indigoTheme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .indigoTheme .mat-flat-button.mat-warn .mat-ripple-element, .lightTheme .indigoTheme .mat-raised-button.mat-warn .mat-ripple-element, .lightTheme .indigoTheme .mat-fab.mat-warn .mat-ripple-element, .lightTheme .indigoTheme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .indigoTheme .mat-stroked-button:not([class*=mat-elevation-z]), .lightTheme .indigoTheme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-fab:not([class*=mat-elevation-z]), .lightTheme .indigoTheme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .lightTheme .indigoTheme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .lightTheme .indigoTheme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.lightTheme .indigoTheme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.lightTheme .indigoTheme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.lightTheme .indigoTheme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .indigoTheme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.lightTheme .indigoTheme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.lightTheme .indigoTheme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.lightTheme .indigoTheme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.lightTheme .indigoTheme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.lightTheme .indigoTheme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .indigoTheme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.lightTheme .indigoTheme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.lightTheme .indigoTheme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.lightTheme .indigoTheme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.lightTheme .indigoTheme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.lightTheme .indigoTheme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .indigoTheme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.lightTheme .indigoTheme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.lightTheme .indigoTheme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.lightTheme .indigoTheme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.lightTheme .indigoTheme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .lightTheme .indigoTheme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #1e4db7;
}
.lightTheme .indigoTheme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .lightTheme .indigoTheme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #11397b;
}
.lightTheme .indigoTheme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .lightTheme .indigoTheme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e91e63;
}
.lightTheme .indigoTheme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .lightTheme .indigoTheme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.lightTheme .indigoTheme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.lightTheme .indigoTheme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .indigoTheme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.lightTheme .indigoTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.lightTheme .indigoTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #1e4db7;
}
.lightTheme .indigoTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.lightTheme .indigoTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #11397b;
}
.lightTheme .indigoTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.lightTheme .indigoTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e91e63;
}
.lightTheme .indigoTheme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.lightTheme .indigoTheme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.lightTheme .indigoTheme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.lightTheme .indigoTheme .mat-chip.mat-standard-chip::after {
  background: black;
}
.lightTheme .indigoTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #1e4db7;
  color: white;
}
.lightTheme .indigoTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.lightTheme .indigoTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .indigoTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e91e63;
  color: white;
}
.lightTheme .indigoTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.lightTheme .indigoTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .indigoTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #11397b;
  color: white;
}
.lightTheme .indigoTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.lightTheme .indigoTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightTheme .indigoTheme .mat-table {
  background: white;
}
.lightTheme .indigoTheme .mat-table thead, .lightTheme .indigoTheme .mat-table tbody, .lightTheme .indigoTheme .mat-table tfoot,
.lightTheme .indigoTheme mat-header-row, .lightTheme .indigoTheme mat-row, .lightTheme .indigoTheme mat-footer-row,
.lightTheme .indigoTheme [mat-header-row], .lightTheme .indigoTheme [mat-row], .lightTheme .indigoTheme [mat-footer-row],
.lightTheme .indigoTheme .mat-table-sticky {
  background: inherit;
}
.lightTheme .indigoTheme mat-row, .lightTheme .indigoTheme mat-header-row, .lightTheme .indigoTheme mat-footer-row,
.lightTheme .indigoTheme th.mat-header-cell, .lightTheme .indigoTheme td.mat-cell, .lightTheme .indigoTheme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .indigoTheme .mat-cell, .lightTheme .indigoTheme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.lightTheme .indigoTheme .mat-datepicker-toggle,
.lightTheme .indigoTheme .mat-datepicker-content .mat-calendar-next-button,
.lightTheme .indigoTheme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .indigoTheme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-calendar-table-header,
.lightTheme .indigoTheme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .indigoTheme .mat-calendar-body-cell-content,
.lightTheme .indigoTheme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.lightTheme .indigoTheme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .indigoTheme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .indigoTheme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.lightTheme .indigoTheme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .indigoTheme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.lightTheme .indigoTheme .mat-calendar-body-in-range::before {
  background: rgba(30, 77, 183, 0.2);
}
.lightTheme .indigoTheme .mat-calendar-body-comparison-identical,
.lightTheme .indigoTheme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.lightTheme .indigoTheme .mat-calendar-body-comparison-bridge-start::before,
.lightTheme .indigoTheme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(30, 77, 183, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .indigoTheme .mat-calendar-body-comparison-bridge-end::before,
.lightTheme .indigoTheme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(30, 77, 183, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .indigoTheme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.lightTheme .indigoTheme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.lightTheme .indigoTheme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.lightTheme .indigoTheme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.lightTheme .indigoTheme .mat-calendar-body-selected {
  background-color: #1e4db7;
  color: white;
}
.lightTheme .indigoTheme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(30, 77, 183, 0.4);
}
.lightTheme .indigoTheme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.lightTheme .indigoTheme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.lightTheme .indigoTheme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(30, 77, 183, 0.3);
}
@media (hover: hover) {
  .lightTheme .indigoTheme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(30, 77, 183, 0.3);
  }
}
.lightTheme .indigoTheme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(17, 57, 123, 0.2);
}
.lightTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.lightTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.lightTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.lightTheme .indigoTheme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(17, 57, 123, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.lightTheme .indigoTheme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(17, 57, 123, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.lightTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.lightTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.lightTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.lightTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #11397b;
  color: white;
}
.lightTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(17, 57, 123, 0.4);
}
.lightTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.lightTheme .indigoTheme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.lightTheme .indigoTheme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(17, 57, 123, 0.3);
}
@media (hover: hover) {
  .lightTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(17, 57, 123, 0.3);
  }
}
.lightTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(233, 30, 99, 0.2);
}
.lightTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.lightTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.lightTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.lightTheme .indigoTheme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.lightTheme .indigoTheme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.lightTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.lightTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.lightTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.lightTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.lightTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e91e63;
  color: white;
}
.lightTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(233, 30, 99, 0.4);
}
.lightTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.lightTheme .indigoTheme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.lightTheme .indigoTheme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(233, 30, 99, 0.3);
}
@media (hover: hover) {
  .lightTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(233, 30, 99, 0.3);
  }
}
.lightTheme .indigoTheme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-datepicker-toggle-active {
  color: #1e4db7;
}
.lightTheme .indigoTheme .mat-datepicker-toggle-active.mat-accent {
  color: #11397b;
}
.lightTheme .indigoTheme .mat-datepicker-toggle-active.mat-warn {
  color: #e91e63;
}
.lightTheme .indigoTheme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .indigoTheme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .lightTheme .indigoTheme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .lightTheme .indigoTheme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .lightTheme .indigoTheme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.lightTheme .indigoTheme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-expansion-panel-header-description,
.lightTheme .indigoTheme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .indigoTheme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.lightTheme .indigoTheme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.lightTheme .indigoTheme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.lightTheme .indigoTheme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.lightTheme .indigoTheme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.lightTheme .indigoTheme .mat-form-field.mat-focused .mat-form-field-label {
  color: #1e4db7;
}
.lightTheme .indigoTheme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #11397b;
}
.lightTheme .indigoTheme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e91e63;
}
.lightTheme .indigoTheme .mat-focused .mat-form-field-required-marker {
  color: #11397b;
}
.lightTheme .indigoTheme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #1e4db7;
}
.lightTheme .indigoTheme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #11397b;
}
.lightTheme .indigoTheme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e91e63;
}
.lightTheme .indigoTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #1e4db7;
}
.lightTheme .indigoTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #11397b;
}
.lightTheme .indigoTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e91e63;
}
.lightTheme .indigoTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e91e63;
}
.lightTheme .indigoTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.lightTheme .indigoTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e91e63;
}
.lightTheme .indigoTheme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.lightTheme .indigoTheme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e91e63;
}
.lightTheme .indigoTheme .mat-error {
  color: #e91e63;
}
.lightTheme .indigoTheme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .indigoTheme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .indigoTheme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.lightTheme .indigoTheme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.lightTheme .indigoTheme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.lightTheme .indigoTheme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.lightTheme .indigoTheme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.lightTheme .indigoTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.lightTheme .indigoTheme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.lightTheme .indigoTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .indigoTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.lightTheme .indigoTheme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #1e4db7;
}
.lightTheme .indigoTheme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #11397b;
}
.lightTheme .indigoTheme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e91e63;
}
.lightTheme .indigoTheme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e91e63;
}
.lightTheme .indigoTheme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .indigoTheme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.lightTheme .indigoTheme .mat-icon.mat-primary {
  color: #1e4db7;
}
.lightTheme .indigoTheme .mat-icon.mat-accent {
  color: #11397b;
}
.lightTheme .indigoTheme .mat-icon.mat-warn {
  color: #e91e63;
}
.lightTheme .indigoTheme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .indigoTheme .mat-input-element:disabled,
.lightTheme .indigoTheme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .indigoTheme .mat-input-element {
  caret-color: #1e4db7;
}
.lightTheme .indigoTheme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.lightTheme .indigoTheme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.lightTheme .indigoTheme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.lightTheme .indigoTheme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.lightTheme .indigoTheme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #11397b;
}
.lightTheme .indigoTheme .mat-form-field.mat-warn .mat-input-element,
.lightTheme .indigoTheme .mat-form-field-invalid .mat-input-element {
  caret-color: #e91e63;
}
.lightTheme .indigoTheme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e91e63;
}
.lightTheme .indigoTheme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .indigoTheme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .indigoTheme .mat-list-option:hover, .lightTheme .indigoTheme .mat-list-option:focus,
.lightTheme .indigoTheme .mat-nav-list .mat-list-item:hover,
.lightTheme .indigoTheme .mat-nav-list .mat-list-item:focus,
.lightTheme .indigoTheme .mat-action-list .mat-list-item:hover,
.lightTheme .indigoTheme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.lightTheme .indigoTheme .mat-list-single-selected-option, .lightTheme .indigoTheme .mat-list-single-selected-option:hover, .lightTheme .indigoTheme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-menu-panel {
  background: white;
}
.lightTheme .indigoTheme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-menu-item[disabled],
.lightTheme .indigoTheme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.lightTheme .indigoTheme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .indigoTheme .mat-menu-item .mat-icon-no-color,
.lightTheme .indigoTheme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .indigoTheme .mat-menu-item:hover:not([disabled]),
.lightTheme .indigoTheme .mat-menu-item.cdk-program-focused:not([disabled]),
.lightTheme .indigoTheme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.lightTheme .indigoTheme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.lightTheme .indigoTheme .mat-paginator {
  background: white;
}
.lightTheme .indigoTheme .mat-paginator,
.lightTheme .indigoTheme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .indigoTheme .mat-paginator-decrement,
.lightTheme .indigoTheme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.lightTheme .indigoTheme .mat-paginator-first,
.lightTheme .indigoTheme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.lightTheme .indigoTheme .mat-icon-button[disabled] .mat-paginator-decrement,
.lightTheme .indigoTheme .mat-icon-button[disabled] .mat-paginator-increment,
.lightTheme .indigoTheme .mat-icon-button[disabled] .mat-paginator-first,
.lightTheme .indigoTheme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .indigoTheme .mat-progress-bar-background {
  fill: #c3cfe9;
}
.lightTheme .indigoTheme .mat-progress-bar-buffer {
  background-color: #c3cfe9;
}
.lightTheme .indigoTheme .mat-progress-bar-fill::after {
  background-color: #1e4db7;
}
.lightTheme .indigoTheme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #c0cada;
}
.lightTheme .indigoTheme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #c0cada;
}
.lightTheme .indigoTheme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #11397b;
}
.lightTheme .indigoTheme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f6c3d4;
}
.lightTheme .indigoTheme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f6c3d4;
}
.lightTheme .indigoTheme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e91e63;
}
.lightTheme .indigoTheme .mat-progress-spinner circle, .lightTheme .indigoTheme .mat-spinner circle {
  stroke: #1e4db7;
}
.lightTheme .indigoTheme .mat-progress-spinner.mat-accent circle, .lightTheme .indigoTheme .mat-spinner.mat-accent circle {
  stroke: #11397b;
}
.lightTheme .indigoTheme .mat-progress-spinner.mat-warn circle, .lightTheme .indigoTheme .mat-spinner.mat-warn circle {
  stroke: #e91e63;
}
.lightTheme .indigoTheme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.lightTheme .indigoTheme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #1e4db7;
}
.lightTheme .indigoTheme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.lightTheme .indigoTheme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .lightTheme .indigoTheme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .lightTheme .indigoTheme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #1e4db7;
}
.lightTheme .indigoTheme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #11397b;
}
.lightTheme .indigoTheme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.lightTheme .indigoTheme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .lightTheme .indigoTheme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .lightTheme .indigoTheme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #11397b;
}
.lightTheme .indigoTheme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e91e63;
}
.lightTheme .indigoTheme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.lightTheme .indigoTheme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .lightTheme .indigoTheme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .lightTheme .indigoTheme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e91e63;
}
.lightTheme .indigoTheme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.lightTheme .indigoTheme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .indigoTheme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.lightTheme .indigoTheme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .indigoTheme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .indigoTheme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.lightTheme .indigoTheme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.lightTheme .indigoTheme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .indigoTheme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .indigoTheme .mat-select-panel {
  background: white;
}
.lightTheme .indigoTheme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #1e4db7;
}
.lightTheme .indigoTheme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #11397b;
}
.lightTheme .indigoTheme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e91e63;
}
.lightTheme .indigoTheme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e91e63;
}
.lightTheme .indigoTheme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .indigoTheme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.lightTheme .indigoTheme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.lightTheme .indigoTheme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.lightTheme .indigoTheme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.lightTheme .indigoTheme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #11397b;
}
.lightTheme .indigoTheme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(17, 57, 123, 0.54);
}
.lightTheme .indigoTheme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #11397b;
}
.lightTheme .indigoTheme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #1e4db7;
}
.lightTheme .indigoTheme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(30, 77, 183, 0.54);
}
.lightTheme .indigoTheme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #1e4db7;
}
.lightTheme .indigoTheme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e91e63;
}
.lightTheme .indigoTheme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(233, 30, 99, 0.54);
}
.lightTheme .indigoTheme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e91e63;
}
.lightTheme .indigoTheme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.lightTheme .indigoTheme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.lightTheme .indigoTheme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .indigoTheme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.lightTheme .indigoTheme .mat-slider.mat-primary .mat-slider-track-fill,
.lightTheme .indigoTheme .mat-slider.mat-primary .mat-slider-thumb,
.lightTheme .indigoTheme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #1e4db7;
}
.lightTheme .indigoTheme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.lightTheme .indigoTheme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(30, 77, 183, 0.2);
}
.lightTheme .indigoTheme .mat-slider.mat-accent .mat-slider-track-fill,
.lightTheme .indigoTheme .mat-slider.mat-accent .mat-slider-thumb,
.lightTheme .indigoTheme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #11397b;
}
.lightTheme .indigoTheme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.lightTheme .indigoTheme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(17, 57, 123, 0.2);
}
.lightTheme .indigoTheme .mat-slider.mat-warn .mat-slider-track-fill,
.lightTheme .indigoTheme .mat-slider.mat-warn .mat-slider-thumb,
.lightTheme .indigoTheme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e91e63;
}
.lightTheme .indigoTheme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.lightTheme .indigoTheme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(233, 30, 99, 0.2);
}
.lightTheme .indigoTheme .mat-slider:hover .mat-slider-track-background,
.lightTheme .indigoTheme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .indigoTheme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.lightTheme .indigoTheme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.lightTheme .indigoTheme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.lightTheme .indigoTheme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.lightTheme .indigoTheme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.lightTheme .indigoTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.lightTheme .indigoTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.lightTheme .indigoTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.lightTheme .indigoTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .lightTheme .indigoTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .indigoTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .lightTheme .indigoTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.lightTheme .indigoTheme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.lightTheme .indigoTheme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.lightTheme .indigoTheme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.lightTheme .indigoTheme .mat-step-header.cdk-keyboard-focused, .lightTheme .indigoTheme .mat-step-header.cdk-program-focused, .lightTheme .indigoTheme .mat-step-header:hover:not([aria-disabled]), .lightTheme .indigoTheme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.lightTheme .indigoTheme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .lightTheme .indigoTheme .mat-step-header:hover {
    background: none;
  }
}
.lightTheme .indigoTheme .mat-step-header .mat-step-label,
.lightTheme .indigoTheme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.lightTheme .indigoTheme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.lightTheme .indigoTheme .mat-step-header .mat-step-icon-selected,
.lightTheme .indigoTheme .mat-step-header .mat-step-icon-state-done,
.lightTheme .indigoTheme .mat-step-header .mat-step-icon-state-edit {
  background-color: #1e4db7;
  color: white;
}
.lightTheme .indigoTheme .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.lightTheme .indigoTheme .mat-step-header.mat-accent .mat-step-icon-selected,
.lightTheme .indigoTheme .mat-step-header.mat-accent .mat-step-icon-state-done,
.lightTheme .indigoTheme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #11397b;
  color: white;
}
.lightTheme .indigoTheme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.lightTheme .indigoTheme .mat-step-header.mat-warn .mat-step-icon-selected,
.lightTheme .indigoTheme .mat-step-header.mat-warn .mat-step-icon-state-done,
.lightTheme .indigoTheme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e91e63;
  color: white;
}
.lightTheme .indigoTheme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e91e63;
}
.lightTheme .indigoTheme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e91e63;
}
.lightTheme .indigoTheme .mat-stepper-horizontal, .lightTheme .indigoTheme .mat-stepper-vertical {
  background-color: white;
}
.lightTheme .indigoTheme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-horizontal-stepper-header::before,
.lightTheme .indigoTheme .mat-horizontal-stepper-header::after,
.lightTheme .indigoTheme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-sort-header-arrow {
  color: #757575;
}
.lightTheme .indigoTheme .mat-tab-nav-bar,
.lightTheme .indigoTheme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.lightTheme .indigoTheme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.lightTheme .indigoTheme .mat-tab-label, .lightTheme .indigoTheme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-tab-label.mat-tab-disabled, .lightTheme .indigoTheme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.lightTheme .indigoTheme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.lightTheme .indigoTheme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.lightTheme .indigoTheme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.lightTheme .indigoTheme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .indigoTheme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .indigoTheme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .indigoTheme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .indigoTheme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .indigoTheme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(30, 77, 183, 0.3);
}
.lightTheme .indigoTheme .mat-tab-group.mat-primary .mat-ink-bar, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #1e4db7;
}
.lightTheme .indigoTheme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .lightTheme .indigoTheme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.lightTheme .indigoTheme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .indigoTheme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .indigoTheme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .indigoTheme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .indigoTheme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .indigoTheme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(17, 57, 123, 0.3);
}
.lightTheme .indigoTheme .mat-tab-group.mat-accent .mat-ink-bar, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #11397b;
}
.lightTheme .indigoTheme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .lightTheme .indigoTheme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.lightTheme .indigoTheme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .indigoTheme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .indigoTheme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .indigoTheme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .indigoTheme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .indigoTheme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.lightTheme .indigoTheme .mat-tab-group.mat-warn .mat-ink-bar, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e91e63;
}
.lightTheme .indigoTheme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .lightTheme .indigoTheme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.lightTheme .indigoTheme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .indigoTheme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .indigoTheme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .indigoTheme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(30, 77, 183, 0.3);
}
.lightTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-header, .lightTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .lightTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #1e4db7;
}
.lightTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .lightTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.lightTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.lightTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.lightTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.lightTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.lightTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.lightTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.lightTheme .indigoTheme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .indigoTheme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .indigoTheme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .indigoTheme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(17, 57, 123, 0.3);
}
.lightTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-header, .lightTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .lightTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #11397b;
}
.lightTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .lightTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.lightTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.lightTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.lightTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.lightTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.lightTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.lightTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.lightTheme .indigoTheme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .indigoTheme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .indigoTheme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .indigoTheme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.lightTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-header, .lightTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .lightTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e91e63;
}
.lightTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .lightTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.lightTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.lightTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.lightTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.lightTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.lightTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.lightTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.lightTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.lightTheme .indigoTheme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-toolbar.mat-primary {
  background: #1e4db7;
  color: white;
}
.lightTheme .indigoTheme .mat-toolbar.mat-accent {
  background: #11397b;
  color: white;
}
.lightTheme .indigoTheme .mat-toolbar.mat-warn {
  background: #e91e63;
  color: white;
}
.lightTheme .indigoTheme .mat-toolbar .mat-form-field-underline,
.lightTheme .indigoTheme .mat-toolbar .mat-form-field-ripple,
.lightTheme .indigoTheme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.lightTheme .indigoTheme .mat-toolbar .mat-form-field-label,
.lightTheme .indigoTheme .mat-toolbar .mat-focused .mat-form-field-label,
.lightTheme .indigoTheme .mat-toolbar .mat-select-value,
.lightTheme .indigoTheme .mat-toolbar .mat-select-arrow,
.lightTheme .indigoTheme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.lightTheme .indigoTheme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.lightTheme .indigoTheme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.lightTheme .indigoTheme .mat-tree {
  background: white;
}
.lightTheme .indigoTheme .mat-tree-node,
.lightTheme .indigoTheme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.lightTheme .indigoTheme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.lightTheme .indigoTheme .mat-simple-snackbar-action {
  color: #11397b;
}

.darkTheme .blueTheme .leftsidebar .menu-list-item.activeMenu .mat-list-item-content, .darkTheme .blueTheme .leftsidebar .menu-list-item.activeMenu:hover .mat-list-item-content {
  background-color: #1a97f5;
}
.darkTheme .blueTheme .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .blueTheme .mat-option {
  color: white;
}
.darkTheme .blueTheme .mat-option:hover:not(.mat-option-disabled), .darkTheme .blueTheme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.darkTheme .blueTheme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.darkTheme .blueTheme .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.darkTheme .blueTheme .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #1e4db7;
}
.darkTheme .blueTheme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #1a97f5;
}
.darkTheme .blueTheme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e91e63;
}
.darkTheme .blueTheme .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .blueTheme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .blueTheme .mat-pseudo-checkbox::after {
  color: #303030;
}
.darkTheme .blueTheme .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.darkTheme .blueTheme .mat-primary .mat-pseudo-checkbox-checked,
.darkTheme .blueTheme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #1e4db7;
}
.darkTheme .blueTheme .mat-pseudo-checkbox-checked,
.darkTheme .blueTheme .mat-pseudo-checkbox-indeterminate,
.darkTheme .blueTheme .mat-accent .mat-pseudo-checkbox-checked,
.darkTheme .blueTheme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #1a97f5;
}
.darkTheme .blueTheme .mat-warn .mat-pseudo-checkbox-checked,
.darkTheme .blueTheme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e91e63;
}
.darkTheme .blueTheme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.darkTheme .blueTheme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.darkTheme .blueTheme .mat-app-background, .darkTheme .blueTheme.mat-app-background {
  background-color: #303030;
  color: white;
}
.darkTheme .blueTheme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.darkTheme .blueTheme .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.darkTheme .blueTheme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.darkTheme .blueTheme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.darkTheme .blueTheme .mat-badge-content {
  color: white;
  background: #1e4db7;
}
.cdk-high-contrast-active .darkTheme .blueTheme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.darkTheme .blueTheme .mat-badge-accent .mat-badge-content {
  background: #1a97f5;
  color: white;
}
.darkTheme .blueTheme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e91e63;
}
.darkTheme .blueTheme .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.darkTheme .blueTheme .mat-button, .darkTheme .blueTheme .mat-icon-button, .darkTheme .blueTheme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.darkTheme .blueTheme .mat-button.mat-primary, .darkTheme .blueTheme .mat-icon-button.mat-primary, .darkTheme .blueTheme .mat-stroked-button.mat-primary {
  color: #1e4db7;
}
.darkTheme .blueTheme .mat-button.mat-accent, .darkTheme .blueTheme .mat-icon-button.mat-accent, .darkTheme .blueTheme .mat-stroked-button.mat-accent {
  color: #1a97f5;
}
.darkTheme .blueTheme .mat-button.mat-warn, .darkTheme .blueTheme .mat-icon-button.mat-warn, .darkTheme .blueTheme .mat-stroked-button.mat-warn {
  color: #e91e63;
}
.darkTheme .blueTheme .mat-button.mat-primary.mat-button-disabled, .darkTheme .blueTheme .mat-button.mat-accent.mat-button-disabled, .darkTheme .blueTheme .mat-button.mat-warn.mat-button-disabled, .darkTheme .blueTheme .mat-button.mat-button-disabled.mat-button-disabled, .darkTheme .blueTheme .mat-icon-button.mat-primary.mat-button-disabled, .darkTheme .blueTheme .mat-icon-button.mat-accent.mat-button-disabled, .darkTheme .blueTheme .mat-icon-button.mat-warn.mat-button-disabled, .darkTheme .blueTheme .mat-icon-button.mat-button-disabled.mat-button-disabled, .darkTheme .blueTheme .mat-stroked-button.mat-primary.mat-button-disabled, .darkTheme .blueTheme .mat-stroked-button.mat-accent.mat-button-disabled, .darkTheme .blueTheme .mat-stroked-button.mat-warn.mat-button-disabled, .darkTheme .blueTheme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.darkTheme .blueTheme .mat-button.mat-primary .mat-button-focus-overlay, .darkTheme .blueTheme .mat-icon-button.mat-primary .mat-button-focus-overlay, .darkTheme .blueTheme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #1e4db7;
}
.darkTheme .blueTheme .mat-button.mat-accent .mat-button-focus-overlay, .darkTheme .blueTheme .mat-icon-button.mat-accent .mat-button-focus-overlay, .darkTheme .blueTheme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #1a97f5;
}
.darkTheme .blueTheme .mat-button.mat-warn .mat-button-focus-overlay, .darkTheme .blueTheme .mat-icon-button.mat-warn .mat-button-focus-overlay, .darkTheme .blueTheme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e91e63;
}
.darkTheme .blueTheme .mat-button.mat-button-disabled .mat-button-focus-overlay, .darkTheme .blueTheme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .darkTheme .blueTheme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.darkTheme .blueTheme .mat-button .mat-ripple-element, .darkTheme .blueTheme .mat-icon-button .mat-ripple-element, .darkTheme .blueTheme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.darkTheme .blueTheme .mat-button-focus-overlay {
  background: white;
}
.darkTheme .blueTheme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .blueTheme .mat-flat-button, .darkTheme .blueTheme .mat-raised-button, .darkTheme .blueTheme .mat-fab, .darkTheme .blueTheme .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.darkTheme .blueTheme .mat-flat-button.mat-primary, .darkTheme .blueTheme .mat-raised-button.mat-primary, .darkTheme .blueTheme .mat-fab.mat-primary, .darkTheme .blueTheme .mat-mini-fab.mat-primary {
  color: white;
}
.darkTheme .blueTheme .mat-flat-button.mat-accent, .darkTheme .blueTheme .mat-raised-button.mat-accent, .darkTheme .blueTheme .mat-fab.mat-accent, .darkTheme .blueTheme .mat-mini-fab.mat-accent {
  color: white;
}
.darkTheme .blueTheme .mat-flat-button.mat-warn, .darkTheme .blueTheme .mat-raised-button.mat-warn, .darkTheme .blueTheme .mat-fab.mat-warn, .darkTheme .blueTheme .mat-mini-fab.mat-warn {
  color: white;
}
.darkTheme .blueTheme .mat-flat-button.mat-primary.mat-button-disabled, .darkTheme .blueTheme .mat-flat-button.mat-accent.mat-button-disabled, .darkTheme .blueTheme .mat-flat-button.mat-warn.mat-button-disabled, .darkTheme .blueTheme .mat-flat-button.mat-button-disabled.mat-button-disabled, .darkTheme .blueTheme .mat-raised-button.mat-primary.mat-button-disabled, .darkTheme .blueTheme .mat-raised-button.mat-accent.mat-button-disabled, .darkTheme .blueTheme .mat-raised-button.mat-warn.mat-button-disabled, .darkTheme .blueTheme .mat-raised-button.mat-button-disabled.mat-button-disabled, .darkTheme .blueTheme .mat-fab.mat-primary.mat-button-disabled, .darkTheme .blueTheme .mat-fab.mat-accent.mat-button-disabled, .darkTheme .blueTheme .mat-fab.mat-warn.mat-button-disabled, .darkTheme .blueTheme .mat-fab.mat-button-disabled.mat-button-disabled, .darkTheme .blueTheme .mat-mini-fab.mat-primary.mat-button-disabled, .darkTheme .blueTheme .mat-mini-fab.mat-accent.mat-button-disabled, .darkTheme .blueTheme .mat-mini-fab.mat-warn.mat-button-disabled, .darkTheme .blueTheme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.darkTheme .blueTheme .mat-flat-button.mat-primary, .darkTheme .blueTheme .mat-raised-button.mat-primary, .darkTheme .blueTheme .mat-fab.mat-primary, .darkTheme .blueTheme .mat-mini-fab.mat-primary {
  background-color: #1e4db7;
}
.darkTheme .blueTheme .mat-flat-button.mat-accent, .darkTheme .blueTheme .mat-raised-button.mat-accent, .darkTheme .blueTheme .mat-fab.mat-accent, .darkTheme .blueTheme .mat-mini-fab.mat-accent {
  background-color: #1a97f5;
}
.darkTheme .blueTheme .mat-flat-button.mat-warn, .darkTheme .blueTheme .mat-raised-button.mat-warn, .darkTheme .blueTheme .mat-fab.mat-warn, .darkTheme .blueTheme .mat-mini-fab.mat-warn {
  background-color: #e91e63;
}
.darkTheme .blueTheme .mat-flat-button.mat-primary.mat-button-disabled, .darkTheme .blueTheme .mat-flat-button.mat-accent.mat-button-disabled, .darkTheme .blueTheme .mat-flat-button.mat-warn.mat-button-disabled, .darkTheme .blueTheme .mat-flat-button.mat-button-disabled.mat-button-disabled, .darkTheme .blueTheme .mat-raised-button.mat-primary.mat-button-disabled, .darkTheme .blueTheme .mat-raised-button.mat-accent.mat-button-disabled, .darkTheme .blueTheme .mat-raised-button.mat-warn.mat-button-disabled, .darkTheme .blueTheme .mat-raised-button.mat-button-disabled.mat-button-disabled, .darkTheme .blueTheme .mat-fab.mat-primary.mat-button-disabled, .darkTheme .blueTheme .mat-fab.mat-accent.mat-button-disabled, .darkTheme .blueTheme .mat-fab.mat-warn.mat-button-disabled, .darkTheme .blueTheme .mat-fab.mat-button-disabled.mat-button-disabled, .darkTheme .blueTheme .mat-mini-fab.mat-primary.mat-button-disabled, .darkTheme .blueTheme .mat-mini-fab.mat-accent.mat-button-disabled, .darkTheme .blueTheme .mat-mini-fab.mat-warn.mat-button-disabled, .darkTheme .blueTheme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .blueTheme .mat-flat-button.mat-primary .mat-ripple-element, .darkTheme .blueTheme .mat-raised-button.mat-primary .mat-ripple-element, .darkTheme .blueTheme .mat-fab.mat-primary .mat-ripple-element, .darkTheme .blueTheme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .blueTheme .mat-flat-button.mat-accent .mat-ripple-element, .darkTheme .blueTheme .mat-raised-button.mat-accent .mat-ripple-element, .darkTheme .blueTheme .mat-fab.mat-accent .mat-ripple-element, .darkTheme .blueTheme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .blueTheme .mat-flat-button.mat-warn .mat-ripple-element, .darkTheme .blueTheme .mat-raised-button.mat-warn .mat-ripple-element, .darkTheme .blueTheme .mat-fab.mat-warn .mat-ripple-element, .darkTheme .blueTheme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .blueTheme .mat-stroked-button:not([class*=mat-elevation-z]), .darkTheme .blueTheme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-fab:not([class*=mat-elevation-z]), .darkTheme .blueTheme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .darkTheme .blueTheme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .darkTheme .blueTheme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.darkTheme .blueTheme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.darkTheme .blueTheme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.darkTheme .blueTheme .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .blueTheme .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.darkTheme .blueTheme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.darkTheme .blueTheme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.darkTheme .blueTheme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.darkTheme .blueTheme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.darkTheme .blueTheme .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .blueTheme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.darkTheme .blueTheme .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.darkTheme .blueTheme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.darkTheme .blueTheme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.darkTheme .blueTheme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.darkTheme .blueTheme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.darkTheme .blueTheme .mat-card {
  background: #424242;
  color: white;
}
.darkTheme .blueTheme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .blueTheme .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .blueTheme .mat-checkbox-checkmark {
  fill: #303030;
}
.darkTheme .blueTheme .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.darkTheme .blueTheme .mat-checkbox-mixedmark {
  background-color: #303030;
}
.darkTheme .blueTheme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .darkTheme .blueTheme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #1e4db7;
}
.darkTheme .blueTheme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .darkTheme .blueTheme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #1a97f5;
}
.darkTheme .blueTheme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .darkTheme .blueTheme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e91e63;
}
.darkTheme .blueTheme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .darkTheme .blueTheme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.darkTheme .blueTheme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.darkTheme .blueTheme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.darkTheme .blueTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.darkTheme .blueTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #1e4db7;
}
.darkTheme .blueTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.darkTheme .blueTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #1a97f5;
}
.darkTheme .blueTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.darkTheme .blueTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e91e63;
}
.darkTheme .blueTheme .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.darkTheme .blueTheme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.darkTheme .blueTheme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.darkTheme .blueTheme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.darkTheme .blueTheme .mat-chip.mat-standard-chip::after {
  background: white;
}
.darkTheme .blueTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #1e4db7;
  color: white;
}
.darkTheme .blueTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.darkTheme .blueTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .blueTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e91e63;
  color: white;
}
.darkTheme .blueTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.darkTheme .blueTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .blueTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #1a97f5;
  color: white;
}
.darkTheme .blueTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.darkTheme .blueTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .blueTheme .mat-table {
  background: #424242;
}
.darkTheme .blueTheme .mat-table thead, .darkTheme .blueTheme .mat-table tbody, .darkTheme .blueTheme .mat-table tfoot,
.darkTheme .blueTheme mat-header-row, .darkTheme .blueTheme mat-row, .darkTheme .blueTheme mat-footer-row,
.darkTheme .blueTheme [mat-header-row], .darkTheme .blueTheme [mat-row], .darkTheme .blueTheme [mat-footer-row],
.darkTheme .blueTheme .mat-table-sticky {
  background: inherit;
}
.darkTheme .blueTheme mat-row, .darkTheme .blueTheme mat-header-row, .darkTheme .blueTheme mat-footer-row,
.darkTheme .blueTheme th.mat-header-cell, .darkTheme .blueTheme td.mat-cell, .darkTheme .blueTheme td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .blueTheme .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .blueTheme .mat-cell, .darkTheme .blueTheme .mat-footer-cell {
  color: white;
}
.darkTheme .blueTheme .mat-calendar-arrow {
  fill: white;
}
.darkTheme .blueTheme .mat-datepicker-toggle,
.darkTheme .blueTheme .mat-datepicker-content .mat-calendar-next-button,
.darkTheme .blueTheme .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.darkTheme .blueTheme .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.darkTheme .blueTheme .mat-calendar-table-header,
.darkTheme .blueTheme .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .blueTheme .mat-calendar-body-cell-content,
.darkTheme .blueTheme .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.darkTheme .blueTheme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.darkTheme .blueTheme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .blueTheme .mat-calendar-body-in-range::before {
  background: rgba(30, 77, 183, 0.2);
}
.darkTheme .blueTheme .mat-calendar-body-comparison-identical,
.darkTheme .blueTheme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.darkTheme .blueTheme .mat-calendar-body-comparison-bridge-start::before,
.darkTheme .blueTheme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(30, 77, 183, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .blueTheme .mat-calendar-body-comparison-bridge-end::before,
.darkTheme .blueTheme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(30, 77, 183, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .blueTheme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.darkTheme .blueTheme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.darkTheme .blueTheme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.darkTheme .blueTheme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.darkTheme .blueTheme .mat-calendar-body-selected {
  background-color: #1e4db7;
  color: white;
}
.darkTheme .blueTheme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(30, 77, 183, 0.4);
}
.darkTheme .blueTheme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.darkTheme .blueTheme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.darkTheme .blueTheme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(30, 77, 183, 0.3);
}
@media (hover: hover) {
  .darkTheme .blueTheme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(30, 77, 183, 0.3);
  }
}
.darkTheme .blueTheme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.darkTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(26, 151, 245, 0.2);
}
.darkTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.darkTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.darkTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.darkTheme .blueTheme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(26, 151, 245, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.darkTheme .blueTheme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(26, 151, 245, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.darkTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.darkTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.darkTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.darkTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #1a97f5;
  color: white;
}
.darkTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(26, 151, 245, 0.4);
}
.darkTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.darkTheme .blueTheme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.darkTheme .blueTheme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(26, 151, 245, 0.3);
}
@media (hover: hover) {
  .darkTheme .blueTheme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(26, 151, 245, 0.3);
  }
}
.darkTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(233, 30, 99, 0.2);
}
.darkTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.darkTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.darkTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.darkTheme .blueTheme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.darkTheme .blueTheme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.darkTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.darkTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.darkTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.darkTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e91e63;
  color: white;
}
.darkTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(233, 30, 99, 0.4);
}
.darkTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.darkTheme .blueTheme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.darkTheme .blueTheme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(233, 30, 99, 0.3);
}
@media (hover: hover) {
  .darkTheme .blueTheme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(233, 30, 99, 0.3);
  }
}
.darkTheme .blueTheme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-datepicker-toggle-active {
  color: #1e4db7;
}
.darkTheme .blueTheme .mat-datepicker-toggle-active.mat-accent {
  color: #1a97f5;
}
.darkTheme .blueTheme .mat-datepicker-toggle-active.mat-warn {
  color: #e91e63;
}
.darkTheme .blueTheme .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.darkTheme .blueTheme .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .blueTheme .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .blueTheme .mat-expansion-panel {
  background: #424242;
  color: white;
}
.darkTheme .blueTheme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .blueTheme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .darkTheme .blueTheme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .darkTheme .blueTheme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .darkTheme .blueTheme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.darkTheme .blueTheme .mat-expansion-panel-header-title {
  color: white;
}
.darkTheme .blueTheme .mat-expansion-panel-header-description,
.darkTheme .blueTheme .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .blueTheme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.darkTheme .blueTheme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.darkTheme .blueTheme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.darkTheme .blueTheme .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .blueTheme .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .blueTheme .mat-form-field.mat-focused .mat-form-field-label {
  color: #1e4db7;
}
.darkTheme .blueTheme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #1a97f5;
}
.darkTheme .blueTheme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e91e63;
}
.darkTheme .blueTheme .mat-focused .mat-form-field-required-marker {
  color: #1a97f5;
}
.darkTheme .blueTheme .mat-form-field-ripple {
  background-color: white;
}
.darkTheme .blueTheme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #1e4db7;
}
.darkTheme .blueTheme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #1a97f5;
}
.darkTheme .blueTheme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e91e63;
}
.darkTheme .blueTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #1e4db7;
}
.darkTheme .blueTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #1a97f5;
}
.darkTheme .blueTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e91e63;
}
.darkTheme .blueTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e91e63;
}
.darkTheme .blueTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.darkTheme .blueTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e91e63;
}
.darkTheme .blueTheme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.darkTheme .blueTheme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e91e63;
}
.darkTheme .blueTheme .mat-error {
  color: #e91e63;
}
.darkTheme .blueTheme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .blueTheme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .blueTheme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .blueTheme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.darkTheme .blueTheme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .blueTheme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.darkTheme .blueTheme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .blueTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.darkTheme .blueTheme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.darkTheme .blueTheme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.darkTheme .blueTheme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.darkTheme .blueTheme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #1e4db7;
}
.darkTheme .blueTheme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #1a97f5;
}
.darkTheme .blueTheme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e91e63;
}
.darkTheme .blueTheme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e91e63;
}
.darkTheme .blueTheme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.darkTheme .blueTheme .mat-icon.mat-primary {
  color: #1e4db7;
}
.darkTheme .blueTheme .mat-icon.mat-accent {
  color: #1a97f5;
}
.darkTheme .blueTheme .mat-icon.mat-warn {
  color: #e91e63;
}
.darkTheme .blueTheme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .blueTheme .mat-input-element:disabled,
.darkTheme .blueTheme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-input-element {
  caret-color: #1e4db7;
}
.darkTheme .blueTheme .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.darkTheme .blueTheme .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.darkTheme .blueTheme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #1a97f5;
}
.darkTheme .blueTheme .mat-form-field.mat-warn .mat-input-element,
.darkTheme .blueTheme .mat-form-field-invalid .mat-input-element {
  caret-color: #e91e63;
}
.darkTheme .blueTheme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e91e63;
}
.darkTheme .blueTheme .mat-list-base .mat-list-item {
  color: white;
}
.darkTheme .blueTheme .mat-list-base .mat-list-option {
  color: white;
}
.darkTheme .blueTheme .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .blueTheme .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-list-option:hover, .darkTheme .blueTheme .mat-list-option:focus,
.darkTheme .blueTheme .mat-nav-list .mat-list-item:hover,
.darkTheme .blueTheme .mat-nav-list .mat-list-item:focus,
.darkTheme .blueTheme .mat-action-list .mat-list-item:hover,
.darkTheme .blueTheme .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.darkTheme .blueTheme .mat-list-single-selected-option, .darkTheme .blueTheme .mat-list-single-selected-option:hover, .darkTheme .blueTheme .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.darkTheme .blueTheme .mat-menu-panel {
  background: #424242;
}
.darkTheme .blueTheme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-menu-item {
  background: transparent;
  color: white;
}
.darkTheme .blueTheme .mat-menu-item[disabled],
.darkTheme .blueTheme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.darkTheme .blueTheme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-menu-item .mat-icon-no-color,
.darkTheme .blueTheme .mat-menu-submenu-icon {
  color: white;
}
.darkTheme .blueTheme .mat-menu-item:hover:not([disabled]),
.darkTheme .blueTheme .mat-menu-item.cdk-program-focused:not([disabled]),
.darkTheme .blueTheme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.darkTheme .blueTheme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.darkTheme .blueTheme .mat-paginator {
  background: #424242;
}
.darkTheme .blueTheme .mat-paginator,
.darkTheme .blueTheme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .blueTheme .mat-paginator-decrement,
.darkTheme .blueTheme .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.darkTheme .blueTheme .mat-paginator-first,
.darkTheme .blueTheme .mat-paginator-last {
  border-top: 2px solid white;
}
.darkTheme .blueTheme .mat-icon-button[disabled] .mat-paginator-decrement,
.darkTheme .blueTheme .mat-icon-button[disabled] .mat-paginator-increment,
.darkTheme .blueTheme .mat-icon-button[disabled] .mat-paginator-first,
.darkTheme .blueTheme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-progress-bar-background {
  fill: #2c3752;
}
.darkTheme .blueTheme .mat-progress-bar-buffer {
  background-color: #2c3752;
}
.darkTheme .blueTheme .mat-progress-bar-fill::after {
  background-color: #1e4db7;
}
.darkTheme .blueTheme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #2b4a61;
}
.darkTheme .blueTheme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #2b4a61;
}
.darkTheme .blueTheme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #1a97f5;
}
.darkTheme .blueTheme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #5e2c3d;
}
.darkTheme .blueTheme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #5e2c3d;
}
.darkTheme .blueTheme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e91e63;
}
.darkTheme .blueTheme .mat-progress-spinner circle, .darkTheme .blueTheme .mat-spinner circle {
  stroke: #1e4db7;
}
.darkTheme .blueTheme .mat-progress-spinner.mat-accent circle, .darkTheme .blueTheme .mat-spinner.mat-accent circle {
  stroke: #1a97f5;
}
.darkTheme .blueTheme .mat-progress-spinner.mat-warn circle, .darkTheme .blueTheme .mat-spinner.mat-warn circle {
  stroke: #e91e63;
}
.darkTheme .blueTheme .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .blueTheme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #1e4db7;
}
.darkTheme .blueTheme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.darkTheme .blueTheme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .darkTheme .blueTheme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .darkTheme .blueTheme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #1e4db7;
}
.darkTheme .blueTheme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #1a97f5;
}
.darkTheme .blueTheme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.darkTheme .blueTheme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .darkTheme .blueTheme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .darkTheme .blueTheme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #1a97f5;
}
.darkTheme .blueTheme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e91e63;
}
.darkTheme .blueTheme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.darkTheme .blueTheme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .darkTheme .blueTheme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .darkTheme .blueTheme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e91e63;
}
.darkTheme .blueTheme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.darkTheme .blueTheme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.darkTheme .blueTheme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.darkTheme .blueTheme .mat-select-value {
  color: white;
}
.darkTheme .blueTheme .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .blueTheme .mat-select-panel {
  background: #424242;
}
.darkTheme .blueTheme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.darkTheme .blueTheme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #1e4db7;
}
.darkTheme .blueTheme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #1a97f5;
}
.darkTheme .blueTheme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e91e63;
}
.darkTheme .blueTheme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e91e63;
}
.darkTheme .blueTheme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.darkTheme .blueTheme .mat-drawer {
  background-color: #424242;
  color: white;
}
.darkTheme .blueTheme .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.darkTheme .blueTheme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.darkTheme .blueTheme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.darkTheme .blueTheme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.darkTheme .blueTheme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.darkTheme .blueTheme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.darkTheme .blueTheme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #1a97f5;
}
.darkTheme .blueTheme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(26, 151, 245, 0.54);
}
.darkTheme .blueTheme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #1a97f5;
}
.darkTheme .blueTheme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #1e4db7;
}
.darkTheme .blueTheme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(30, 77, 183, 0.54);
}
.darkTheme .blueTheme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #1e4db7;
}
.darkTheme .blueTheme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e91e63;
}
.darkTheme .blueTheme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(233, 30, 99, 0.54);
}
.darkTheme .blueTheme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e91e63;
}
.darkTheme .blueTheme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.darkTheme .blueTheme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.darkTheme .blueTheme .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .blueTheme .mat-slider.mat-primary .mat-slider-track-fill,
.darkTheme .blueTheme .mat-slider.mat-primary .mat-slider-thumb,
.darkTheme .blueTheme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #1e4db7;
}
.darkTheme .blueTheme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.darkTheme .blueTheme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(30, 77, 183, 0.2);
}
.darkTheme .blueTheme .mat-slider.mat-accent .mat-slider-track-fill,
.darkTheme .blueTheme .mat-slider.mat-accent .mat-slider-thumb,
.darkTheme .blueTheme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #1a97f5;
}
.darkTheme .blueTheme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.darkTheme .blueTheme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(26, 151, 245, 0.2);
}
.darkTheme .blueTheme .mat-slider.mat-warn .mat-slider-track-fill,
.darkTheme .blueTheme .mat-slider.mat-warn .mat-slider-thumb,
.darkTheme .blueTheme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e91e63;
}
.darkTheme .blueTheme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.darkTheme .blueTheme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(233, 30, 99, 0.2);
}
.darkTheme .blueTheme .mat-slider:hover .mat-slider-track-background,
.darkTheme .blueTheme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .blueTheme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.darkTheme .blueTheme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.darkTheme .blueTheme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .blueTheme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .blueTheme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .blueTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.darkTheme .blueTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.darkTheme .blueTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.darkTheme .blueTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .blueTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.darkTheme .blueTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .darkTheme .blueTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .blueTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .darkTheme .blueTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .blueTheme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .blueTheme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.darkTheme .blueTheme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.darkTheme .blueTheme .mat-step-header.cdk-keyboard-focused, .darkTheme .blueTheme .mat-step-header.cdk-program-focused, .darkTheme .blueTheme .mat-step-header:hover:not([aria-disabled]), .darkTheme .blueTheme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.darkTheme .blueTheme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .darkTheme .blueTheme .mat-step-header:hover {
    background: none;
  }
}
.darkTheme .blueTheme .mat-step-header .mat-step-label,
.darkTheme .blueTheme .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .blueTheme .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.darkTheme .blueTheme .mat-step-header .mat-step-icon-selected,
.darkTheme .blueTheme .mat-step-header .mat-step-icon-state-done,
.darkTheme .blueTheme .mat-step-header .mat-step-icon-state-edit {
  background-color: #1e4db7;
  color: white;
}
.darkTheme .blueTheme .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.darkTheme .blueTheme .mat-step-header.mat-accent .mat-step-icon-selected,
.darkTheme .blueTheme .mat-step-header.mat-accent .mat-step-icon-state-done,
.darkTheme .blueTheme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #1a97f5;
  color: white;
}
.darkTheme .blueTheme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.darkTheme .blueTheme .mat-step-header.mat-warn .mat-step-icon-selected,
.darkTheme .blueTheme .mat-step-header.mat-warn .mat-step-icon-state-done,
.darkTheme .blueTheme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e91e63;
  color: white;
}
.darkTheme .blueTheme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e91e63;
}
.darkTheme .blueTheme .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.darkTheme .blueTheme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e91e63;
}
.darkTheme .blueTheme .mat-stepper-horizontal, .darkTheme .blueTheme .mat-stepper-vertical {
  background-color: #424242;
}
.darkTheme .blueTheme .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .blueTheme .mat-horizontal-stepper-header::before,
.darkTheme .blueTheme .mat-horizontal-stepper-header::after,
.darkTheme .blueTheme .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .blueTheme .mat-sort-header-arrow {
  color: #c6c6c6;
}
.darkTheme .blueTheme .mat-tab-nav-bar,
.darkTheme .blueTheme .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.darkTheme .blueTheme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.darkTheme .blueTheme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.darkTheme .blueTheme .mat-tab-label, .darkTheme .blueTheme .mat-tab-link {
  color: white;
}
.darkTheme .blueTheme .mat-tab-label.mat-tab-disabled, .darkTheme .blueTheme .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-tab-header-pagination-chevron {
  border-color: white;
}
.darkTheme .blueTheme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .blueTheme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.darkTheme .blueTheme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.darkTheme .blueTheme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .blueTheme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .blueTheme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .blueTheme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .blueTheme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .blueTheme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .blueTheme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .blueTheme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(101, 145, 246, 0.3);
}
.darkTheme .blueTheme .mat-tab-group.mat-primary .mat-ink-bar, .darkTheme .blueTheme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #1e4db7;
}
.darkTheme .blueTheme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .darkTheme .blueTheme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .darkTheme .blueTheme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .darkTheme .blueTheme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.darkTheme .blueTheme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .blueTheme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .blueTheme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .blueTheme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .blueTheme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .blueTheme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .blueTheme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .blueTheme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(26, 151, 245, 0.3);
}
.darkTheme .blueTheme .mat-tab-group.mat-accent .mat-ink-bar, .darkTheme .blueTheme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #1a97f5;
}
.darkTheme .blueTheme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .darkTheme .blueTheme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .darkTheme .blueTheme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .darkTheme .blueTheme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.darkTheme .blueTheme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .blueTheme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .blueTheme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .blueTheme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .blueTheme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .blueTheme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .blueTheme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .blueTheme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.darkTheme .blueTheme .mat-tab-group.mat-warn .mat-ink-bar, .darkTheme .blueTheme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e91e63;
}
.darkTheme .blueTheme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .darkTheme .blueTheme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .darkTheme .blueTheme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .darkTheme .blueTheme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.darkTheme .blueTheme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .blueTheme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .blueTheme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .blueTheme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .blueTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .blueTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(101, 145, 246, 0.3);
}
.darkTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-header, .darkTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .darkTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #1e4db7;
}
.darkTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .darkTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.darkTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.darkTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.darkTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.darkTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.darkTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.darkTheme .blueTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.darkTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.darkTheme .blueTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.darkTheme .blueTheme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .blueTheme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .blueTheme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .blueTheme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .blueTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .blueTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(26, 151, 245, 0.3);
}
.darkTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-header, .darkTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .darkTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #1a97f5;
}
.darkTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .darkTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.darkTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.darkTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.darkTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.darkTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.darkTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.darkTheme .blueTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.darkTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.darkTheme .blueTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.darkTheme .blueTheme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .blueTheme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .blueTheme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .blueTheme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .blueTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .blueTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.darkTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-header, .darkTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .darkTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e91e63;
}
.darkTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .darkTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.darkTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.darkTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.darkTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.darkTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.darkTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.darkTheme .blueTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .darkTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.darkTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.darkTheme .blueTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.darkTheme .blueTheme .mat-toolbar {
  background: #212121;
  color: white;
}
.darkTheme .blueTheme .mat-toolbar.mat-primary {
  background: #1e4db7;
  color: white;
}
.darkTheme .blueTheme .mat-toolbar.mat-accent {
  background: #1a97f5;
  color: white;
}
.darkTheme .blueTheme .mat-toolbar.mat-warn {
  background: #e91e63;
  color: white;
}
.darkTheme .blueTheme .mat-toolbar .mat-form-field-underline,
.darkTheme .blueTheme .mat-toolbar .mat-form-field-ripple,
.darkTheme .blueTheme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.darkTheme .blueTheme .mat-toolbar .mat-form-field-label,
.darkTheme .blueTheme .mat-toolbar .mat-focused .mat-form-field-label,
.darkTheme .blueTheme .mat-toolbar .mat-select-value,
.darkTheme .blueTheme .mat-toolbar .mat-select-arrow,
.darkTheme .blueTheme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.darkTheme .blueTheme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.darkTheme .blueTheme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.darkTheme .blueTheme .mat-tree {
  background: #424242;
}
.darkTheme .blueTheme .mat-tree-node,
.darkTheme .blueTheme .mat-nested-tree-node {
  color: white;
}
.darkTheme .blueTheme .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .blueTheme .mat-simple-snackbar-action {
  color: inherit;
}
.darkTheme .greenTheme .leftsidebar .menu-list-item.activeMenu .mat-list-item-content, .darkTheme .greenTheme .leftsidebar .menu-list-item.activeMenu:hover .mat-list-item-content {
  background-color: #00cec3;
}
.darkTheme .greenTheme .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .greenTheme .mat-option {
  color: white;
}
.darkTheme .greenTheme .mat-option:hover:not(.mat-option-disabled), .darkTheme .greenTheme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.darkTheme .greenTheme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.darkTheme .greenTheme .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.darkTheme .greenTheme .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #00cec3;
}
.darkTheme .greenTheme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #066a73;
}
.darkTheme .greenTheme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e91e63;
}
.darkTheme .greenTheme .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .greenTheme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .greenTheme .mat-pseudo-checkbox::after {
  color: #303030;
}
.darkTheme .greenTheme .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.darkTheme .greenTheme .mat-primary .mat-pseudo-checkbox-checked,
.darkTheme .greenTheme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #00cec3;
}
.darkTheme .greenTheme .mat-pseudo-checkbox-checked,
.darkTheme .greenTheme .mat-pseudo-checkbox-indeterminate,
.darkTheme .greenTheme .mat-accent .mat-pseudo-checkbox-checked,
.darkTheme .greenTheme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #066a73;
}
.darkTheme .greenTheme .mat-warn .mat-pseudo-checkbox-checked,
.darkTheme .greenTheme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e91e63;
}
.darkTheme .greenTheme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.darkTheme .greenTheme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.darkTheme .greenTheme .mat-app-background, .darkTheme .greenTheme.mat-app-background {
  background-color: #303030;
  color: white;
}
.darkTheme .greenTheme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.darkTheme .greenTheme .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.darkTheme .greenTheme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.darkTheme .greenTheme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.darkTheme .greenTheme .mat-badge-content {
  color: white;
  background: #00cec3;
}
.cdk-high-contrast-active .darkTheme .greenTheme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.darkTheme .greenTheme .mat-badge-accent .mat-badge-content {
  background: #066a73;
  color: white;
}
.darkTheme .greenTheme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e91e63;
}
.darkTheme .greenTheme .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.darkTheme .greenTheme .mat-button, .darkTheme .greenTheme .mat-icon-button, .darkTheme .greenTheme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.darkTheme .greenTheme .mat-button.mat-primary, .darkTheme .greenTheme .mat-icon-button.mat-primary, .darkTheme .greenTheme .mat-stroked-button.mat-primary {
  color: #00cec3;
}
.darkTheme .greenTheme .mat-button.mat-accent, .darkTheme .greenTheme .mat-icon-button.mat-accent, .darkTheme .greenTheme .mat-stroked-button.mat-accent {
  color: #066a73;
}
.darkTheme .greenTheme .mat-button.mat-warn, .darkTheme .greenTheme .mat-icon-button.mat-warn, .darkTheme .greenTheme .mat-stroked-button.mat-warn {
  color: #e91e63;
}
.darkTheme .greenTheme .mat-button.mat-primary.mat-button-disabled, .darkTheme .greenTheme .mat-button.mat-accent.mat-button-disabled, .darkTheme .greenTheme .mat-button.mat-warn.mat-button-disabled, .darkTheme .greenTheme .mat-button.mat-button-disabled.mat-button-disabled, .darkTheme .greenTheme .mat-icon-button.mat-primary.mat-button-disabled, .darkTheme .greenTheme .mat-icon-button.mat-accent.mat-button-disabled, .darkTheme .greenTheme .mat-icon-button.mat-warn.mat-button-disabled, .darkTheme .greenTheme .mat-icon-button.mat-button-disabled.mat-button-disabled, .darkTheme .greenTheme .mat-stroked-button.mat-primary.mat-button-disabled, .darkTheme .greenTheme .mat-stroked-button.mat-accent.mat-button-disabled, .darkTheme .greenTheme .mat-stroked-button.mat-warn.mat-button-disabled, .darkTheme .greenTheme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.darkTheme .greenTheme .mat-button.mat-primary .mat-button-focus-overlay, .darkTheme .greenTheme .mat-icon-button.mat-primary .mat-button-focus-overlay, .darkTheme .greenTheme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #00cec3;
}
.darkTheme .greenTheme .mat-button.mat-accent .mat-button-focus-overlay, .darkTheme .greenTheme .mat-icon-button.mat-accent .mat-button-focus-overlay, .darkTheme .greenTheme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #066a73;
}
.darkTheme .greenTheme .mat-button.mat-warn .mat-button-focus-overlay, .darkTheme .greenTheme .mat-icon-button.mat-warn .mat-button-focus-overlay, .darkTheme .greenTheme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e91e63;
}
.darkTheme .greenTheme .mat-button.mat-button-disabled .mat-button-focus-overlay, .darkTheme .greenTheme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .darkTheme .greenTheme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.darkTheme .greenTheme .mat-button .mat-ripple-element, .darkTheme .greenTheme .mat-icon-button .mat-ripple-element, .darkTheme .greenTheme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.darkTheme .greenTheme .mat-button-focus-overlay {
  background: white;
}
.darkTheme .greenTheme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .greenTheme .mat-flat-button, .darkTheme .greenTheme .mat-raised-button, .darkTheme .greenTheme .mat-fab, .darkTheme .greenTheme .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.darkTheme .greenTheme .mat-flat-button.mat-primary, .darkTheme .greenTheme .mat-raised-button.mat-primary, .darkTheme .greenTheme .mat-fab.mat-primary, .darkTheme .greenTheme .mat-mini-fab.mat-primary {
  color: white;
}
.darkTheme .greenTheme .mat-flat-button.mat-accent, .darkTheme .greenTheme .mat-raised-button.mat-accent, .darkTheme .greenTheme .mat-fab.mat-accent, .darkTheme .greenTheme .mat-mini-fab.mat-accent {
  color: white;
}
.darkTheme .greenTheme .mat-flat-button.mat-warn, .darkTheme .greenTheme .mat-raised-button.mat-warn, .darkTheme .greenTheme .mat-fab.mat-warn, .darkTheme .greenTheme .mat-mini-fab.mat-warn {
  color: white;
}
.darkTheme .greenTheme .mat-flat-button.mat-primary.mat-button-disabled, .darkTheme .greenTheme .mat-flat-button.mat-accent.mat-button-disabled, .darkTheme .greenTheme .mat-flat-button.mat-warn.mat-button-disabled, .darkTheme .greenTheme .mat-flat-button.mat-button-disabled.mat-button-disabled, .darkTheme .greenTheme .mat-raised-button.mat-primary.mat-button-disabled, .darkTheme .greenTheme .mat-raised-button.mat-accent.mat-button-disabled, .darkTheme .greenTheme .mat-raised-button.mat-warn.mat-button-disabled, .darkTheme .greenTheme .mat-raised-button.mat-button-disabled.mat-button-disabled, .darkTheme .greenTheme .mat-fab.mat-primary.mat-button-disabled, .darkTheme .greenTheme .mat-fab.mat-accent.mat-button-disabled, .darkTheme .greenTheme .mat-fab.mat-warn.mat-button-disabled, .darkTheme .greenTheme .mat-fab.mat-button-disabled.mat-button-disabled, .darkTheme .greenTheme .mat-mini-fab.mat-primary.mat-button-disabled, .darkTheme .greenTheme .mat-mini-fab.mat-accent.mat-button-disabled, .darkTheme .greenTheme .mat-mini-fab.mat-warn.mat-button-disabled, .darkTheme .greenTheme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.darkTheme .greenTheme .mat-flat-button.mat-primary, .darkTheme .greenTheme .mat-raised-button.mat-primary, .darkTheme .greenTheme .mat-fab.mat-primary, .darkTheme .greenTheme .mat-mini-fab.mat-primary {
  background-color: #00cec3;
}
.darkTheme .greenTheme .mat-flat-button.mat-accent, .darkTheme .greenTheme .mat-raised-button.mat-accent, .darkTheme .greenTheme .mat-fab.mat-accent, .darkTheme .greenTheme .mat-mini-fab.mat-accent {
  background-color: #066a73;
}
.darkTheme .greenTheme .mat-flat-button.mat-warn, .darkTheme .greenTheme .mat-raised-button.mat-warn, .darkTheme .greenTheme .mat-fab.mat-warn, .darkTheme .greenTheme .mat-mini-fab.mat-warn {
  background-color: #e91e63;
}
.darkTheme .greenTheme .mat-flat-button.mat-primary.mat-button-disabled, .darkTheme .greenTheme .mat-flat-button.mat-accent.mat-button-disabled, .darkTheme .greenTheme .mat-flat-button.mat-warn.mat-button-disabled, .darkTheme .greenTheme .mat-flat-button.mat-button-disabled.mat-button-disabled, .darkTheme .greenTheme .mat-raised-button.mat-primary.mat-button-disabled, .darkTheme .greenTheme .mat-raised-button.mat-accent.mat-button-disabled, .darkTheme .greenTheme .mat-raised-button.mat-warn.mat-button-disabled, .darkTheme .greenTheme .mat-raised-button.mat-button-disabled.mat-button-disabled, .darkTheme .greenTheme .mat-fab.mat-primary.mat-button-disabled, .darkTheme .greenTheme .mat-fab.mat-accent.mat-button-disabled, .darkTheme .greenTheme .mat-fab.mat-warn.mat-button-disabled, .darkTheme .greenTheme .mat-fab.mat-button-disabled.mat-button-disabled, .darkTheme .greenTheme .mat-mini-fab.mat-primary.mat-button-disabled, .darkTheme .greenTheme .mat-mini-fab.mat-accent.mat-button-disabled, .darkTheme .greenTheme .mat-mini-fab.mat-warn.mat-button-disabled, .darkTheme .greenTheme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .greenTheme .mat-flat-button.mat-primary .mat-ripple-element, .darkTheme .greenTheme .mat-raised-button.mat-primary .mat-ripple-element, .darkTheme .greenTheme .mat-fab.mat-primary .mat-ripple-element, .darkTheme .greenTheme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .greenTheme .mat-flat-button.mat-accent .mat-ripple-element, .darkTheme .greenTheme .mat-raised-button.mat-accent .mat-ripple-element, .darkTheme .greenTheme .mat-fab.mat-accent .mat-ripple-element, .darkTheme .greenTheme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .greenTheme .mat-flat-button.mat-warn .mat-ripple-element, .darkTheme .greenTheme .mat-raised-button.mat-warn .mat-ripple-element, .darkTheme .greenTheme .mat-fab.mat-warn .mat-ripple-element, .darkTheme .greenTheme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .greenTheme .mat-stroked-button:not([class*=mat-elevation-z]), .darkTheme .greenTheme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-fab:not([class*=mat-elevation-z]), .darkTheme .greenTheme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .darkTheme .greenTheme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .darkTheme .greenTheme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.darkTheme .greenTheme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.darkTheme .greenTheme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.darkTheme .greenTheme .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .greenTheme .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.darkTheme .greenTheme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.darkTheme .greenTheme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.darkTheme .greenTheme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.darkTheme .greenTheme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.darkTheme .greenTheme .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .greenTheme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.darkTheme .greenTheme .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.darkTheme .greenTheme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.darkTheme .greenTheme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.darkTheme .greenTheme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.darkTheme .greenTheme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.darkTheme .greenTheme .mat-card {
  background: #424242;
  color: white;
}
.darkTheme .greenTheme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .greenTheme .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .greenTheme .mat-checkbox-checkmark {
  fill: #303030;
}
.darkTheme .greenTheme .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.darkTheme .greenTheme .mat-checkbox-mixedmark {
  background-color: #303030;
}
.darkTheme .greenTheme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .darkTheme .greenTheme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #00cec3;
}
.darkTheme .greenTheme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .darkTheme .greenTheme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #066a73;
}
.darkTheme .greenTheme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .darkTheme .greenTheme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e91e63;
}
.darkTheme .greenTheme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .darkTheme .greenTheme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.darkTheme .greenTheme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.darkTheme .greenTheme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.darkTheme .greenTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.darkTheme .greenTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #00cec3;
}
.darkTheme .greenTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.darkTheme .greenTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #066a73;
}
.darkTheme .greenTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.darkTheme .greenTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e91e63;
}
.darkTheme .greenTheme .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.darkTheme .greenTheme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.darkTheme .greenTheme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.darkTheme .greenTheme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.darkTheme .greenTheme .mat-chip.mat-standard-chip::after {
  background: white;
}
.darkTheme .greenTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #00cec3;
  color: white;
}
.darkTheme .greenTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.darkTheme .greenTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .greenTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e91e63;
  color: white;
}
.darkTheme .greenTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.darkTheme .greenTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .greenTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #066a73;
  color: white;
}
.darkTheme .greenTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.darkTheme .greenTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .greenTheme .mat-table {
  background: #424242;
}
.darkTheme .greenTheme .mat-table thead, .darkTheme .greenTheme .mat-table tbody, .darkTheme .greenTheme .mat-table tfoot,
.darkTheme .greenTheme mat-header-row, .darkTheme .greenTheme mat-row, .darkTheme .greenTheme mat-footer-row,
.darkTheme .greenTheme [mat-header-row], .darkTheme .greenTheme [mat-row], .darkTheme .greenTheme [mat-footer-row],
.darkTheme .greenTheme .mat-table-sticky {
  background: inherit;
}
.darkTheme .greenTheme mat-row, .darkTheme .greenTheme mat-header-row, .darkTheme .greenTheme mat-footer-row,
.darkTheme .greenTheme th.mat-header-cell, .darkTheme .greenTheme td.mat-cell, .darkTheme .greenTheme td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .greenTheme .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .greenTheme .mat-cell, .darkTheme .greenTheme .mat-footer-cell {
  color: white;
}
.darkTheme .greenTheme .mat-calendar-arrow {
  fill: white;
}
.darkTheme .greenTheme .mat-datepicker-toggle,
.darkTheme .greenTheme .mat-datepicker-content .mat-calendar-next-button,
.darkTheme .greenTheme .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.darkTheme .greenTheme .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.darkTheme .greenTheme .mat-calendar-table-header,
.darkTheme .greenTheme .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .greenTheme .mat-calendar-body-cell-content,
.darkTheme .greenTheme .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.darkTheme .greenTheme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.darkTheme .greenTheme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .greenTheme .mat-calendar-body-in-range::before {
  background: rgba(0, 206, 195, 0.2);
}
.darkTheme .greenTheme .mat-calendar-body-comparison-identical,
.darkTheme .greenTheme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.darkTheme .greenTheme .mat-calendar-body-comparison-bridge-start::before,
.darkTheme .greenTheme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 206, 195, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .greenTheme .mat-calendar-body-comparison-bridge-end::before,
.darkTheme .greenTheme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 206, 195, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .greenTheme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.darkTheme .greenTheme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.darkTheme .greenTheme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.darkTheme .greenTheme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.darkTheme .greenTheme .mat-calendar-body-selected {
  background-color: #00cec3;
  color: white;
}
.darkTheme .greenTheme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 206, 195, 0.4);
}
.darkTheme .greenTheme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.darkTheme .greenTheme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.darkTheme .greenTheme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 206, 195, 0.3);
}
@media (hover: hover) {
  .darkTheme .greenTheme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 206, 195, 0.3);
  }
}
.darkTheme .greenTheme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.darkTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(6, 106, 115, 0.2);
}
.darkTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.darkTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.darkTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.darkTheme .greenTheme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(6, 106, 115, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.darkTheme .greenTheme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(6, 106, 115, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.darkTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.darkTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.darkTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.darkTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #066a73;
  color: white;
}
.darkTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(6, 106, 115, 0.4);
}
.darkTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.darkTheme .greenTheme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.darkTheme .greenTheme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(6, 106, 115, 0.3);
}
@media (hover: hover) {
  .darkTheme .greenTheme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(6, 106, 115, 0.3);
  }
}
.darkTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(233, 30, 99, 0.2);
}
.darkTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.darkTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.darkTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.darkTheme .greenTheme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.darkTheme .greenTheme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.darkTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.darkTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.darkTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.darkTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e91e63;
  color: white;
}
.darkTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(233, 30, 99, 0.4);
}
.darkTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.darkTheme .greenTheme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.darkTheme .greenTheme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(233, 30, 99, 0.3);
}
@media (hover: hover) {
  .darkTheme .greenTheme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(233, 30, 99, 0.3);
  }
}
.darkTheme .greenTheme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-datepicker-toggle-active {
  color: #00cec3;
}
.darkTheme .greenTheme .mat-datepicker-toggle-active.mat-accent {
  color: #066a73;
}
.darkTheme .greenTheme .mat-datepicker-toggle-active.mat-warn {
  color: #e91e63;
}
.darkTheme .greenTheme .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.darkTheme .greenTheme .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .greenTheme .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .greenTheme .mat-expansion-panel {
  background: #424242;
  color: white;
}
.darkTheme .greenTheme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .greenTheme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .darkTheme .greenTheme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .darkTheme .greenTheme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .darkTheme .greenTheme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.darkTheme .greenTheme .mat-expansion-panel-header-title {
  color: white;
}
.darkTheme .greenTheme .mat-expansion-panel-header-description,
.darkTheme .greenTheme .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .greenTheme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.darkTheme .greenTheme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.darkTheme .greenTheme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.darkTheme .greenTheme .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .greenTheme .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .greenTheme .mat-form-field.mat-focused .mat-form-field-label {
  color: #00cec3;
}
.darkTheme .greenTheme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #066a73;
}
.darkTheme .greenTheme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e91e63;
}
.darkTheme .greenTheme .mat-focused .mat-form-field-required-marker {
  color: #066a73;
}
.darkTheme .greenTheme .mat-form-field-ripple {
  background-color: white;
}
.darkTheme .greenTheme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #00cec3;
}
.darkTheme .greenTheme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #066a73;
}
.darkTheme .greenTheme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e91e63;
}
.darkTheme .greenTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #00cec3;
}
.darkTheme .greenTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #066a73;
}
.darkTheme .greenTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e91e63;
}
.darkTheme .greenTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e91e63;
}
.darkTheme .greenTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.darkTheme .greenTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e91e63;
}
.darkTheme .greenTheme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.darkTheme .greenTheme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e91e63;
}
.darkTheme .greenTheme .mat-error {
  color: #e91e63;
}
.darkTheme .greenTheme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .greenTheme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .greenTheme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .greenTheme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.darkTheme .greenTheme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .greenTheme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.darkTheme .greenTheme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .greenTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.darkTheme .greenTheme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.darkTheme .greenTheme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.darkTheme .greenTheme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.darkTheme .greenTheme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #00cec3;
}
.darkTheme .greenTheme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #066a73;
}
.darkTheme .greenTheme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e91e63;
}
.darkTheme .greenTheme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e91e63;
}
.darkTheme .greenTheme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.darkTheme .greenTheme .mat-icon.mat-primary {
  color: #00cec3;
}
.darkTheme .greenTheme .mat-icon.mat-accent {
  color: #066a73;
}
.darkTheme .greenTheme .mat-icon.mat-warn {
  color: #e91e63;
}
.darkTheme .greenTheme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .greenTheme .mat-input-element:disabled,
.darkTheme .greenTheme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-input-element {
  caret-color: #00cec3;
}
.darkTheme .greenTheme .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.darkTheme .greenTheme .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.darkTheme .greenTheme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #066a73;
}
.darkTheme .greenTheme .mat-form-field.mat-warn .mat-input-element,
.darkTheme .greenTheme .mat-form-field-invalid .mat-input-element {
  caret-color: #e91e63;
}
.darkTheme .greenTheme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e91e63;
}
.darkTheme .greenTheme .mat-list-base .mat-list-item {
  color: white;
}
.darkTheme .greenTheme .mat-list-base .mat-list-option {
  color: white;
}
.darkTheme .greenTheme .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .greenTheme .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-list-option:hover, .darkTheme .greenTheme .mat-list-option:focus,
.darkTheme .greenTheme .mat-nav-list .mat-list-item:hover,
.darkTheme .greenTheme .mat-nav-list .mat-list-item:focus,
.darkTheme .greenTheme .mat-action-list .mat-list-item:hover,
.darkTheme .greenTheme .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.darkTheme .greenTheme .mat-list-single-selected-option, .darkTheme .greenTheme .mat-list-single-selected-option:hover, .darkTheme .greenTheme .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.darkTheme .greenTheme .mat-menu-panel {
  background: #424242;
}
.darkTheme .greenTheme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-menu-item {
  background: transparent;
  color: white;
}
.darkTheme .greenTheme .mat-menu-item[disabled],
.darkTheme .greenTheme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.darkTheme .greenTheme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-menu-item .mat-icon-no-color,
.darkTheme .greenTheme .mat-menu-submenu-icon {
  color: white;
}
.darkTheme .greenTheme .mat-menu-item:hover:not([disabled]),
.darkTheme .greenTheme .mat-menu-item.cdk-program-focused:not([disabled]),
.darkTheme .greenTheme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.darkTheme .greenTheme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.darkTheme .greenTheme .mat-paginator {
  background: #424242;
}
.darkTheme .greenTheme .mat-paginator,
.darkTheme .greenTheme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .greenTheme .mat-paginator-decrement,
.darkTheme .greenTheme .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.darkTheme .greenTheme .mat-paginator-first,
.darkTheme .greenTheme .mat-paginator-last {
  border-top: 2px solid white;
}
.darkTheme .greenTheme .mat-icon-button[disabled] .mat-paginator-decrement,
.darkTheme .greenTheme .mat-icon-button[disabled] .mat-paginator-increment,
.darkTheme .greenTheme .mat-icon-button[disabled] .mat-paginator-first,
.darkTheme .greenTheme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-progress-bar-background {
  fill: #245855;
}
.darkTheme .greenTheme .mat-progress-bar-buffer {
  background-color: #245855;
}
.darkTheme .greenTheme .mat-progress-bar-fill::after {
  background-color: #00cec3;
}
.darkTheme .greenTheme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #263f41;
}
.darkTheme .greenTheme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #263f41;
}
.darkTheme .greenTheme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #066a73;
}
.darkTheme .greenTheme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #5e2c3d;
}
.darkTheme .greenTheme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #5e2c3d;
}
.darkTheme .greenTheme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e91e63;
}
.darkTheme .greenTheme .mat-progress-spinner circle, .darkTheme .greenTheme .mat-spinner circle {
  stroke: #00cec3;
}
.darkTheme .greenTheme .mat-progress-spinner.mat-accent circle, .darkTheme .greenTheme .mat-spinner.mat-accent circle {
  stroke: #066a73;
}
.darkTheme .greenTheme .mat-progress-spinner.mat-warn circle, .darkTheme .greenTheme .mat-spinner.mat-warn circle {
  stroke: #e91e63;
}
.darkTheme .greenTheme .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .greenTheme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #00cec3;
}
.darkTheme .greenTheme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.darkTheme .greenTheme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .darkTheme .greenTheme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .darkTheme .greenTheme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #00cec3;
}
.darkTheme .greenTheme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #066a73;
}
.darkTheme .greenTheme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.darkTheme .greenTheme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .darkTheme .greenTheme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .darkTheme .greenTheme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #066a73;
}
.darkTheme .greenTheme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e91e63;
}
.darkTheme .greenTheme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.darkTheme .greenTheme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .darkTheme .greenTheme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .darkTheme .greenTheme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e91e63;
}
.darkTheme .greenTheme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.darkTheme .greenTheme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.darkTheme .greenTheme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.darkTheme .greenTheme .mat-select-value {
  color: white;
}
.darkTheme .greenTheme .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .greenTheme .mat-select-panel {
  background: #424242;
}
.darkTheme .greenTheme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.darkTheme .greenTheme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #00cec3;
}
.darkTheme .greenTheme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #066a73;
}
.darkTheme .greenTheme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e91e63;
}
.darkTheme .greenTheme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e91e63;
}
.darkTheme .greenTheme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.darkTheme .greenTheme .mat-drawer {
  background-color: #424242;
  color: white;
}
.darkTheme .greenTheme .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.darkTheme .greenTheme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.darkTheme .greenTheme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.darkTheme .greenTheme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.darkTheme .greenTheme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.darkTheme .greenTheme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.darkTheme .greenTheme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #066a73;
}
.darkTheme .greenTheme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(6, 106, 115, 0.54);
}
.darkTheme .greenTheme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #066a73;
}
.darkTheme .greenTheme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #00cec3;
}
.darkTheme .greenTheme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 206, 195, 0.54);
}
.darkTheme .greenTheme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #00cec3;
}
.darkTheme .greenTheme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e91e63;
}
.darkTheme .greenTheme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(233, 30, 99, 0.54);
}
.darkTheme .greenTheme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e91e63;
}
.darkTheme .greenTheme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.darkTheme .greenTheme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.darkTheme .greenTheme .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .greenTheme .mat-slider.mat-primary .mat-slider-track-fill,
.darkTheme .greenTheme .mat-slider.mat-primary .mat-slider-thumb,
.darkTheme .greenTheme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #00cec3;
}
.darkTheme .greenTheme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.darkTheme .greenTheme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 206, 195, 0.2);
}
.darkTheme .greenTheme .mat-slider.mat-accent .mat-slider-track-fill,
.darkTheme .greenTheme .mat-slider.mat-accent .mat-slider-thumb,
.darkTheme .greenTheme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #066a73;
}
.darkTheme .greenTheme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.darkTheme .greenTheme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(6, 106, 115, 0.2);
}
.darkTheme .greenTheme .mat-slider.mat-warn .mat-slider-track-fill,
.darkTheme .greenTheme .mat-slider.mat-warn .mat-slider-thumb,
.darkTheme .greenTheme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e91e63;
}
.darkTheme .greenTheme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.darkTheme .greenTheme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(233, 30, 99, 0.2);
}
.darkTheme .greenTheme .mat-slider:hover .mat-slider-track-background,
.darkTheme .greenTheme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .greenTheme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.darkTheme .greenTheme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.darkTheme .greenTheme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .greenTheme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .greenTheme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .greenTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.darkTheme .greenTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.darkTheme .greenTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.darkTheme .greenTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .greenTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.darkTheme .greenTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .darkTheme .greenTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .greenTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .darkTheme .greenTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .greenTheme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .greenTheme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.darkTheme .greenTheme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.darkTheme .greenTheme .mat-step-header.cdk-keyboard-focused, .darkTheme .greenTheme .mat-step-header.cdk-program-focused, .darkTheme .greenTheme .mat-step-header:hover:not([aria-disabled]), .darkTheme .greenTheme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.darkTheme .greenTheme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .darkTheme .greenTheme .mat-step-header:hover {
    background: none;
  }
}
.darkTheme .greenTheme .mat-step-header .mat-step-label,
.darkTheme .greenTheme .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .greenTheme .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.darkTheme .greenTheme .mat-step-header .mat-step-icon-selected,
.darkTheme .greenTheme .mat-step-header .mat-step-icon-state-done,
.darkTheme .greenTheme .mat-step-header .mat-step-icon-state-edit {
  background-color: #00cec3;
  color: white;
}
.darkTheme .greenTheme .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.darkTheme .greenTheme .mat-step-header.mat-accent .mat-step-icon-selected,
.darkTheme .greenTheme .mat-step-header.mat-accent .mat-step-icon-state-done,
.darkTheme .greenTheme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #066a73;
  color: white;
}
.darkTheme .greenTheme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.darkTheme .greenTheme .mat-step-header.mat-warn .mat-step-icon-selected,
.darkTheme .greenTheme .mat-step-header.mat-warn .mat-step-icon-state-done,
.darkTheme .greenTheme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e91e63;
  color: white;
}
.darkTheme .greenTheme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e91e63;
}
.darkTheme .greenTheme .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.darkTheme .greenTheme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e91e63;
}
.darkTheme .greenTheme .mat-stepper-horizontal, .darkTheme .greenTheme .mat-stepper-vertical {
  background-color: #424242;
}
.darkTheme .greenTheme .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .greenTheme .mat-horizontal-stepper-header::before,
.darkTheme .greenTheme .mat-horizontal-stepper-header::after,
.darkTheme .greenTheme .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .greenTheme .mat-sort-header-arrow {
  color: #c6c6c6;
}
.darkTheme .greenTheme .mat-tab-nav-bar,
.darkTheme .greenTheme .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.darkTheme .greenTheme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.darkTheme .greenTheme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.darkTheme .greenTheme .mat-tab-label, .darkTheme .greenTheme .mat-tab-link {
  color: white;
}
.darkTheme .greenTheme .mat-tab-label.mat-tab-disabled, .darkTheme .greenTheme .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-tab-header-pagination-chevron {
  border-color: white;
}
.darkTheme .greenTheme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .greenTheme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.darkTheme .greenTheme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.darkTheme .greenTheme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .greenTheme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .greenTheme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .greenTheme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .greenTheme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .greenTheme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .greenTheme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .greenTheme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 206, 195, 0.3);
}
.darkTheme .greenTheme .mat-tab-group.mat-primary .mat-ink-bar, .darkTheme .greenTheme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #00cec3;
}
.darkTheme .greenTheme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .darkTheme .greenTheme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .darkTheme .greenTheme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .darkTheme .greenTheme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.darkTheme .greenTheme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .greenTheme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .greenTheme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .greenTheme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .greenTheme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .greenTheme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .greenTheme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .greenTheme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(6, 106, 115, 0.3);
}
.darkTheme .greenTheme .mat-tab-group.mat-accent .mat-ink-bar, .darkTheme .greenTheme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #066a73;
}
.darkTheme .greenTheme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .darkTheme .greenTheme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .darkTheme .greenTheme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .darkTheme .greenTheme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.darkTheme .greenTheme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .greenTheme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .greenTheme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .greenTheme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .greenTheme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .greenTheme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .greenTheme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .greenTheme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.darkTheme .greenTheme .mat-tab-group.mat-warn .mat-ink-bar, .darkTheme .greenTheme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e91e63;
}
.darkTheme .greenTheme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .darkTheme .greenTheme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .darkTheme .greenTheme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .darkTheme .greenTheme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.darkTheme .greenTheme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .greenTheme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .greenTheme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .greenTheme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .greenTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .greenTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 206, 195, 0.3);
}
.darkTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-header, .darkTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .darkTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #00cec3;
}
.darkTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .darkTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.darkTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.darkTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.darkTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.darkTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.darkTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.darkTheme .greenTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.darkTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.darkTheme .greenTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.darkTheme .greenTheme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .greenTheme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .greenTheme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .greenTheme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .greenTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .greenTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(6, 106, 115, 0.3);
}
.darkTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-header, .darkTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .darkTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #066a73;
}
.darkTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .darkTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.darkTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.darkTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.darkTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.darkTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.darkTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.darkTheme .greenTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.darkTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.darkTheme .greenTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.darkTheme .greenTheme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .greenTheme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .greenTheme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .greenTheme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .greenTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .greenTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.darkTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-header, .darkTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .darkTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e91e63;
}
.darkTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .darkTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.darkTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.darkTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.darkTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.darkTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.darkTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.darkTheme .greenTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .darkTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.darkTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.darkTheme .greenTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.darkTheme .greenTheme .mat-toolbar {
  background: #212121;
  color: white;
}
.darkTheme .greenTheme .mat-toolbar.mat-primary {
  background: #00cec3;
  color: white;
}
.darkTheme .greenTheme .mat-toolbar.mat-accent {
  background: #066a73;
  color: white;
}
.darkTheme .greenTheme .mat-toolbar.mat-warn {
  background: #e91e63;
  color: white;
}
.darkTheme .greenTheme .mat-toolbar .mat-form-field-underline,
.darkTheme .greenTheme .mat-toolbar .mat-form-field-ripple,
.darkTheme .greenTheme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.darkTheme .greenTheme .mat-toolbar .mat-form-field-label,
.darkTheme .greenTheme .mat-toolbar .mat-focused .mat-form-field-label,
.darkTheme .greenTheme .mat-toolbar .mat-select-value,
.darkTheme .greenTheme .mat-toolbar .mat-select-arrow,
.darkTheme .greenTheme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.darkTheme .greenTheme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.darkTheme .greenTheme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.darkTheme .greenTheme .mat-tree {
  background: #424242;
}
.darkTheme .greenTheme .mat-tree-node,
.darkTheme .greenTheme .mat-nested-tree-node {
  color: white;
}
.darkTheme .greenTheme .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .greenTheme .mat-simple-snackbar-action {
  color: inherit;
}
.darkTheme .purpleTheme .leftsidebar .menu-list-item.activeMenu .mat-list-item-content, .darkTheme .purpleTheme .leftsidebar .menu-list-item.activeMenu:hover .mat-list-item-content {
  background-color: #7352ff;
}
.darkTheme .purpleTheme .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .purpleTheme .mat-option {
  color: white;
}
.darkTheme .purpleTheme .mat-option:hover:not(.mat-option-disabled), .darkTheme .purpleTheme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.darkTheme .purpleTheme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.darkTheme .purpleTheme .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.darkTheme .purpleTheme .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #7352ff;
}
.darkTheme .purpleTheme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #402e8d;
}
.darkTheme .purpleTheme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e91e63;
}
.darkTheme .purpleTheme .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .purpleTheme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .purpleTheme .mat-pseudo-checkbox::after {
  color: #303030;
}
.darkTheme .purpleTheme .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.darkTheme .purpleTheme .mat-primary .mat-pseudo-checkbox-checked,
.darkTheme .purpleTheme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #7352ff;
}
.darkTheme .purpleTheme .mat-pseudo-checkbox-checked,
.darkTheme .purpleTheme .mat-pseudo-checkbox-indeterminate,
.darkTheme .purpleTheme .mat-accent .mat-pseudo-checkbox-checked,
.darkTheme .purpleTheme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #402e8d;
}
.darkTheme .purpleTheme .mat-warn .mat-pseudo-checkbox-checked,
.darkTheme .purpleTheme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e91e63;
}
.darkTheme .purpleTheme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.darkTheme .purpleTheme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.darkTheme .purpleTheme .mat-app-background, .darkTheme .purpleTheme.mat-app-background {
  background-color: #303030;
  color: white;
}
.darkTheme .purpleTheme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.darkTheme .purpleTheme .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.darkTheme .purpleTheme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.darkTheme .purpleTheme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.darkTheme .purpleTheme .mat-badge-content {
  color: white;
  background: #7352ff;
}
.cdk-high-contrast-active .darkTheme .purpleTheme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.darkTheme .purpleTheme .mat-badge-accent .mat-badge-content {
  background: #402e8d;
  color: white;
}
.darkTheme .purpleTheme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e91e63;
}
.darkTheme .purpleTheme .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.darkTheme .purpleTheme .mat-button, .darkTheme .purpleTheme .mat-icon-button, .darkTheme .purpleTheme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.darkTheme .purpleTheme .mat-button.mat-primary, .darkTheme .purpleTheme .mat-icon-button.mat-primary, .darkTheme .purpleTheme .mat-stroked-button.mat-primary {
  color: #7352ff;
}
.darkTheme .purpleTheme .mat-button.mat-accent, .darkTheme .purpleTheme .mat-icon-button.mat-accent, .darkTheme .purpleTheme .mat-stroked-button.mat-accent {
  color: #402e8d;
}
.darkTheme .purpleTheme .mat-button.mat-warn, .darkTheme .purpleTheme .mat-icon-button.mat-warn, .darkTheme .purpleTheme .mat-stroked-button.mat-warn {
  color: #e91e63;
}
.darkTheme .purpleTheme .mat-button.mat-primary.mat-button-disabled, .darkTheme .purpleTheme .mat-button.mat-accent.mat-button-disabled, .darkTheme .purpleTheme .mat-button.mat-warn.mat-button-disabled, .darkTheme .purpleTheme .mat-button.mat-button-disabled.mat-button-disabled, .darkTheme .purpleTheme .mat-icon-button.mat-primary.mat-button-disabled, .darkTheme .purpleTheme .mat-icon-button.mat-accent.mat-button-disabled, .darkTheme .purpleTheme .mat-icon-button.mat-warn.mat-button-disabled, .darkTheme .purpleTheme .mat-icon-button.mat-button-disabled.mat-button-disabled, .darkTheme .purpleTheme .mat-stroked-button.mat-primary.mat-button-disabled, .darkTheme .purpleTheme .mat-stroked-button.mat-accent.mat-button-disabled, .darkTheme .purpleTheme .mat-stroked-button.mat-warn.mat-button-disabled, .darkTheme .purpleTheme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.darkTheme .purpleTheme .mat-button.mat-primary .mat-button-focus-overlay, .darkTheme .purpleTheme .mat-icon-button.mat-primary .mat-button-focus-overlay, .darkTheme .purpleTheme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #7352ff;
}
.darkTheme .purpleTheme .mat-button.mat-accent .mat-button-focus-overlay, .darkTheme .purpleTheme .mat-icon-button.mat-accent .mat-button-focus-overlay, .darkTheme .purpleTheme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #402e8d;
}
.darkTheme .purpleTheme .mat-button.mat-warn .mat-button-focus-overlay, .darkTheme .purpleTheme .mat-icon-button.mat-warn .mat-button-focus-overlay, .darkTheme .purpleTheme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e91e63;
}
.darkTheme .purpleTheme .mat-button.mat-button-disabled .mat-button-focus-overlay, .darkTheme .purpleTheme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .darkTheme .purpleTheme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.darkTheme .purpleTheme .mat-button .mat-ripple-element, .darkTheme .purpleTheme .mat-icon-button .mat-ripple-element, .darkTheme .purpleTheme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.darkTheme .purpleTheme .mat-button-focus-overlay {
  background: white;
}
.darkTheme .purpleTheme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .purpleTheme .mat-flat-button, .darkTheme .purpleTheme .mat-raised-button, .darkTheme .purpleTheme .mat-fab, .darkTheme .purpleTheme .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.darkTheme .purpleTheme .mat-flat-button.mat-primary, .darkTheme .purpleTheme .mat-raised-button.mat-primary, .darkTheme .purpleTheme .mat-fab.mat-primary, .darkTheme .purpleTheme .mat-mini-fab.mat-primary {
  color: white;
}
.darkTheme .purpleTheme .mat-flat-button.mat-accent, .darkTheme .purpleTheme .mat-raised-button.mat-accent, .darkTheme .purpleTheme .mat-fab.mat-accent, .darkTheme .purpleTheme .mat-mini-fab.mat-accent {
  color: white;
}
.darkTheme .purpleTheme .mat-flat-button.mat-warn, .darkTheme .purpleTheme .mat-raised-button.mat-warn, .darkTheme .purpleTheme .mat-fab.mat-warn, .darkTheme .purpleTheme .mat-mini-fab.mat-warn {
  color: white;
}
.darkTheme .purpleTheme .mat-flat-button.mat-primary.mat-button-disabled, .darkTheme .purpleTheme .mat-flat-button.mat-accent.mat-button-disabled, .darkTheme .purpleTheme .mat-flat-button.mat-warn.mat-button-disabled, .darkTheme .purpleTheme .mat-flat-button.mat-button-disabled.mat-button-disabled, .darkTheme .purpleTheme .mat-raised-button.mat-primary.mat-button-disabled, .darkTheme .purpleTheme .mat-raised-button.mat-accent.mat-button-disabled, .darkTheme .purpleTheme .mat-raised-button.mat-warn.mat-button-disabled, .darkTheme .purpleTheme .mat-raised-button.mat-button-disabled.mat-button-disabled, .darkTheme .purpleTheme .mat-fab.mat-primary.mat-button-disabled, .darkTheme .purpleTheme .mat-fab.mat-accent.mat-button-disabled, .darkTheme .purpleTheme .mat-fab.mat-warn.mat-button-disabled, .darkTheme .purpleTheme .mat-fab.mat-button-disabled.mat-button-disabled, .darkTheme .purpleTheme .mat-mini-fab.mat-primary.mat-button-disabled, .darkTheme .purpleTheme .mat-mini-fab.mat-accent.mat-button-disabled, .darkTheme .purpleTheme .mat-mini-fab.mat-warn.mat-button-disabled, .darkTheme .purpleTheme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.darkTheme .purpleTheme .mat-flat-button.mat-primary, .darkTheme .purpleTheme .mat-raised-button.mat-primary, .darkTheme .purpleTheme .mat-fab.mat-primary, .darkTheme .purpleTheme .mat-mini-fab.mat-primary {
  background-color: #7352ff;
}
.darkTheme .purpleTheme .mat-flat-button.mat-accent, .darkTheme .purpleTheme .mat-raised-button.mat-accent, .darkTheme .purpleTheme .mat-fab.mat-accent, .darkTheme .purpleTheme .mat-mini-fab.mat-accent {
  background-color: #402e8d;
}
.darkTheme .purpleTheme .mat-flat-button.mat-warn, .darkTheme .purpleTheme .mat-raised-button.mat-warn, .darkTheme .purpleTheme .mat-fab.mat-warn, .darkTheme .purpleTheme .mat-mini-fab.mat-warn {
  background-color: #e91e63;
}
.darkTheme .purpleTheme .mat-flat-button.mat-primary.mat-button-disabled, .darkTheme .purpleTheme .mat-flat-button.mat-accent.mat-button-disabled, .darkTheme .purpleTheme .mat-flat-button.mat-warn.mat-button-disabled, .darkTheme .purpleTheme .mat-flat-button.mat-button-disabled.mat-button-disabled, .darkTheme .purpleTheme .mat-raised-button.mat-primary.mat-button-disabled, .darkTheme .purpleTheme .mat-raised-button.mat-accent.mat-button-disabled, .darkTheme .purpleTheme .mat-raised-button.mat-warn.mat-button-disabled, .darkTheme .purpleTheme .mat-raised-button.mat-button-disabled.mat-button-disabled, .darkTheme .purpleTheme .mat-fab.mat-primary.mat-button-disabled, .darkTheme .purpleTheme .mat-fab.mat-accent.mat-button-disabled, .darkTheme .purpleTheme .mat-fab.mat-warn.mat-button-disabled, .darkTheme .purpleTheme .mat-fab.mat-button-disabled.mat-button-disabled, .darkTheme .purpleTheme .mat-mini-fab.mat-primary.mat-button-disabled, .darkTheme .purpleTheme .mat-mini-fab.mat-accent.mat-button-disabled, .darkTheme .purpleTheme .mat-mini-fab.mat-warn.mat-button-disabled, .darkTheme .purpleTheme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .purpleTheme .mat-flat-button.mat-primary .mat-ripple-element, .darkTheme .purpleTheme .mat-raised-button.mat-primary .mat-ripple-element, .darkTheme .purpleTheme .mat-fab.mat-primary .mat-ripple-element, .darkTheme .purpleTheme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .purpleTheme .mat-flat-button.mat-accent .mat-ripple-element, .darkTheme .purpleTheme .mat-raised-button.mat-accent .mat-ripple-element, .darkTheme .purpleTheme .mat-fab.mat-accent .mat-ripple-element, .darkTheme .purpleTheme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .purpleTheme .mat-flat-button.mat-warn .mat-ripple-element, .darkTheme .purpleTheme .mat-raised-button.mat-warn .mat-ripple-element, .darkTheme .purpleTheme .mat-fab.mat-warn .mat-ripple-element, .darkTheme .purpleTheme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .purpleTheme .mat-stroked-button:not([class*=mat-elevation-z]), .darkTheme .purpleTheme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-fab:not([class*=mat-elevation-z]), .darkTheme .purpleTheme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .darkTheme .purpleTheme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .darkTheme .purpleTheme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.darkTheme .purpleTheme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.darkTheme .purpleTheme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.darkTheme .purpleTheme .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .purpleTheme .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.darkTheme .purpleTheme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.darkTheme .purpleTheme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.darkTheme .purpleTheme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.darkTheme .purpleTheme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.darkTheme .purpleTheme .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .purpleTheme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.darkTheme .purpleTheme .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.darkTheme .purpleTheme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.darkTheme .purpleTheme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.darkTheme .purpleTheme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.darkTheme .purpleTheme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.darkTheme .purpleTheme .mat-card {
  background: #424242;
  color: white;
}
.darkTheme .purpleTheme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .purpleTheme .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .purpleTheme .mat-checkbox-checkmark {
  fill: #303030;
}
.darkTheme .purpleTheme .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.darkTheme .purpleTheme .mat-checkbox-mixedmark {
  background-color: #303030;
}
.darkTheme .purpleTheme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .darkTheme .purpleTheme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #7352ff;
}
.darkTheme .purpleTheme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .darkTheme .purpleTheme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #402e8d;
}
.darkTheme .purpleTheme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .darkTheme .purpleTheme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e91e63;
}
.darkTheme .purpleTheme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .darkTheme .purpleTheme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.darkTheme .purpleTheme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.darkTheme .purpleTheme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.darkTheme .purpleTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.darkTheme .purpleTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #7352ff;
}
.darkTheme .purpleTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.darkTheme .purpleTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #402e8d;
}
.darkTheme .purpleTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.darkTheme .purpleTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e91e63;
}
.darkTheme .purpleTheme .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.darkTheme .purpleTheme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.darkTheme .purpleTheme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.darkTheme .purpleTheme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.darkTheme .purpleTheme .mat-chip.mat-standard-chip::after {
  background: white;
}
.darkTheme .purpleTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #7352ff;
  color: white;
}
.darkTheme .purpleTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.darkTheme .purpleTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .purpleTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e91e63;
  color: white;
}
.darkTheme .purpleTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.darkTheme .purpleTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .purpleTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #402e8d;
  color: white;
}
.darkTheme .purpleTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.darkTheme .purpleTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .purpleTheme .mat-table {
  background: #424242;
}
.darkTheme .purpleTheme .mat-table thead, .darkTheme .purpleTheme .mat-table tbody, .darkTheme .purpleTheme .mat-table tfoot,
.darkTheme .purpleTheme mat-header-row, .darkTheme .purpleTheme mat-row, .darkTheme .purpleTheme mat-footer-row,
.darkTheme .purpleTheme [mat-header-row], .darkTheme .purpleTheme [mat-row], .darkTheme .purpleTheme [mat-footer-row],
.darkTheme .purpleTheme .mat-table-sticky {
  background: inherit;
}
.darkTheme .purpleTheme mat-row, .darkTheme .purpleTheme mat-header-row, .darkTheme .purpleTheme mat-footer-row,
.darkTheme .purpleTheme th.mat-header-cell, .darkTheme .purpleTheme td.mat-cell, .darkTheme .purpleTheme td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .purpleTheme .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .purpleTheme .mat-cell, .darkTheme .purpleTheme .mat-footer-cell {
  color: white;
}
.darkTheme .purpleTheme .mat-calendar-arrow {
  fill: white;
}
.darkTheme .purpleTheme .mat-datepicker-toggle,
.darkTheme .purpleTheme .mat-datepicker-content .mat-calendar-next-button,
.darkTheme .purpleTheme .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.darkTheme .purpleTheme .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.darkTheme .purpleTheme .mat-calendar-table-header,
.darkTheme .purpleTheme .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .purpleTheme .mat-calendar-body-cell-content,
.darkTheme .purpleTheme .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.darkTheme .purpleTheme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.darkTheme .purpleTheme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .purpleTheme .mat-calendar-body-in-range::before {
  background: rgba(115, 82, 255, 0.2);
}
.darkTheme .purpleTheme .mat-calendar-body-comparison-identical,
.darkTheme .purpleTheme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.darkTheme .purpleTheme .mat-calendar-body-comparison-bridge-start::before,
.darkTheme .purpleTheme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(115, 82, 255, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .purpleTheme .mat-calendar-body-comparison-bridge-end::before,
.darkTheme .purpleTheme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(115, 82, 255, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .purpleTheme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.darkTheme .purpleTheme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.darkTheme .purpleTheme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.darkTheme .purpleTheme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.darkTheme .purpleTheme .mat-calendar-body-selected {
  background-color: #7352ff;
  color: white;
}
.darkTheme .purpleTheme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(115, 82, 255, 0.4);
}
.darkTheme .purpleTheme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.darkTheme .purpleTheme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.darkTheme .purpleTheme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(115, 82, 255, 0.3);
}
@media (hover: hover) {
  .darkTheme .purpleTheme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(115, 82, 255, 0.3);
  }
}
.darkTheme .purpleTheme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.darkTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(64, 46, 141, 0.2);
}
.darkTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.darkTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.darkTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.darkTheme .purpleTheme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(64, 46, 141, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.darkTheme .purpleTheme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(64, 46, 141, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.darkTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.darkTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.darkTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.darkTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #402e8d;
  color: white;
}
.darkTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(64, 46, 141, 0.4);
}
.darkTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.darkTheme .purpleTheme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.darkTheme .purpleTheme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(64, 46, 141, 0.3);
}
@media (hover: hover) {
  .darkTheme .purpleTheme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(64, 46, 141, 0.3);
  }
}
.darkTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(233, 30, 99, 0.2);
}
.darkTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.darkTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.darkTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.darkTheme .purpleTheme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.darkTheme .purpleTheme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.darkTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.darkTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.darkTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.darkTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e91e63;
  color: white;
}
.darkTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(233, 30, 99, 0.4);
}
.darkTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.darkTheme .purpleTheme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.darkTheme .purpleTheme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(233, 30, 99, 0.3);
}
@media (hover: hover) {
  .darkTheme .purpleTheme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(233, 30, 99, 0.3);
  }
}
.darkTheme .purpleTheme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-datepicker-toggle-active {
  color: #7352ff;
}
.darkTheme .purpleTheme .mat-datepicker-toggle-active.mat-accent {
  color: #402e8d;
}
.darkTheme .purpleTheme .mat-datepicker-toggle-active.mat-warn {
  color: #e91e63;
}
.darkTheme .purpleTheme .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.darkTheme .purpleTheme .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .purpleTheme .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .purpleTheme .mat-expansion-panel {
  background: #424242;
  color: white;
}
.darkTheme .purpleTheme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .purpleTheme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .darkTheme .purpleTheme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .darkTheme .purpleTheme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .darkTheme .purpleTheme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.darkTheme .purpleTheme .mat-expansion-panel-header-title {
  color: white;
}
.darkTheme .purpleTheme .mat-expansion-panel-header-description,
.darkTheme .purpleTheme .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .purpleTheme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.darkTheme .purpleTheme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.darkTheme .purpleTheme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.darkTheme .purpleTheme .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .purpleTheme .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .purpleTheme .mat-form-field.mat-focused .mat-form-field-label {
  color: #7352ff;
}
.darkTheme .purpleTheme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #402e8d;
}
.darkTheme .purpleTheme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e91e63;
}
.darkTheme .purpleTheme .mat-focused .mat-form-field-required-marker {
  color: #402e8d;
}
.darkTheme .purpleTheme .mat-form-field-ripple {
  background-color: white;
}
.darkTheme .purpleTheme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #7352ff;
}
.darkTheme .purpleTheme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #402e8d;
}
.darkTheme .purpleTheme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e91e63;
}
.darkTheme .purpleTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #7352ff;
}
.darkTheme .purpleTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #402e8d;
}
.darkTheme .purpleTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e91e63;
}
.darkTheme .purpleTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e91e63;
}
.darkTheme .purpleTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.darkTheme .purpleTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e91e63;
}
.darkTheme .purpleTheme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.darkTheme .purpleTheme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e91e63;
}
.darkTheme .purpleTheme .mat-error {
  color: #e91e63;
}
.darkTheme .purpleTheme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .purpleTheme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .purpleTheme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .purpleTheme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.darkTheme .purpleTheme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .purpleTheme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.darkTheme .purpleTheme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .purpleTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.darkTheme .purpleTheme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.darkTheme .purpleTheme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.darkTheme .purpleTheme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.darkTheme .purpleTheme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #7352ff;
}
.darkTheme .purpleTheme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #402e8d;
}
.darkTheme .purpleTheme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e91e63;
}
.darkTheme .purpleTheme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e91e63;
}
.darkTheme .purpleTheme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.darkTheme .purpleTheme .mat-icon.mat-primary {
  color: #7352ff;
}
.darkTheme .purpleTheme .mat-icon.mat-accent {
  color: #402e8d;
}
.darkTheme .purpleTheme .mat-icon.mat-warn {
  color: #e91e63;
}
.darkTheme .purpleTheme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .purpleTheme .mat-input-element:disabled,
.darkTheme .purpleTheme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-input-element {
  caret-color: #7352ff;
}
.darkTheme .purpleTheme .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.darkTheme .purpleTheme .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.darkTheme .purpleTheme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #402e8d;
}
.darkTheme .purpleTheme .mat-form-field.mat-warn .mat-input-element,
.darkTheme .purpleTheme .mat-form-field-invalid .mat-input-element {
  caret-color: #e91e63;
}
.darkTheme .purpleTheme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e91e63;
}
.darkTheme .purpleTheme .mat-list-base .mat-list-item {
  color: white;
}
.darkTheme .purpleTheme .mat-list-base .mat-list-option {
  color: white;
}
.darkTheme .purpleTheme .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .purpleTheme .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-list-option:hover, .darkTheme .purpleTheme .mat-list-option:focus,
.darkTheme .purpleTheme .mat-nav-list .mat-list-item:hover,
.darkTheme .purpleTheme .mat-nav-list .mat-list-item:focus,
.darkTheme .purpleTheme .mat-action-list .mat-list-item:hover,
.darkTheme .purpleTheme .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.darkTheme .purpleTheme .mat-list-single-selected-option, .darkTheme .purpleTheme .mat-list-single-selected-option:hover, .darkTheme .purpleTheme .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.darkTheme .purpleTheme .mat-menu-panel {
  background: #424242;
}
.darkTheme .purpleTheme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-menu-item {
  background: transparent;
  color: white;
}
.darkTheme .purpleTheme .mat-menu-item[disabled],
.darkTheme .purpleTheme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.darkTheme .purpleTheme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-menu-item .mat-icon-no-color,
.darkTheme .purpleTheme .mat-menu-submenu-icon {
  color: white;
}
.darkTheme .purpleTheme .mat-menu-item:hover:not([disabled]),
.darkTheme .purpleTheme .mat-menu-item.cdk-program-focused:not([disabled]),
.darkTheme .purpleTheme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.darkTheme .purpleTheme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.darkTheme .purpleTheme .mat-paginator {
  background: #424242;
}
.darkTheme .purpleTheme .mat-paginator,
.darkTheme .purpleTheme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .purpleTheme .mat-paginator-decrement,
.darkTheme .purpleTheme .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.darkTheme .purpleTheme .mat-paginator-first,
.darkTheme .purpleTheme .mat-paginator-last {
  border-top: 2px solid white;
}
.darkTheme .purpleTheme .mat-icon-button[disabled] .mat-paginator-decrement,
.darkTheme .purpleTheme .mat-icon-button[disabled] .mat-paginator-increment,
.darkTheme .purpleTheme .mat-icon-button[disabled] .mat-paginator-first,
.darkTheme .purpleTheme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-progress-bar-background {
  fill: #413964;
}
.darkTheme .purpleTheme .mat-progress-bar-buffer {
  background-color: #413964;
}
.darkTheme .purpleTheme .mat-progress-bar-fill::after {
  background-color: #7352ff;
}
.darkTheme .purpleTheme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #343047;
}
.darkTheme .purpleTheme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #343047;
}
.darkTheme .purpleTheme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #402e8d;
}
.darkTheme .purpleTheme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #5e2c3d;
}
.darkTheme .purpleTheme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #5e2c3d;
}
.darkTheme .purpleTheme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e91e63;
}
.darkTheme .purpleTheme .mat-progress-spinner circle, .darkTheme .purpleTheme .mat-spinner circle {
  stroke: #7352ff;
}
.darkTheme .purpleTheme .mat-progress-spinner.mat-accent circle, .darkTheme .purpleTheme .mat-spinner.mat-accent circle {
  stroke: #402e8d;
}
.darkTheme .purpleTheme .mat-progress-spinner.mat-warn circle, .darkTheme .purpleTheme .mat-spinner.mat-warn circle {
  stroke: #e91e63;
}
.darkTheme .purpleTheme .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .purpleTheme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #7352ff;
}
.darkTheme .purpleTheme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.darkTheme .purpleTheme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .darkTheme .purpleTheme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .darkTheme .purpleTheme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #7352ff;
}
.darkTheme .purpleTheme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #402e8d;
}
.darkTheme .purpleTheme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.darkTheme .purpleTheme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .darkTheme .purpleTheme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .darkTheme .purpleTheme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #402e8d;
}
.darkTheme .purpleTheme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e91e63;
}
.darkTheme .purpleTheme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.darkTheme .purpleTheme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .darkTheme .purpleTheme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .darkTheme .purpleTheme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e91e63;
}
.darkTheme .purpleTheme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.darkTheme .purpleTheme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.darkTheme .purpleTheme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.darkTheme .purpleTheme .mat-select-value {
  color: white;
}
.darkTheme .purpleTheme .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .purpleTheme .mat-select-panel {
  background: #424242;
}
.darkTheme .purpleTheme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.darkTheme .purpleTheme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #7352ff;
}
.darkTheme .purpleTheme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #402e8d;
}
.darkTheme .purpleTheme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e91e63;
}
.darkTheme .purpleTheme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e91e63;
}
.darkTheme .purpleTheme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.darkTheme .purpleTheme .mat-drawer {
  background-color: #424242;
  color: white;
}
.darkTheme .purpleTheme .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.darkTheme .purpleTheme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.darkTheme .purpleTheme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.darkTheme .purpleTheme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.darkTheme .purpleTheme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.darkTheme .purpleTheme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.darkTheme .purpleTheme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #402e8d;
}
.darkTheme .purpleTheme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(64, 46, 141, 0.54);
}
.darkTheme .purpleTheme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #402e8d;
}
.darkTheme .purpleTheme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #7352ff;
}
.darkTheme .purpleTheme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(115, 82, 255, 0.54);
}
.darkTheme .purpleTheme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #7352ff;
}
.darkTheme .purpleTheme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e91e63;
}
.darkTheme .purpleTheme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(233, 30, 99, 0.54);
}
.darkTheme .purpleTheme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e91e63;
}
.darkTheme .purpleTheme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.darkTheme .purpleTheme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.darkTheme .purpleTheme .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .purpleTheme .mat-slider.mat-primary .mat-slider-track-fill,
.darkTheme .purpleTheme .mat-slider.mat-primary .mat-slider-thumb,
.darkTheme .purpleTheme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #7352ff;
}
.darkTheme .purpleTheme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.darkTheme .purpleTheme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(115, 82, 255, 0.2);
}
.darkTheme .purpleTheme .mat-slider.mat-accent .mat-slider-track-fill,
.darkTheme .purpleTheme .mat-slider.mat-accent .mat-slider-thumb,
.darkTheme .purpleTheme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #402e8d;
}
.darkTheme .purpleTheme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.darkTheme .purpleTheme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(64, 46, 141, 0.2);
}
.darkTheme .purpleTheme .mat-slider.mat-warn .mat-slider-track-fill,
.darkTheme .purpleTheme .mat-slider.mat-warn .mat-slider-thumb,
.darkTheme .purpleTheme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e91e63;
}
.darkTheme .purpleTheme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.darkTheme .purpleTheme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(233, 30, 99, 0.2);
}
.darkTheme .purpleTheme .mat-slider:hover .mat-slider-track-background,
.darkTheme .purpleTheme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .purpleTheme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.darkTheme .purpleTheme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.darkTheme .purpleTheme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .purpleTheme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .purpleTheme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .purpleTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.darkTheme .purpleTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.darkTheme .purpleTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.darkTheme .purpleTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .purpleTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.darkTheme .purpleTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .darkTheme .purpleTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .purpleTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .darkTheme .purpleTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .purpleTheme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .purpleTheme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.darkTheme .purpleTheme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.darkTheme .purpleTheme .mat-step-header.cdk-keyboard-focused, .darkTheme .purpleTheme .mat-step-header.cdk-program-focused, .darkTheme .purpleTheme .mat-step-header:hover:not([aria-disabled]), .darkTheme .purpleTheme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.darkTheme .purpleTheme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .darkTheme .purpleTheme .mat-step-header:hover {
    background: none;
  }
}
.darkTheme .purpleTheme .mat-step-header .mat-step-label,
.darkTheme .purpleTheme .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .purpleTheme .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.darkTheme .purpleTheme .mat-step-header .mat-step-icon-selected,
.darkTheme .purpleTheme .mat-step-header .mat-step-icon-state-done,
.darkTheme .purpleTheme .mat-step-header .mat-step-icon-state-edit {
  background-color: #7352ff;
  color: white;
}
.darkTheme .purpleTheme .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.darkTheme .purpleTheme .mat-step-header.mat-accent .mat-step-icon-selected,
.darkTheme .purpleTheme .mat-step-header.mat-accent .mat-step-icon-state-done,
.darkTheme .purpleTheme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #402e8d;
  color: white;
}
.darkTheme .purpleTheme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.darkTheme .purpleTheme .mat-step-header.mat-warn .mat-step-icon-selected,
.darkTheme .purpleTheme .mat-step-header.mat-warn .mat-step-icon-state-done,
.darkTheme .purpleTheme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e91e63;
  color: white;
}
.darkTheme .purpleTheme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e91e63;
}
.darkTheme .purpleTheme .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.darkTheme .purpleTheme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e91e63;
}
.darkTheme .purpleTheme .mat-stepper-horizontal, .darkTheme .purpleTheme .mat-stepper-vertical {
  background-color: #424242;
}
.darkTheme .purpleTheme .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .purpleTheme .mat-horizontal-stepper-header::before,
.darkTheme .purpleTheme .mat-horizontal-stepper-header::after,
.darkTheme .purpleTheme .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .purpleTheme .mat-sort-header-arrow {
  color: #c6c6c6;
}
.darkTheme .purpleTheme .mat-tab-nav-bar,
.darkTheme .purpleTheme .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.darkTheme .purpleTheme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.darkTheme .purpleTheme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.darkTheme .purpleTheme .mat-tab-label, .darkTheme .purpleTheme .mat-tab-link {
  color: white;
}
.darkTheme .purpleTheme .mat-tab-label.mat-tab-disabled, .darkTheme .purpleTheme .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-tab-header-pagination-chevron {
  border-color: white;
}
.darkTheme .purpleTheme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .purpleTheme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.darkTheme .purpleTheme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.darkTheme .purpleTheme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .purpleTheme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .purpleTheme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .purpleTheme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .purpleTheme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .purpleTheme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(115, 82, 255, 0.3);
}
.darkTheme .purpleTheme .mat-tab-group.mat-primary .mat-ink-bar, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #7352ff;
}
.darkTheme .purpleTheme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .darkTheme .purpleTheme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.darkTheme .purpleTheme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .purpleTheme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .purpleTheme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .purpleTheme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .purpleTheme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .purpleTheme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(64, 46, 141, 0.3);
}
.darkTheme .purpleTheme .mat-tab-group.mat-accent .mat-ink-bar, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #402e8d;
}
.darkTheme .purpleTheme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .darkTheme .purpleTheme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.darkTheme .purpleTheme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .purpleTheme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .purpleTheme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .purpleTheme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .purpleTheme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .purpleTheme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.darkTheme .purpleTheme .mat-tab-group.mat-warn .mat-ink-bar, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e91e63;
}
.darkTheme .purpleTheme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .darkTheme .purpleTheme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.darkTheme .purpleTheme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .purpleTheme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .purpleTheme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .purpleTheme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(115, 82, 255, 0.3);
}
.darkTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-header, .darkTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .darkTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #7352ff;
}
.darkTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .darkTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.darkTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.darkTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.darkTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.darkTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.darkTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.darkTheme .purpleTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.darkTheme .purpleTheme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .purpleTheme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .purpleTheme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .purpleTheme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(64, 46, 141, 0.3);
}
.darkTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-header, .darkTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .darkTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #402e8d;
}
.darkTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .darkTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.darkTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.darkTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.darkTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.darkTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.darkTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.darkTheme .purpleTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.darkTheme .purpleTheme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .purpleTheme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .purpleTheme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .purpleTheme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.darkTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-header, .darkTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .darkTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e91e63;
}
.darkTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .darkTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.darkTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.darkTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.darkTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.darkTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.darkTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.darkTheme .purpleTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.darkTheme .purpleTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.darkTheme .purpleTheme .mat-toolbar {
  background: #212121;
  color: white;
}
.darkTheme .purpleTheme .mat-toolbar.mat-primary {
  background: #7352ff;
  color: white;
}
.darkTheme .purpleTheme .mat-toolbar.mat-accent {
  background: #402e8d;
  color: white;
}
.darkTheme .purpleTheme .mat-toolbar.mat-warn {
  background: #e91e63;
  color: white;
}
.darkTheme .purpleTheme .mat-toolbar .mat-form-field-underline,
.darkTheme .purpleTheme .mat-toolbar .mat-form-field-ripple,
.darkTheme .purpleTheme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.darkTheme .purpleTheme .mat-toolbar .mat-form-field-label,
.darkTheme .purpleTheme .mat-toolbar .mat-focused .mat-form-field-label,
.darkTheme .purpleTheme .mat-toolbar .mat-select-value,
.darkTheme .purpleTheme .mat-toolbar .mat-select-arrow,
.darkTheme .purpleTheme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.darkTheme .purpleTheme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.darkTheme .purpleTheme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.darkTheme .purpleTheme .mat-tree {
  background: #424242;
}
.darkTheme .purpleTheme .mat-tree-node,
.darkTheme .purpleTheme .mat-nested-tree-node {
  color: white;
}
.darkTheme .purpleTheme .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .purpleTheme .mat-simple-snackbar-action {
  color: inherit;
}
.darkTheme .redTheme .leftsidebar .menu-list-item.activeMenu .mat-list-item-content, .darkTheme .redTheme .leftsidebar .menu-list-item.activeMenu:hover .mat-list-item-content {
  background-color: #ff5c8e;
}
.darkTheme .redTheme .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .redTheme .mat-option {
  color: white;
}
.darkTheme .redTheme .mat-option:hover:not(.mat-option-disabled), .darkTheme .redTheme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.darkTheme .redTheme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.darkTheme .redTheme .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.darkTheme .redTheme .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff5c8e;
}
.darkTheme .redTheme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #5e244d;
}
.darkTheme .redTheme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e91e63;
}
.darkTheme .redTheme .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .redTheme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .redTheme .mat-pseudo-checkbox::after {
  color: #303030;
}
.darkTheme .redTheme .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.darkTheme .redTheme .mat-primary .mat-pseudo-checkbox-checked,
.darkTheme .redTheme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #ff5c8e;
}
.darkTheme .redTheme .mat-pseudo-checkbox-checked,
.darkTheme .redTheme .mat-pseudo-checkbox-indeterminate,
.darkTheme .redTheme .mat-accent .mat-pseudo-checkbox-checked,
.darkTheme .redTheme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #5e244d;
}
.darkTheme .redTheme .mat-warn .mat-pseudo-checkbox-checked,
.darkTheme .redTheme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e91e63;
}
.darkTheme .redTheme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.darkTheme .redTheme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.darkTheme .redTheme .mat-app-background, .darkTheme .redTheme.mat-app-background {
  background-color: #303030;
  color: white;
}
.darkTheme .redTheme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.darkTheme .redTheme .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.darkTheme .redTheme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.darkTheme .redTheme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.darkTheme .redTheme .mat-badge-content {
  color: white;
  background: #ff5c8e;
}
.cdk-high-contrast-active .darkTheme .redTheme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.darkTheme .redTheme .mat-badge-accent .mat-badge-content {
  background: #5e244d;
  color: white;
}
.darkTheme .redTheme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e91e63;
}
.darkTheme .redTheme .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.darkTheme .redTheme .mat-button, .darkTheme .redTheme .mat-icon-button, .darkTheme .redTheme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.darkTheme .redTheme .mat-button.mat-primary, .darkTheme .redTheme .mat-icon-button.mat-primary, .darkTheme .redTheme .mat-stroked-button.mat-primary {
  color: #ff5c8e;
}
.darkTheme .redTheme .mat-button.mat-accent, .darkTheme .redTheme .mat-icon-button.mat-accent, .darkTheme .redTheme .mat-stroked-button.mat-accent {
  color: #5e244d;
}
.darkTheme .redTheme .mat-button.mat-warn, .darkTheme .redTheme .mat-icon-button.mat-warn, .darkTheme .redTheme .mat-stroked-button.mat-warn {
  color: #e91e63;
}
.darkTheme .redTheme .mat-button.mat-primary.mat-button-disabled, .darkTheme .redTheme .mat-button.mat-accent.mat-button-disabled, .darkTheme .redTheme .mat-button.mat-warn.mat-button-disabled, .darkTheme .redTheme .mat-button.mat-button-disabled.mat-button-disabled, .darkTheme .redTheme .mat-icon-button.mat-primary.mat-button-disabled, .darkTheme .redTheme .mat-icon-button.mat-accent.mat-button-disabled, .darkTheme .redTheme .mat-icon-button.mat-warn.mat-button-disabled, .darkTheme .redTheme .mat-icon-button.mat-button-disabled.mat-button-disabled, .darkTheme .redTheme .mat-stroked-button.mat-primary.mat-button-disabled, .darkTheme .redTheme .mat-stroked-button.mat-accent.mat-button-disabled, .darkTheme .redTheme .mat-stroked-button.mat-warn.mat-button-disabled, .darkTheme .redTheme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.darkTheme .redTheme .mat-button.mat-primary .mat-button-focus-overlay, .darkTheme .redTheme .mat-icon-button.mat-primary .mat-button-focus-overlay, .darkTheme .redTheme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #ff5c8e;
}
.darkTheme .redTheme .mat-button.mat-accent .mat-button-focus-overlay, .darkTheme .redTheme .mat-icon-button.mat-accent .mat-button-focus-overlay, .darkTheme .redTheme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #5e244d;
}
.darkTheme .redTheme .mat-button.mat-warn .mat-button-focus-overlay, .darkTheme .redTheme .mat-icon-button.mat-warn .mat-button-focus-overlay, .darkTheme .redTheme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e91e63;
}
.darkTheme .redTheme .mat-button.mat-button-disabled .mat-button-focus-overlay, .darkTheme .redTheme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .darkTheme .redTheme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.darkTheme .redTheme .mat-button .mat-ripple-element, .darkTheme .redTheme .mat-icon-button .mat-ripple-element, .darkTheme .redTheme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.darkTheme .redTheme .mat-button-focus-overlay {
  background: white;
}
.darkTheme .redTheme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .redTheme .mat-flat-button, .darkTheme .redTheme .mat-raised-button, .darkTheme .redTheme .mat-fab, .darkTheme .redTheme .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.darkTheme .redTheme .mat-flat-button.mat-primary, .darkTheme .redTheme .mat-raised-button.mat-primary, .darkTheme .redTheme .mat-fab.mat-primary, .darkTheme .redTheme .mat-mini-fab.mat-primary {
  color: white;
}
.darkTheme .redTheme .mat-flat-button.mat-accent, .darkTheme .redTheme .mat-raised-button.mat-accent, .darkTheme .redTheme .mat-fab.mat-accent, .darkTheme .redTheme .mat-mini-fab.mat-accent {
  color: white;
}
.darkTheme .redTheme .mat-flat-button.mat-warn, .darkTheme .redTheme .mat-raised-button.mat-warn, .darkTheme .redTheme .mat-fab.mat-warn, .darkTheme .redTheme .mat-mini-fab.mat-warn {
  color: white;
}
.darkTheme .redTheme .mat-flat-button.mat-primary.mat-button-disabled, .darkTheme .redTheme .mat-flat-button.mat-accent.mat-button-disabled, .darkTheme .redTheme .mat-flat-button.mat-warn.mat-button-disabled, .darkTheme .redTheme .mat-flat-button.mat-button-disabled.mat-button-disabled, .darkTheme .redTheme .mat-raised-button.mat-primary.mat-button-disabled, .darkTheme .redTheme .mat-raised-button.mat-accent.mat-button-disabled, .darkTheme .redTheme .mat-raised-button.mat-warn.mat-button-disabled, .darkTheme .redTheme .mat-raised-button.mat-button-disabled.mat-button-disabled, .darkTheme .redTheme .mat-fab.mat-primary.mat-button-disabled, .darkTheme .redTheme .mat-fab.mat-accent.mat-button-disabled, .darkTheme .redTheme .mat-fab.mat-warn.mat-button-disabled, .darkTheme .redTheme .mat-fab.mat-button-disabled.mat-button-disabled, .darkTheme .redTheme .mat-mini-fab.mat-primary.mat-button-disabled, .darkTheme .redTheme .mat-mini-fab.mat-accent.mat-button-disabled, .darkTheme .redTheme .mat-mini-fab.mat-warn.mat-button-disabled, .darkTheme .redTheme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.darkTheme .redTheme .mat-flat-button.mat-primary, .darkTheme .redTheme .mat-raised-button.mat-primary, .darkTheme .redTheme .mat-fab.mat-primary, .darkTheme .redTheme .mat-mini-fab.mat-primary {
  background-color: #ff5c8e;
}
.darkTheme .redTheme .mat-flat-button.mat-accent, .darkTheme .redTheme .mat-raised-button.mat-accent, .darkTheme .redTheme .mat-fab.mat-accent, .darkTheme .redTheme .mat-mini-fab.mat-accent {
  background-color: #5e244d;
}
.darkTheme .redTheme .mat-flat-button.mat-warn, .darkTheme .redTheme .mat-raised-button.mat-warn, .darkTheme .redTheme .mat-fab.mat-warn, .darkTheme .redTheme .mat-mini-fab.mat-warn {
  background-color: #e91e63;
}
.darkTheme .redTheme .mat-flat-button.mat-primary.mat-button-disabled, .darkTheme .redTheme .mat-flat-button.mat-accent.mat-button-disabled, .darkTheme .redTheme .mat-flat-button.mat-warn.mat-button-disabled, .darkTheme .redTheme .mat-flat-button.mat-button-disabled.mat-button-disabled, .darkTheme .redTheme .mat-raised-button.mat-primary.mat-button-disabled, .darkTheme .redTheme .mat-raised-button.mat-accent.mat-button-disabled, .darkTheme .redTheme .mat-raised-button.mat-warn.mat-button-disabled, .darkTheme .redTheme .mat-raised-button.mat-button-disabled.mat-button-disabled, .darkTheme .redTheme .mat-fab.mat-primary.mat-button-disabled, .darkTheme .redTheme .mat-fab.mat-accent.mat-button-disabled, .darkTheme .redTheme .mat-fab.mat-warn.mat-button-disabled, .darkTheme .redTheme .mat-fab.mat-button-disabled.mat-button-disabled, .darkTheme .redTheme .mat-mini-fab.mat-primary.mat-button-disabled, .darkTheme .redTheme .mat-mini-fab.mat-accent.mat-button-disabled, .darkTheme .redTheme .mat-mini-fab.mat-warn.mat-button-disabled, .darkTheme .redTheme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .redTheme .mat-flat-button.mat-primary .mat-ripple-element, .darkTheme .redTheme .mat-raised-button.mat-primary .mat-ripple-element, .darkTheme .redTheme .mat-fab.mat-primary .mat-ripple-element, .darkTheme .redTheme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .redTheme .mat-flat-button.mat-accent .mat-ripple-element, .darkTheme .redTheme .mat-raised-button.mat-accent .mat-ripple-element, .darkTheme .redTheme .mat-fab.mat-accent .mat-ripple-element, .darkTheme .redTheme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .redTheme .mat-flat-button.mat-warn .mat-ripple-element, .darkTheme .redTheme .mat-raised-button.mat-warn .mat-ripple-element, .darkTheme .redTheme .mat-fab.mat-warn .mat-ripple-element, .darkTheme .redTheme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .redTheme .mat-stroked-button:not([class*=mat-elevation-z]), .darkTheme .redTheme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-fab:not([class*=mat-elevation-z]), .darkTheme .redTheme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .darkTheme .redTheme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .darkTheme .redTheme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.darkTheme .redTheme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.darkTheme .redTheme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.darkTheme .redTheme .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .redTheme .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.darkTheme .redTheme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.darkTheme .redTheme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.darkTheme .redTheme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.darkTheme .redTheme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.darkTheme .redTheme .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .redTheme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.darkTheme .redTheme .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.darkTheme .redTheme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.darkTheme .redTheme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.darkTheme .redTheme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.darkTheme .redTheme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.darkTheme .redTheme .mat-card {
  background: #424242;
  color: white;
}
.darkTheme .redTheme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .redTheme .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .redTheme .mat-checkbox-checkmark {
  fill: #303030;
}
.darkTheme .redTheme .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.darkTheme .redTheme .mat-checkbox-mixedmark {
  background-color: #303030;
}
.darkTheme .redTheme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .darkTheme .redTheme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #ff5c8e;
}
.darkTheme .redTheme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .darkTheme .redTheme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #5e244d;
}
.darkTheme .redTheme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .darkTheme .redTheme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e91e63;
}
.darkTheme .redTheme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .darkTheme .redTheme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.darkTheme .redTheme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.darkTheme .redTheme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.darkTheme .redTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.darkTheme .redTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #ff5c8e;
}
.darkTheme .redTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.darkTheme .redTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #5e244d;
}
.darkTheme .redTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.darkTheme .redTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e91e63;
}
.darkTheme .redTheme .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.darkTheme .redTheme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.darkTheme .redTheme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.darkTheme .redTheme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.darkTheme .redTheme .mat-chip.mat-standard-chip::after {
  background: white;
}
.darkTheme .redTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #ff5c8e;
  color: white;
}
.darkTheme .redTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.darkTheme .redTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .redTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e91e63;
  color: white;
}
.darkTheme .redTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.darkTheme .redTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .redTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #5e244d;
  color: white;
}
.darkTheme .redTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.darkTheme .redTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .redTheme .mat-table {
  background: #424242;
}
.darkTheme .redTheme .mat-table thead, .darkTheme .redTheme .mat-table tbody, .darkTheme .redTheme .mat-table tfoot,
.darkTheme .redTheme mat-header-row, .darkTheme .redTheme mat-row, .darkTheme .redTheme mat-footer-row,
.darkTheme .redTheme [mat-header-row], .darkTheme .redTheme [mat-row], .darkTheme .redTheme [mat-footer-row],
.darkTheme .redTheme .mat-table-sticky {
  background: inherit;
}
.darkTheme .redTheme mat-row, .darkTheme .redTheme mat-header-row, .darkTheme .redTheme mat-footer-row,
.darkTheme .redTheme th.mat-header-cell, .darkTheme .redTheme td.mat-cell, .darkTheme .redTheme td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .redTheme .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .redTheme .mat-cell, .darkTheme .redTheme .mat-footer-cell {
  color: white;
}
.darkTheme .redTheme .mat-calendar-arrow {
  fill: white;
}
.darkTheme .redTheme .mat-datepicker-toggle,
.darkTheme .redTheme .mat-datepicker-content .mat-calendar-next-button,
.darkTheme .redTheme .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.darkTheme .redTheme .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.darkTheme .redTheme .mat-calendar-table-header,
.darkTheme .redTheme .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .redTheme .mat-calendar-body-cell-content,
.darkTheme .redTheme .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.darkTheme .redTheme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.darkTheme .redTheme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .redTheme .mat-calendar-body-in-range::before {
  background: rgba(255, 92, 142, 0.2);
}
.darkTheme .redTheme .mat-calendar-body-comparison-identical,
.darkTheme .redTheme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.darkTheme .redTheme .mat-calendar-body-comparison-bridge-start::before,
.darkTheme .redTheme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 92, 142, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .redTheme .mat-calendar-body-comparison-bridge-end::before,
.darkTheme .redTheme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 92, 142, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .redTheme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.darkTheme .redTheme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.darkTheme .redTheme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.darkTheme .redTheme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.darkTheme .redTheme .mat-calendar-body-selected {
  background-color: #ff5c8e;
  color: white;
}
.darkTheme .redTheme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 92, 142, 0.4);
}
.darkTheme .redTheme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.darkTheme .redTheme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.darkTheme .redTheme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 92, 142, 0.3);
}
@media (hover: hover) {
  .darkTheme .redTheme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 92, 142, 0.3);
  }
}
.darkTheme .redTheme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.darkTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(94, 36, 77, 0.2);
}
.darkTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.darkTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.darkTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.darkTheme .redTheme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(94, 36, 77, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.darkTheme .redTheme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(94, 36, 77, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.darkTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.darkTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.darkTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.darkTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #5e244d;
  color: white;
}
.darkTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(94, 36, 77, 0.4);
}
.darkTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.darkTheme .redTheme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.darkTheme .redTheme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(94, 36, 77, 0.3);
}
@media (hover: hover) {
  .darkTheme .redTheme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(94, 36, 77, 0.3);
  }
}
.darkTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(233, 30, 99, 0.2);
}
.darkTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.darkTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.darkTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.darkTheme .redTheme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.darkTheme .redTheme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.darkTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.darkTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.darkTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.darkTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e91e63;
  color: white;
}
.darkTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(233, 30, 99, 0.4);
}
.darkTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.darkTheme .redTheme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.darkTheme .redTheme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(233, 30, 99, 0.3);
}
@media (hover: hover) {
  .darkTheme .redTheme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(233, 30, 99, 0.3);
  }
}
.darkTheme .redTheme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-datepicker-toggle-active {
  color: #ff5c8e;
}
.darkTheme .redTheme .mat-datepicker-toggle-active.mat-accent {
  color: #5e244d;
}
.darkTheme .redTheme .mat-datepicker-toggle-active.mat-warn {
  color: #e91e63;
}
.darkTheme .redTheme .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.darkTheme .redTheme .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .redTheme .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .redTheme .mat-expansion-panel {
  background: #424242;
  color: white;
}
.darkTheme .redTheme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .redTheme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .darkTheme .redTheme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .darkTheme .redTheme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .darkTheme .redTheme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.darkTheme .redTheme .mat-expansion-panel-header-title {
  color: white;
}
.darkTheme .redTheme .mat-expansion-panel-header-description,
.darkTheme .redTheme .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .redTheme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.darkTheme .redTheme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.darkTheme .redTheme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.darkTheme .redTheme .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .redTheme .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .redTheme .mat-form-field.mat-focused .mat-form-field-label {
  color: #ff5c8e;
}
.darkTheme .redTheme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #5e244d;
}
.darkTheme .redTheme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e91e63;
}
.darkTheme .redTheme .mat-focused .mat-form-field-required-marker {
  color: #5e244d;
}
.darkTheme .redTheme .mat-form-field-ripple {
  background-color: white;
}
.darkTheme .redTheme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #ff5c8e;
}
.darkTheme .redTheme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #5e244d;
}
.darkTheme .redTheme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e91e63;
}
.darkTheme .redTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #ff5c8e;
}
.darkTheme .redTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #5e244d;
}
.darkTheme .redTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e91e63;
}
.darkTheme .redTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e91e63;
}
.darkTheme .redTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.darkTheme .redTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e91e63;
}
.darkTheme .redTheme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.darkTheme .redTheme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e91e63;
}
.darkTheme .redTheme .mat-error {
  color: #e91e63;
}
.darkTheme .redTheme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .redTheme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .redTheme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .redTheme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.darkTheme .redTheme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .redTheme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.darkTheme .redTheme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .redTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.darkTheme .redTheme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.darkTheme .redTheme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.darkTheme .redTheme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.darkTheme .redTheme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #ff5c8e;
}
.darkTheme .redTheme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #5e244d;
}
.darkTheme .redTheme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e91e63;
}
.darkTheme .redTheme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e91e63;
}
.darkTheme .redTheme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.darkTheme .redTheme .mat-icon.mat-primary {
  color: #ff5c8e;
}
.darkTheme .redTheme .mat-icon.mat-accent {
  color: #5e244d;
}
.darkTheme .redTheme .mat-icon.mat-warn {
  color: #e91e63;
}
.darkTheme .redTheme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .redTheme .mat-input-element:disabled,
.darkTheme .redTheme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-input-element {
  caret-color: #ff5c8e;
}
.darkTheme .redTheme .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.darkTheme .redTheme .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.darkTheme .redTheme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #5e244d;
}
.darkTheme .redTheme .mat-form-field.mat-warn .mat-input-element,
.darkTheme .redTheme .mat-form-field-invalid .mat-input-element {
  caret-color: #e91e63;
}
.darkTheme .redTheme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e91e63;
}
.darkTheme .redTheme .mat-list-base .mat-list-item {
  color: white;
}
.darkTheme .redTheme .mat-list-base .mat-list-option {
  color: white;
}
.darkTheme .redTheme .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .redTheme .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-list-option:hover, .darkTheme .redTheme .mat-list-option:focus,
.darkTheme .redTheme .mat-nav-list .mat-list-item:hover,
.darkTheme .redTheme .mat-nav-list .mat-list-item:focus,
.darkTheme .redTheme .mat-action-list .mat-list-item:hover,
.darkTheme .redTheme .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.darkTheme .redTheme .mat-list-single-selected-option, .darkTheme .redTheme .mat-list-single-selected-option:hover, .darkTheme .redTheme .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.darkTheme .redTheme .mat-menu-panel {
  background: #424242;
}
.darkTheme .redTheme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-menu-item {
  background: transparent;
  color: white;
}
.darkTheme .redTheme .mat-menu-item[disabled],
.darkTheme .redTheme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.darkTheme .redTheme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-menu-item .mat-icon-no-color,
.darkTheme .redTheme .mat-menu-submenu-icon {
  color: white;
}
.darkTheme .redTheme .mat-menu-item:hover:not([disabled]),
.darkTheme .redTheme .mat-menu-item.cdk-program-focused:not([disabled]),
.darkTheme .redTheme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.darkTheme .redTheme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.darkTheme .redTheme .mat-paginator {
  background: #424242;
}
.darkTheme .redTheme .mat-paginator,
.darkTheme .redTheme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .redTheme .mat-paginator-decrement,
.darkTheme .redTheme .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.darkTheme .redTheme .mat-paginator-first,
.darkTheme .redTheme .mat-paginator-last {
  border-top: 2px solid white;
}
.darkTheme .redTheme .mat-icon-button[disabled] .mat-paginator-decrement,
.darkTheme .redTheme .mat-icon-button[disabled] .mat-paginator-increment,
.darkTheme .redTheme .mat-icon-button[disabled] .mat-paginator-first,
.darkTheme .redTheme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-progress-bar-background {
  fill: #643b48;
}
.darkTheme .redTheme .mat-progress-bar-buffer {
  background-color: #643b48;
}
.darkTheme .redTheme .mat-progress-bar-fill::after {
  background-color: #ff5c8e;
}
.darkTheme .redTheme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #3c2d37;
}
.darkTheme .redTheme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #3c2d37;
}
.darkTheme .redTheme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #5e244d;
}
.darkTheme .redTheme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #5e2c3d;
}
.darkTheme .redTheme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #5e2c3d;
}
.darkTheme .redTheme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e91e63;
}
.darkTheme .redTheme .mat-progress-spinner circle, .darkTheme .redTheme .mat-spinner circle {
  stroke: #ff5c8e;
}
.darkTheme .redTheme .mat-progress-spinner.mat-accent circle, .darkTheme .redTheme .mat-spinner.mat-accent circle {
  stroke: #5e244d;
}
.darkTheme .redTheme .mat-progress-spinner.mat-warn circle, .darkTheme .redTheme .mat-spinner.mat-warn circle {
  stroke: #e91e63;
}
.darkTheme .redTheme .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .redTheme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff5c8e;
}
.darkTheme .redTheme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.darkTheme .redTheme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .darkTheme .redTheme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .darkTheme .redTheme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #ff5c8e;
}
.darkTheme .redTheme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #5e244d;
}
.darkTheme .redTheme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.darkTheme .redTheme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .darkTheme .redTheme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .darkTheme .redTheme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #5e244d;
}
.darkTheme .redTheme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e91e63;
}
.darkTheme .redTheme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.darkTheme .redTheme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .darkTheme .redTheme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .darkTheme .redTheme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e91e63;
}
.darkTheme .redTheme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.darkTheme .redTheme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.darkTheme .redTheme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.darkTheme .redTheme .mat-select-value {
  color: white;
}
.darkTheme .redTheme .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .redTheme .mat-select-panel {
  background: #424242;
}
.darkTheme .redTheme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.darkTheme .redTheme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #ff5c8e;
}
.darkTheme .redTheme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #5e244d;
}
.darkTheme .redTheme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e91e63;
}
.darkTheme .redTheme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e91e63;
}
.darkTheme .redTheme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.darkTheme .redTheme .mat-drawer {
  background-color: #424242;
  color: white;
}
.darkTheme .redTheme .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.darkTheme .redTheme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.darkTheme .redTheme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.darkTheme .redTheme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.darkTheme .redTheme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.darkTheme .redTheme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.darkTheme .redTheme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #5e244d;
}
.darkTheme .redTheme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(94, 36, 77, 0.54);
}
.darkTheme .redTheme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #5e244d;
}
.darkTheme .redTheme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff5c8e;
}
.darkTheme .redTheme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 92, 142, 0.54);
}
.darkTheme .redTheme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #ff5c8e;
}
.darkTheme .redTheme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e91e63;
}
.darkTheme .redTheme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(233, 30, 99, 0.54);
}
.darkTheme .redTheme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e91e63;
}
.darkTheme .redTheme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.darkTheme .redTheme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.darkTheme .redTheme .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .redTheme .mat-slider.mat-primary .mat-slider-track-fill,
.darkTheme .redTheme .mat-slider.mat-primary .mat-slider-thumb,
.darkTheme .redTheme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #ff5c8e;
}
.darkTheme .redTheme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.darkTheme .redTheme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(255, 92, 142, 0.2);
}
.darkTheme .redTheme .mat-slider.mat-accent .mat-slider-track-fill,
.darkTheme .redTheme .mat-slider.mat-accent .mat-slider-thumb,
.darkTheme .redTheme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #5e244d;
}
.darkTheme .redTheme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.darkTheme .redTheme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(94, 36, 77, 0.2);
}
.darkTheme .redTheme .mat-slider.mat-warn .mat-slider-track-fill,
.darkTheme .redTheme .mat-slider.mat-warn .mat-slider-thumb,
.darkTheme .redTheme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e91e63;
}
.darkTheme .redTheme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.darkTheme .redTheme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(233, 30, 99, 0.2);
}
.darkTheme .redTheme .mat-slider:hover .mat-slider-track-background,
.darkTheme .redTheme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .redTheme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.darkTheme .redTheme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.darkTheme .redTheme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .redTheme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .redTheme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .redTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.darkTheme .redTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.darkTheme .redTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.darkTheme .redTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .redTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.darkTheme .redTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .darkTheme .redTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .redTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .darkTheme .redTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .redTheme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .redTheme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.darkTheme .redTheme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.darkTheme .redTheme .mat-step-header.cdk-keyboard-focused, .darkTheme .redTheme .mat-step-header.cdk-program-focused, .darkTheme .redTheme .mat-step-header:hover:not([aria-disabled]), .darkTheme .redTheme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.darkTheme .redTheme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .darkTheme .redTheme .mat-step-header:hover {
    background: none;
  }
}
.darkTheme .redTheme .mat-step-header .mat-step-label,
.darkTheme .redTheme .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .redTheme .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.darkTheme .redTheme .mat-step-header .mat-step-icon-selected,
.darkTheme .redTheme .mat-step-header .mat-step-icon-state-done,
.darkTheme .redTheme .mat-step-header .mat-step-icon-state-edit {
  background-color: #ff5c8e;
  color: white;
}
.darkTheme .redTheme .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.darkTheme .redTheme .mat-step-header.mat-accent .mat-step-icon-selected,
.darkTheme .redTheme .mat-step-header.mat-accent .mat-step-icon-state-done,
.darkTheme .redTheme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #5e244d;
  color: white;
}
.darkTheme .redTheme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.darkTheme .redTheme .mat-step-header.mat-warn .mat-step-icon-selected,
.darkTheme .redTheme .mat-step-header.mat-warn .mat-step-icon-state-done,
.darkTheme .redTheme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e91e63;
  color: white;
}
.darkTheme .redTheme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e91e63;
}
.darkTheme .redTheme .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.darkTheme .redTheme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e91e63;
}
.darkTheme .redTheme .mat-stepper-horizontal, .darkTheme .redTheme .mat-stepper-vertical {
  background-color: #424242;
}
.darkTheme .redTheme .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .redTheme .mat-horizontal-stepper-header::before,
.darkTheme .redTheme .mat-horizontal-stepper-header::after,
.darkTheme .redTheme .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .redTheme .mat-sort-header-arrow {
  color: #c6c6c6;
}
.darkTheme .redTheme .mat-tab-nav-bar,
.darkTheme .redTheme .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.darkTheme .redTheme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.darkTheme .redTheme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.darkTheme .redTheme .mat-tab-label, .darkTheme .redTheme .mat-tab-link {
  color: white;
}
.darkTheme .redTheme .mat-tab-label.mat-tab-disabled, .darkTheme .redTheme .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-tab-header-pagination-chevron {
  border-color: white;
}
.darkTheme .redTheme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .redTheme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.darkTheme .redTheme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.darkTheme .redTheme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .redTheme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .redTheme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .redTheme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .redTheme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .redTheme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .redTheme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .redTheme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 92, 142, 0.3);
}
.darkTheme .redTheme .mat-tab-group.mat-primary .mat-ink-bar, .darkTheme .redTheme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #ff5c8e;
}
.darkTheme .redTheme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .darkTheme .redTheme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .darkTheme .redTheme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .darkTheme .redTheme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.darkTheme .redTheme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .redTheme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .redTheme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .redTheme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .redTheme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .redTheme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .redTheme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .redTheme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(94, 36, 77, 0.3);
}
.darkTheme .redTheme .mat-tab-group.mat-accent .mat-ink-bar, .darkTheme .redTheme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #5e244d;
}
.darkTheme .redTheme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .darkTheme .redTheme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .darkTheme .redTheme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .darkTheme .redTheme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.darkTheme .redTheme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .redTheme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .redTheme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .redTheme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .redTheme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .redTheme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .redTheme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .redTheme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.darkTheme .redTheme .mat-tab-group.mat-warn .mat-ink-bar, .darkTheme .redTheme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e91e63;
}
.darkTheme .redTheme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .darkTheme .redTheme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .darkTheme .redTheme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .darkTheme .redTheme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.darkTheme .redTheme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .redTheme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .redTheme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .redTheme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .redTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .redTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .redTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .redTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 92, 142, 0.3);
}
.darkTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-header, .darkTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .darkTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #ff5c8e;
}
.darkTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .darkTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.darkTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.darkTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.darkTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.darkTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.darkTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.darkTheme .redTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.darkTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.darkTheme .redTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.darkTheme .redTheme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .redTheme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .redTheme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .redTheme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .redTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .redTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .redTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .redTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(94, 36, 77, 0.3);
}
.darkTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-header, .darkTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .darkTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #5e244d;
}
.darkTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .darkTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.darkTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.darkTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.darkTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.darkTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.darkTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.darkTheme .redTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.darkTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.darkTheme .redTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.darkTheme .redTheme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .redTheme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .redTheme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .redTheme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .redTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .redTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .redTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .redTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.darkTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-header, .darkTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .darkTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e91e63;
}
.darkTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .darkTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.darkTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.darkTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.darkTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.darkTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.darkTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.darkTheme .redTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .darkTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.darkTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.darkTheme .redTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.darkTheme .redTheme .mat-toolbar {
  background: #212121;
  color: white;
}
.darkTheme .redTheme .mat-toolbar.mat-primary {
  background: #ff5c8e;
  color: white;
}
.darkTheme .redTheme .mat-toolbar.mat-accent {
  background: #5e244d;
  color: white;
}
.darkTheme .redTheme .mat-toolbar.mat-warn {
  background: #e91e63;
  color: white;
}
.darkTheme .redTheme .mat-toolbar .mat-form-field-underline,
.darkTheme .redTheme .mat-toolbar .mat-form-field-ripple,
.darkTheme .redTheme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.darkTheme .redTheme .mat-toolbar .mat-form-field-label,
.darkTheme .redTheme .mat-toolbar .mat-focused .mat-form-field-label,
.darkTheme .redTheme .mat-toolbar .mat-select-value,
.darkTheme .redTheme .mat-toolbar .mat-select-arrow,
.darkTheme .redTheme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.darkTheme .redTheme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.darkTheme .redTheme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.darkTheme .redTheme .mat-tree {
  background: #424242;
}
.darkTheme .redTheme .mat-tree-node,
.darkTheme .redTheme .mat-nested-tree-node {
  color: white;
}
.darkTheme .redTheme .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .redTheme .mat-simple-snackbar-action {
  color: inherit;
}
.darkTheme .indigoTheme .leftsidebar .menu-list-item.activeMenu .mat-list-item-content, .darkTheme .indigoTheme .leftsidebar .menu-list-item.activeMenu:hover .mat-list-item-content {
  background-color: #1e4db7;
}
.darkTheme .indigoTheme .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .indigoTheme .mat-option {
  color: white;
}
.darkTheme .indigoTheme .mat-option:hover:not(.mat-option-disabled), .darkTheme .indigoTheme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.darkTheme .indigoTheme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.darkTheme .indigoTheme .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.darkTheme .indigoTheme .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #1e4db7;
}
.darkTheme .indigoTheme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #11397b;
}
.darkTheme .indigoTheme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e91e63;
}
.darkTheme .indigoTheme .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .indigoTheme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .indigoTheme .mat-pseudo-checkbox::after {
  color: #303030;
}
.darkTheme .indigoTheme .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.darkTheme .indigoTheme .mat-primary .mat-pseudo-checkbox-checked,
.darkTheme .indigoTheme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #1e4db7;
}
.darkTheme .indigoTheme .mat-pseudo-checkbox-checked,
.darkTheme .indigoTheme .mat-pseudo-checkbox-indeterminate,
.darkTheme .indigoTheme .mat-accent .mat-pseudo-checkbox-checked,
.darkTheme .indigoTheme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #11397b;
}
.darkTheme .indigoTheme .mat-warn .mat-pseudo-checkbox-checked,
.darkTheme .indigoTheme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e91e63;
}
.darkTheme .indigoTheme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.darkTheme .indigoTheme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.darkTheme .indigoTheme .mat-app-background, .darkTheme .indigoTheme.mat-app-background {
  background-color: #303030;
  color: white;
}
.darkTheme .indigoTheme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.darkTheme .indigoTheme .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.darkTheme .indigoTheme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.darkTheme .indigoTheme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.darkTheme .indigoTheme .mat-badge-content {
  color: white;
  background: #1e4db7;
}
.cdk-high-contrast-active .darkTheme .indigoTheme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.darkTheme .indigoTheme .mat-badge-accent .mat-badge-content {
  background: #11397b;
  color: white;
}
.darkTheme .indigoTheme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e91e63;
}
.darkTheme .indigoTheme .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.darkTheme .indigoTheme .mat-button, .darkTheme .indigoTheme .mat-icon-button, .darkTheme .indigoTheme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.darkTheme .indigoTheme .mat-button.mat-primary, .darkTheme .indigoTheme .mat-icon-button.mat-primary, .darkTheme .indigoTheme .mat-stroked-button.mat-primary {
  color: #1e4db7;
}
.darkTheme .indigoTheme .mat-button.mat-accent, .darkTheme .indigoTheme .mat-icon-button.mat-accent, .darkTheme .indigoTheme .mat-stroked-button.mat-accent {
  color: #11397b;
}
.darkTheme .indigoTheme .mat-button.mat-warn, .darkTheme .indigoTheme .mat-icon-button.mat-warn, .darkTheme .indigoTheme .mat-stroked-button.mat-warn {
  color: #e91e63;
}
.darkTheme .indigoTheme .mat-button.mat-primary.mat-button-disabled, .darkTheme .indigoTheme .mat-button.mat-accent.mat-button-disabled, .darkTheme .indigoTheme .mat-button.mat-warn.mat-button-disabled, .darkTheme .indigoTheme .mat-button.mat-button-disabled.mat-button-disabled, .darkTheme .indigoTheme .mat-icon-button.mat-primary.mat-button-disabled, .darkTheme .indigoTheme .mat-icon-button.mat-accent.mat-button-disabled, .darkTheme .indigoTheme .mat-icon-button.mat-warn.mat-button-disabled, .darkTheme .indigoTheme .mat-icon-button.mat-button-disabled.mat-button-disabled, .darkTheme .indigoTheme .mat-stroked-button.mat-primary.mat-button-disabled, .darkTheme .indigoTheme .mat-stroked-button.mat-accent.mat-button-disabled, .darkTheme .indigoTheme .mat-stroked-button.mat-warn.mat-button-disabled, .darkTheme .indigoTheme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.darkTheme .indigoTheme .mat-button.mat-primary .mat-button-focus-overlay, .darkTheme .indigoTheme .mat-icon-button.mat-primary .mat-button-focus-overlay, .darkTheme .indigoTheme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #1e4db7;
}
.darkTheme .indigoTheme .mat-button.mat-accent .mat-button-focus-overlay, .darkTheme .indigoTheme .mat-icon-button.mat-accent .mat-button-focus-overlay, .darkTheme .indigoTheme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #11397b;
}
.darkTheme .indigoTheme .mat-button.mat-warn .mat-button-focus-overlay, .darkTheme .indigoTheme .mat-icon-button.mat-warn .mat-button-focus-overlay, .darkTheme .indigoTheme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e91e63;
}
.darkTheme .indigoTheme .mat-button.mat-button-disabled .mat-button-focus-overlay, .darkTheme .indigoTheme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .darkTheme .indigoTheme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.darkTheme .indigoTheme .mat-button .mat-ripple-element, .darkTheme .indigoTheme .mat-icon-button .mat-ripple-element, .darkTheme .indigoTheme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.darkTheme .indigoTheme .mat-button-focus-overlay {
  background: white;
}
.darkTheme .indigoTheme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .indigoTheme .mat-flat-button, .darkTheme .indigoTheme .mat-raised-button, .darkTheme .indigoTheme .mat-fab, .darkTheme .indigoTheme .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.darkTheme .indigoTheme .mat-flat-button.mat-primary, .darkTheme .indigoTheme .mat-raised-button.mat-primary, .darkTheme .indigoTheme .mat-fab.mat-primary, .darkTheme .indigoTheme .mat-mini-fab.mat-primary {
  color: white;
}
.darkTheme .indigoTheme .mat-flat-button.mat-accent, .darkTheme .indigoTheme .mat-raised-button.mat-accent, .darkTheme .indigoTheme .mat-fab.mat-accent, .darkTheme .indigoTheme .mat-mini-fab.mat-accent {
  color: white;
}
.darkTheme .indigoTheme .mat-flat-button.mat-warn, .darkTheme .indigoTheme .mat-raised-button.mat-warn, .darkTheme .indigoTheme .mat-fab.mat-warn, .darkTheme .indigoTheme .mat-mini-fab.mat-warn {
  color: white;
}
.darkTheme .indigoTheme .mat-flat-button.mat-primary.mat-button-disabled, .darkTheme .indigoTheme .mat-flat-button.mat-accent.mat-button-disabled, .darkTheme .indigoTheme .mat-flat-button.mat-warn.mat-button-disabled, .darkTheme .indigoTheme .mat-flat-button.mat-button-disabled.mat-button-disabled, .darkTheme .indigoTheme .mat-raised-button.mat-primary.mat-button-disabled, .darkTheme .indigoTheme .mat-raised-button.mat-accent.mat-button-disabled, .darkTheme .indigoTheme .mat-raised-button.mat-warn.mat-button-disabled, .darkTheme .indigoTheme .mat-raised-button.mat-button-disabled.mat-button-disabled, .darkTheme .indigoTheme .mat-fab.mat-primary.mat-button-disabled, .darkTheme .indigoTheme .mat-fab.mat-accent.mat-button-disabled, .darkTheme .indigoTheme .mat-fab.mat-warn.mat-button-disabled, .darkTheme .indigoTheme .mat-fab.mat-button-disabled.mat-button-disabled, .darkTheme .indigoTheme .mat-mini-fab.mat-primary.mat-button-disabled, .darkTheme .indigoTheme .mat-mini-fab.mat-accent.mat-button-disabled, .darkTheme .indigoTheme .mat-mini-fab.mat-warn.mat-button-disabled, .darkTheme .indigoTheme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.darkTheme .indigoTheme .mat-flat-button.mat-primary, .darkTheme .indigoTheme .mat-raised-button.mat-primary, .darkTheme .indigoTheme .mat-fab.mat-primary, .darkTheme .indigoTheme .mat-mini-fab.mat-primary {
  background-color: #1e4db7;
}
.darkTheme .indigoTheme .mat-flat-button.mat-accent, .darkTheme .indigoTheme .mat-raised-button.mat-accent, .darkTheme .indigoTheme .mat-fab.mat-accent, .darkTheme .indigoTheme .mat-mini-fab.mat-accent {
  background-color: #11397b;
}
.darkTheme .indigoTheme .mat-flat-button.mat-warn, .darkTheme .indigoTheme .mat-raised-button.mat-warn, .darkTheme .indigoTheme .mat-fab.mat-warn, .darkTheme .indigoTheme .mat-mini-fab.mat-warn {
  background-color: #e91e63;
}
.darkTheme .indigoTheme .mat-flat-button.mat-primary.mat-button-disabled, .darkTheme .indigoTheme .mat-flat-button.mat-accent.mat-button-disabled, .darkTheme .indigoTheme .mat-flat-button.mat-warn.mat-button-disabled, .darkTheme .indigoTheme .mat-flat-button.mat-button-disabled.mat-button-disabled, .darkTheme .indigoTheme .mat-raised-button.mat-primary.mat-button-disabled, .darkTheme .indigoTheme .mat-raised-button.mat-accent.mat-button-disabled, .darkTheme .indigoTheme .mat-raised-button.mat-warn.mat-button-disabled, .darkTheme .indigoTheme .mat-raised-button.mat-button-disabled.mat-button-disabled, .darkTheme .indigoTheme .mat-fab.mat-primary.mat-button-disabled, .darkTheme .indigoTheme .mat-fab.mat-accent.mat-button-disabled, .darkTheme .indigoTheme .mat-fab.mat-warn.mat-button-disabled, .darkTheme .indigoTheme .mat-fab.mat-button-disabled.mat-button-disabled, .darkTheme .indigoTheme .mat-mini-fab.mat-primary.mat-button-disabled, .darkTheme .indigoTheme .mat-mini-fab.mat-accent.mat-button-disabled, .darkTheme .indigoTheme .mat-mini-fab.mat-warn.mat-button-disabled, .darkTheme .indigoTheme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .indigoTheme .mat-flat-button.mat-primary .mat-ripple-element, .darkTheme .indigoTheme .mat-raised-button.mat-primary .mat-ripple-element, .darkTheme .indigoTheme .mat-fab.mat-primary .mat-ripple-element, .darkTheme .indigoTheme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .indigoTheme .mat-flat-button.mat-accent .mat-ripple-element, .darkTheme .indigoTheme .mat-raised-button.mat-accent .mat-ripple-element, .darkTheme .indigoTheme .mat-fab.mat-accent .mat-ripple-element, .darkTheme .indigoTheme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .indigoTheme .mat-flat-button.mat-warn .mat-ripple-element, .darkTheme .indigoTheme .mat-raised-button.mat-warn .mat-ripple-element, .darkTheme .indigoTheme .mat-fab.mat-warn .mat-ripple-element, .darkTheme .indigoTheme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .indigoTheme .mat-stroked-button:not([class*=mat-elevation-z]), .darkTheme .indigoTheme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-fab:not([class*=mat-elevation-z]), .darkTheme .indigoTheme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .darkTheme .indigoTheme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .darkTheme .indigoTheme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.darkTheme .indigoTheme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.darkTheme .indigoTheme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.darkTheme .indigoTheme .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .indigoTheme .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.darkTheme .indigoTheme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.darkTheme .indigoTheme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.darkTheme .indigoTheme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.darkTheme .indigoTheme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.darkTheme .indigoTheme .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .indigoTheme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.darkTheme .indigoTheme .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.darkTheme .indigoTheme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.darkTheme .indigoTheme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.darkTheme .indigoTheme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.darkTheme .indigoTheme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.darkTheme .indigoTheme .mat-card {
  background: #424242;
  color: white;
}
.darkTheme .indigoTheme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .indigoTheme .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .indigoTheme .mat-checkbox-checkmark {
  fill: #303030;
}
.darkTheme .indigoTheme .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.darkTheme .indigoTheme .mat-checkbox-mixedmark {
  background-color: #303030;
}
.darkTheme .indigoTheme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .darkTheme .indigoTheme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #1e4db7;
}
.darkTheme .indigoTheme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .darkTheme .indigoTheme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #11397b;
}
.darkTheme .indigoTheme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .darkTheme .indigoTheme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e91e63;
}
.darkTheme .indigoTheme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .darkTheme .indigoTheme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.darkTheme .indigoTheme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.darkTheme .indigoTheme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.darkTheme .indigoTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.darkTheme .indigoTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #1e4db7;
}
.darkTheme .indigoTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.darkTheme .indigoTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #11397b;
}
.darkTheme .indigoTheme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.darkTheme .indigoTheme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e91e63;
}
.darkTheme .indigoTheme .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.darkTheme .indigoTheme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.darkTheme .indigoTheme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.darkTheme .indigoTheme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.darkTheme .indigoTheme .mat-chip.mat-standard-chip::after {
  background: white;
}
.darkTheme .indigoTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #1e4db7;
  color: white;
}
.darkTheme .indigoTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.darkTheme .indigoTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .indigoTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e91e63;
  color: white;
}
.darkTheme .indigoTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.darkTheme .indigoTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .indigoTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #11397b;
  color: white;
}
.darkTheme .indigoTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.darkTheme .indigoTheme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .indigoTheme .mat-table {
  background: #424242;
}
.darkTheme .indigoTheme .mat-table thead, .darkTheme .indigoTheme .mat-table tbody, .darkTheme .indigoTheme .mat-table tfoot,
.darkTheme .indigoTheme mat-header-row, .darkTheme .indigoTheme mat-row, .darkTheme .indigoTheme mat-footer-row,
.darkTheme .indigoTheme [mat-header-row], .darkTheme .indigoTheme [mat-row], .darkTheme .indigoTheme [mat-footer-row],
.darkTheme .indigoTheme .mat-table-sticky {
  background: inherit;
}
.darkTheme .indigoTheme mat-row, .darkTheme .indigoTheme mat-header-row, .darkTheme .indigoTheme mat-footer-row,
.darkTheme .indigoTheme th.mat-header-cell, .darkTheme .indigoTheme td.mat-cell, .darkTheme .indigoTheme td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .indigoTheme .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .indigoTheme .mat-cell, .darkTheme .indigoTheme .mat-footer-cell {
  color: white;
}
.darkTheme .indigoTheme .mat-calendar-arrow {
  fill: white;
}
.darkTheme .indigoTheme .mat-datepicker-toggle,
.darkTheme .indigoTheme .mat-datepicker-content .mat-calendar-next-button,
.darkTheme .indigoTheme .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.darkTheme .indigoTheme .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.darkTheme .indigoTheme .mat-calendar-table-header,
.darkTheme .indigoTheme .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .indigoTheme .mat-calendar-body-cell-content,
.darkTheme .indigoTheme .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.darkTheme .indigoTheme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.darkTheme .indigoTheme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .indigoTheme .mat-calendar-body-in-range::before {
  background: rgba(30, 77, 183, 0.2);
}
.darkTheme .indigoTheme .mat-calendar-body-comparison-identical,
.darkTheme .indigoTheme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.darkTheme .indigoTheme .mat-calendar-body-comparison-bridge-start::before,
.darkTheme .indigoTheme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(30, 77, 183, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .indigoTheme .mat-calendar-body-comparison-bridge-end::before,
.darkTheme .indigoTheme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(30, 77, 183, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .indigoTheme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.darkTheme .indigoTheme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.darkTheme .indigoTheme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.darkTheme .indigoTheme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.darkTheme .indigoTheme .mat-calendar-body-selected {
  background-color: #1e4db7;
  color: white;
}
.darkTheme .indigoTheme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(30, 77, 183, 0.4);
}
.darkTheme .indigoTheme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.darkTheme .indigoTheme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.darkTheme .indigoTheme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(30, 77, 183, 0.3);
}
@media (hover: hover) {
  .darkTheme .indigoTheme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(30, 77, 183, 0.3);
  }
}
.darkTheme .indigoTheme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.darkTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(17, 57, 123, 0.2);
}
.darkTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.darkTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.darkTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.darkTheme .indigoTheme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(17, 57, 123, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.darkTheme .indigoTheme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(17, 57, 123, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.darkTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.darkTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.darkTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.darkTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #11397b;
  color: white;
}
.darkTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(17, 57, 123, 0.4);
}
.darkTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.darkTheme .indigoTheme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.darkTheme .indigoTheme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(17, 57, 123, 0.3);
}
@media (hover: hover) {
  .darkTheme .indigoTheme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(17, 57, 123, 0.3);
  }
}
.darkTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(233, 30, 99, 0.2);
}
.darkTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.darkTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.darkTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.darkTheme .indigoTheme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.darkTheme .indigoTheme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.darkTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.darkTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.darkTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.darkTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.darkTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e91e63;
  color: white;
}
.darkTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(233, 30, 99, 0.4);
}
.darkTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.darkTheme .indigoTheme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.darkTheme .indigoTheme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(233, 30, 99, 0.3);
}
@media (hover: hover) {
  .darkTheme .indigoTheme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(233, 30, 99, 0.3);
  }
}
.darkTheme .indigoTheme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-datepicker-toggle-active {
  color: #1e4db7;
}
.darkTheme .indigoTheme .mat-datepicker-toggle-active.mat-accent {
  color: #11397b;
}
.darkTheme .indigoTheme .mat-datepicker-toggle-active.mat-warn {
  color: #e91e63;
}
.darkTheme .indigoTheme .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.darkTheme .indigoTheme .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .indigoTheme .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .indigoTheme .mat-expansion-panel {
  background: #424242;
  color: white;
}
.darkTheme .indigoTheme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .indigoTheme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .darkTheme .indigoTheme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .darkTheme .indigoTheme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .darkTheme .indigoTheme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.darkTheme .indigoTheme .mat-expansion-panel-header-title {
  color: white;
}
.darkTheme .indigoTheme .mat-expansion-panel-header-description,
.darkTheme .indigoTheme .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .indigoTheme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.darkTheme .indigoTheme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.darkTheme .indigoTheme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.darkTheme .indigoTheme .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .indigoTheme .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .indigoTheme .mat-form-field.mat-focused .mat-form-field-label {
  color: #1e4db7;
}
.darkTheme .indigoTheme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #11397b;
}
.darkTheme .indigoTheme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e91e63;
}
.darkTheme .indigoTheme .mat-focused .mat-form-field-required-marker {
  color: #11397b;
}
.darkTheme .indigoTheme .mat-form-field-ripple {
  background-color: white;
}
.darkTheme .indigoTheme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #1e4db7;
}
.darkTheme .indigoTheme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #11397b;
}
.darkTheme .indigoTheme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e91e63;
}
.darkTheme .indigoTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #1e4db7;
}
.darkTheme .indigoTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #11397b;
}
.darkTheme .indigoTheme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e91e63;
}
.darkTheme .indigoTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e91e63;
}
.darkTheme .indigoTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.darkTheme .indigoTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e91e63;
}
.darkTheme .indigoTheme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.darkTheme .indigoTheme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e91e63;
}
.darkTheme .indigoTheme .mat-error {
  color: #e91e63;
}
.darkTheme .indigoTheme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .indigoTheme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .indigoTheme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .indigoTheme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.darkTheme .indigoTheme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .indigoTheme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.darkTheme .indigoTheme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.darkTheme .indigoTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.darkTheme .indigoTheme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.darkTheme .indigoTheme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.darkTheme .indigoTheme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.darkTheme .indigoTheme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #1e4db7;
}
.darkTheme .indigoTheme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #11397b;
}
.darkTheme .indigoTheme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e91e63;
}
.darkTheme .indigoTheme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e91e63;
}
.darkTheme .indigoTheme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.darkTheme .indigoTheme .mat-icon.mat-primary {
  color: #1e4db7;
}
.darkTheme .indigoTheme .mat-icon.mat-accent {
  color: #11397b;
}
.darkTheme .indigoTheme .mat-icon.mat-warn {
  color: #e91e63;
}
.darkTheme .indigoTheme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .indigoTheme .mat-input-element:disabled,
.darkTheme .indigoTheme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-input-element {
  caret-color: #1e4db7;
}
.darkTheme .indigoTheme .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.darkTheme .indigoTheme .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.darkTheme .indigoTheme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #11397b;
}
.darkTheme .indigoTheme .mat-form-field.mat-warn .mat-input-element,
.darkTheme .indigoTheme .mat-form-field-invalid .mat-input-element {
  caret-color: #e91e63;
}
.darkTheme .indigoTheme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e91e63;
}
.darkTheme .indigoTheme .mat-list-base .mat-list-item {
  color: white;
}
.darkTheme .indigoTheme .mat-list-base .mat-list-option {
  color: white;
}
.darkTheme .indigoTheme .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .indigoTheme .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-list-option:hover, .darkTheme .indigoTheme .mat-list-option:focus,
.darkTheme .indigoTheme .mat-nav-list .mat-list-item:hover,
.darkTheme .indigoTheme .mat-nav-list .mat-list-item:focus,
.darkTheme .indigoTheme .mat-action-list .mat-list-item:hover,
.darkTheme .indigoTheme .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.darkTheme .indigoTheme .mat-list-single-selected-option, .darkTheme .indigoTheme .mat-list-single-selected-option:hover, .darkTheme .indigoTheme .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.darkTheme .indigoTheme .mat-menu-panel {
  background: #424242;
}
.darkTheme .indigoTheme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-menu-item {
  background: transparent;
  color: white;
}
.darkTheme .indigoTheme .mat-menu-item[disabled],
.darkTheme .indigoTheme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.darkTheme .indigoTheme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-menu-item .mat-icon-no-color,
.darkTheme .indigoTheme .mat-menu-submenu-icon {
  color: white;
}
.darkTheme .indigoTheme .mat-menu-item:hover:not([disabled]),
.darkTheme .indigoTheme .mat-menu-item.cdk-program-focused:not([disabled]),
.darkTheme .indigoTheme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.darkTheme .indigoTheme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.darkTheme .indigoTheme .mat-paginator {
  background: #424242;
}
.darkTheme .indigoTheme .mat-paginator,
.darkTheme .indigoTheme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .indigoTheme .mat-paginator-decrement,
.darkTheme .indigoTheme .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.darkTheme .indigoTheme .mat-paginator-first,
.darkTheme .indigoTheme .mat-paginator-last {
  border-top: 2px solid white;
}
.darkTheme .indigoTheme .mat-icon-button[disabled] .mat-paginator-decrement,
.darkTheme .indigoTheme .mat-icon-button[disabled] .mat-paginator-increment,
.darkTheme .indigoTheme .mat-icon-button[disabled] .mat-paginator-first,
.darkTheme .indigoTheme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-progress-bar-background {
  fill: #2c3752;
}
.darkTheme .indigoTheme .mat-progress-bar-buffer {
  background-color: #2c3752;
}
.darkTheme .indigoTheme .mat-progress-bar-fill::after {
  background-color: #1e4db7;
}
.darkTheme .indigoTheme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #283243;
}
.darkTheme .indigoTheme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #283243;
}
.darkTheme .indigoTheme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #11397b;
}
.darkTheme .indigoTheme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #5e2c3d;
}
.darkTheme .indigoTheme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #5e2c3d;
}
.darkTheme .indigoTheme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e91e63;
}
.darkTheme .indigoTheme .mat-progress-spinner circle, .darkTheme .indigoTheme .mat-spinner circle {
  stroke: #1e4db7;
}
.darkTheme .indigoTheme .mat-progress-spinner.mat-accent circle, .darkTheme .indigoTheme .mat-spinner.mat-accent circle {
  stroke: #11397b;
}
.darkTheme .indigoTheme .mat-progress-spinner.mat-warn circle, .darkTheme .indigoTheme .mat-spinner.mat-warn circle {
  stroke: #e91e63;
}
.darkTheme .indigoTheme .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .indigoTheme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #1e4db7;
}
.darkTheme .indigoTheme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.darkTheme .indigoTheme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .darkTheme .indigoTheme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .darkTheme .indigoTheme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #1e4db7;
}
.darkTheme .indigoTheme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #11397b;
}
.darkTheme .indigoTheme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.darkTheme .indigoTheme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .darkTheme .indigoTheme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .darkTheme .indigoTheme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #11397b;
}
.darkTheme .indigoTheme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e91e63;
}
.darkTheme .indigoTheme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.darkTheme .indigoTheme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .darkTheme .indigoTheme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .darkTheme .indigoTheme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e91e63;
}
.darkTheme .indigoTheme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.darkTheme .indigoTheme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.darkTheme .indigoTheme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.darkTheme .indigoTheme .mat-select-value {
  color: white;
}
.darkTheme .indigoTheme .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .indigoTheme .mat-select-panel {
  background: #424242;
}
.darkTheme .indigoTheme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.darkTheme .indigoTheme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #1e4db7;
}
.darkTheme .indigoTheme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #11397b;
}
.darkTheme .indigoTheme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e91e63;
}
.darkTheme .indigoTheme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e91e63;
}
.darkTheme .indigoTheme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.darkTheme .indigoTheme .mat-drawer {
  background-color: #424242;
  color: white;
}
.darkTheme .indigoTheme .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.darkTheme .indigoTheme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.darkTheme .indigoTheme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.darkTheme .indigoTheme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.darkTheme .indigoTheme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.darkTheme .indigoTheme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.darkTheme .indigoTheme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #11397b;
}
.darkTheme .indigoTheme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(17, 57, 123, 0.54);
}
.darkTheme .indigoTheme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #11397b;
}
.darkTheme .indigoTheme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #1e4db7;
}
.darkTheme .indigoTheme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(30, 77, 183, 0.54);
}
.darkTheme .indigoTheme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #1e4db7;
}
.darkTheme .indigoTheme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e91e63;
}
.darkTheme .indigoTheme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(233, 30, 99, 0.54);
}
.darkTheme .indigoTheme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e91e63;
}
.darkTheme .indigoTheme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.darkTheme .indigoTheme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.darkTheme .indigoTheme .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .indigoTheme .mat-slider.mat-primary .mat-slider-track-fill,
.darkTheme .indigoTheme .mat-slider.mat-primary .mat-slider-thumb,
.darkTheme .indigoTheme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #1e4db7;
}
.darkTheme .indigoTheme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.darkTheme .indigoTheme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(30, 77, 183, 0.2);
}
.darkTheme .indigoTheme .mat-slider.mat-accent .mat-slider-track-fill,
.darkTheme .indigoTheme .mat-slider.mat-accent .mat-slider-thumb,
.darkTheme .indigoTheme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #11397b;
}
.darkTheme .indigoTheme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.darkTheme .indigoTheme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(17, 57, 123, 0.2);
}
.darkTheme .indigoTheme .mat-slider.mat-warn .mat-slider-track-fill,
.darkTheme .indigoTheme .mat-slider.mat-warn .mat-slider-thumb,
.darkTheme .indigoTheme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e91e63;
}
.darkTheme .indigoTheme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.darkTheme .indigoTheme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(233, 30, 99, 0.2);
}
.darkTheme .indigoTheme .mat-slider:hover .mat-slider-track-background,
.darkTheme .indigoTheme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .indigoTheme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.darkTheme .indigoTheme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.darkTheme .indigoTheme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .indigoTheme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .indigoTheme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .indigoTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.darkTheme .indigoTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.darkTheme .indigoTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.darkTheme .indigoTheme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .indigoTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.darkTheme .indigoTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .darkTheme .indigoTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .indigoTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .darkTheme .indigoTheme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.darkTheme .indigoTheme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.darkTheme .indigoTheme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.darkTheme .indigoTheme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.darkTheme .indigoTheme .mat-step-header.cdk-keyboard-focused, .darkTheme .indigoTheme .mat-step-header.cdk-program-focused, .darkTheme .indigoTheme .mat-step-header:hover:not([aria-disabled]), .darkTheme .indigoTheme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.darkTheme .indigoTheme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .darkTheme .indigoTheme .mat-step-header:hover {
    background: none;
  }
}
.darkTheme .indigoTheme .mat-step-header .mat-step-label,
.darkTheme .indigoTheme .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.darkTheme .indigoTheme .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.darkTheme .indigoTheme .mat-step-header .mat-step-icon-selected,
.darkTheme .indigoTheme .mat-step-header .mat-step-icon-state-done,
.darkTheme .indigoTheme .mat-step-header .mat-step-icon-state-edit {
  background-color: #1e4db7;
  color: white;
}
.darkTheme .indigoTheme .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.darkTheme .indigoTheme .mat-step-header.mat-accent .mat-step-icon-selected,
.darkTheme .indigoTheme .mat-step-header.mat-accent .mat-step-icon-state-done,
.darkTheme .indigoTheme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #11397b;
  color: white;
}
.darkTheme .indigoTheme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.darkTheme .indigoTheme .mat-step-header.mat-warn .mat-step-icon-selected,
.darkTheme .indigoTheme .mat-step-header.mat-warn .mat-step-icon-state-done,
.darkTheme .indigoTheme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e91e63;
  color: white;
}
.darkTheme .indigoTheme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e91e63;
}
.darkTheme .indigoTheme .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.darkTheme .indigoTheme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e91e63;
}
.darkTheme .indigoTheme .mat-stepper-horizontal, .darkTheme .indigoTheme .mat-stepper-vertical {
  background-color: #424242;
}
.darkTheme .indigoTheme .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .indigoTheme .mat-horizontal-stepper-header::before,
.darkTheme .indigoTheme .mat-horizontal-stepper-header::after,
.darkTheme .indigoTheme .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.darkTheme .indigoTheme .mat-sort-header-arrow {
  color: #c6c6c6;
}
.darkTheme .indigoTheme .mat-tab-nav-bar,
.darkTheme .indigoTheme .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.darkTheme .indigoTheme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.darkTheme .indigoTheme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.darkTheme .indigoTheme .mat-tab-label, .darkTheme .indigoTheme .mat-tab-link {
  color: white;
}
.darkTheme .indigoTheme .mat-tab-label.mat-tab-disabled, .darkTheme .indigoTheme .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-tab-header-pagination-chevron {
  border-color: white;
}
.darkTheme .indigoTheme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.darkTheme .indigoTheme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.darkTheme .indigoTheme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.darkTheme .indigoTheme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .indigoTheme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .indigoTheme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .indigoTheme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .indigoTheme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .indigoTheme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(30, 77, 183, 0.3);
}
.darkTheme .indigoTheme .mat-tab-group.mat-primary .mat-ink-bar, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #1e4db7;
}
.darkTheme .indigoTheme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .darkTheme .indigoTheme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.darkTheme .indigoTheme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .indigoTheme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .indigoTheme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .indigoTheme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .indigoTheme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .indigoTheme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(17, 57, 123, 0.3);
}
.darkTheme .indigoTheme .mat-tab-group.mat-accent .mat-ink-bar, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #11397b;
}
.darkTheme .indigoTheme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .darkTheme .indigoTheme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.darkTheme .indigoTheme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .indigoTheme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .indigoTheme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .indigoTheme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .indigoTheme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .indigoTheme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.darkTheme .indigoTheme .mat-tab-group.mat-warn .mat-ink-bar, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e91e63;
}
.darkTheme .indigoTheme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .darkTheme .indigoTheme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.darkTheme .indigoTheme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .indigoTheme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .indigoTheme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .indigoTheme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(30, 77, 183, 0.3);
}
.darkTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-header, .darkTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .darkTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #1e4db7;
}
.darkTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .darkTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.darkTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.darkTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.darkTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.darkTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.darkTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.darkTheme .indigoTheme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.darkTheme .indigoTheme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .indigoTheme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .indigoTheme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .indigoTheme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(17, 57, 123, 0.3);
}
.darkTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-header, .darkTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .darkTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #11397b;
}
.darkTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .darkTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.darkTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.darkTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.darkTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.darkTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.darkTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.darkTheme .indigoTheme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.darkTheme .indigoTheme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .indigoTheme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .indigoTheme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .indigoTheme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.darkTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-header, .darkTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .darkTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e91e63;
}
.darkTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .darkTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.darkTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.darkTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.darkTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.darkTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.darkTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.darkTheme .indigoTheme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.darkTheme .indigoTheme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.darkTheme .indigoTheme .mat-toolbar {
  background: #212121;
  color: white;
}
.darkTheme .indigoTheme .mat-toolbar.mat-primary {
  background: #1e4db7;
  color: white;
}
.darkTheme .indigoTheme .mat-toolbar.mat-accent {
  background: #11397b;
  color: white;
}
.darkTheme .indigoTheme .mat-toolbar.mat-warn {
  background: #e91e63;
  color: white;
}
.darkTheme .indigoTheme .mat-toolbar .mat-form-field-underline,
.darkTheme .indigoTheme .mat-toolbar .mat-form-field-ripple,
.darkTheme .indigoTheme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.darkTheme .indigoTheme .mat-toolbar .mat-form-field-label,
.darkTheme .indigoTheme .mat-toolbar .mat-focused .mat-form-field-label,
.darkTheme .indigoTheme .mat-toolbar .mat-select-value,
.darkTheme .indigoTheme .mat-toolbar .mat-select-arrow,
.darkTheme .indigoTheme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.darkTheme .indigoTheme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.darkTheme .indigoTheme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.darkTheme .indigoTheme .mat-tree {
  background: #424242;
}
.darkTheme .indigoTheme .mat-tree-node,
.darkTheme .indigoTheme .mat-nested-tree-node {
  color: white;
}
.darkTheme .indigoTheme .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.darkTheme .indigoTheme .mat-simple-snackbar-action {
  color: inherit;
}