/* You can add global styles to this file, and also import other style files */
@import "./assets/styles/style";
html,
body {
  height: 100%;
}
body {
  margin: 0;
}

.j-center {
  display: flex;
  justify-content: center;
}

.a-i-center {
  display: flex;
  align-items: center;
}

.m-r-10{
  margin-right: 10px;
}

.m-l-10{
  margin-left: 10px;
}

.bg-success {
    background-color: $success;
}

.bg-danger {
    background-color: $danger;
}

.bg-light {
  background-color: $light;
}

.bg-primary {
  background-color: $primary;
}

.bg-secondary {
  background-color: $secondary;
}

