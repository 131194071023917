@media (min-width: 1100px) {
  .mainboxContainer {
    &.minisidebar {
      .leftsidebar {
        .brand {
          width: 58px;
          overflow: hidden;
          margin: 0 auto;
        }
        &:hover {
          width: $sidebarwidth;
          transition: width 0.2s cubic-bezier(0.25, 0.8, 0.25, 1),
            transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
          .brand {
            width: 100%;
            overflow: unset;
            margin: unset;
          }
          .vsidebar {
            .vmenu-list-item {
              .mat-small {
                width: 100%;
              }
              .menu-list-item {
                .routeIcon {
                  margin-right: 10px;
                }
                .hide-menu,
                .arrow-icon {
                  display: flex !important;
                }
              }
            }
          }
        }
        .vsidebar {
          .vmenu-list-item {
            .mat-small {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 45px;
              display: block;
            }
            .menu-list-item {
              .routeIcon {
                margin-right: 0;
              }
              .hide-menu,
              .arrow-icon {
                display: none !important;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1099px) {
    .mainboxContainer {
        &.minisidebar {
          .leftsidebar {
              width: $sidebarwidth;
          }
        }
    }
}
