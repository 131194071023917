.customizerSidebar {
  width: $customizerwidth;
}
.customizerbtn.mat-fab {
  position: fixed;
  bottom: 15px;
  z-index: 1;
}
.ltr .customizerbtn.mat-fab {
  right: 25px;
}
.rtl .customizerbtn.mat-fab {
  left: 25px;
}

.themeColorOption {
  padding: 15px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  li {
    list-style: none;
    padding: 5px;
    line-height: 0;
    color: #fff;
    cursor: pointer;
    border-radius: 100%;
    &.orangeTheme {
      background-color: $primary;
    }
    &.greenTheme {
      background-color: $success;
    }
    &.blueTheme {
      background-color: $info;
    }
    &.redTheme {
      background-color: $danger;
    }
    &.indigoTheme {
      background-color: $indigo;
    }
    &.purpleTheme {
      background-color: $purple;
    }
  }
}
